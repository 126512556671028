import React, { useState } from 'react'
import { useEffect } from 'react'
import { gerTransactionListAPI } from '../../../Api/BarangizanServices'
import TransactionsList from '../../Lists/TransactionsList/TransactionsList'
import TopWalletPrice from './TopWalletPrice/TopWalletPrice'

const UserPanelWallet = () => {
  const[Transactions,setTransactions]=useState()
  useEffect(()=>{
    getTransactionsHandler()
  },[])
  const getTransactionsHandler=async()=>{
    const data=await gerTransactionListAPI()
    setTransactions(data.data)
  }
  return (
    <div className='flexStyle bg-white'>
        <TopWalletPrice data={Transactions}/>
        <TransactionsList data={Transactions}/>
    </div>
  )
}

export default UserPanelWallet