import React from 'react'
import PropertyRegisterForm from '../../Forms/PropertyRegisterForm/PropertyRegisterForm'

const UserPanelPropertyRegister = () => {
  return (
    <section className={'flexStyle bg-white'}>
      <PropertyRegisterForm/>
    </section>
   )
}

export default UserPanelPropertyRegister