import React, { useEffect, useState } from 'react'
import { MagazinListAPI } from '../../../Api/BarangizanServices';
import MagazinePerviewSlide from '../../MagazinePerviewSlide/MagazinePerviewSlide'

import Style from './MagazineLists.module.scss'
const MagazineLists = () => {
    const [magazineList, setMagazineList] = useState();
    useEffect(() => {
        getMagazineListHandler();
      }, []);
    const getMagazineListHandler = async () => {
        const data = await MagazinListAPI();
        setMagazineList(data.data.list);
      };
    
    return (
        <ul className={Style.ListHolder}>
            {
                magazineList&&magazineList.map(item=>{
                    return(
                    <li key={item.id}><MagazinePerviewSlide  data={item}/></li> 
                    )
                })
            }
        </ul>
    )
}

export default MagazineLists
