import { Pagination } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { getProjectListAPI } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import ProjectList from '../../Components/Lists/ProjectList/ProjectList'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import ScrollToTap from '../../Utils/ScrollToTap/ScrollToTap'

const Projects = () => {
  const[ProjectListState,setProjectListState]=useState(null)
  const[pageNumber,setPageNumber]=useState(1)
  useEffect(()=>{
    getProjectsDataHandler()
  },[pageNumber])
  const getProjectsDataHandler=async()=>{
    setProjectListState(null)
    const data=await getProjectListAPI(pageNumber)
    setProjectListState(data.data)
  }

  return (
    <ScrollToTap>
    <div className={'pageHolder'}>
          <AmlakKharejiHeader/>
          <ProjectList ProjectListState={ProjectListState&&ProjectListState.list}/>
          <Pagination
          
          showSizeChanger={false} 
          current={pageNumber}
          pageSize={10}
          total={ProjectListState&&ProjectListState.pagination.totalPages*10}
          onChange={(page) => {
            setPageNumber(page);
          }}
        />
          <Footer/>
      </div>
</ScrollToTap>
  )
}

export default Projects