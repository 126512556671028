import { faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, Switch } from "antd";
import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FilterInfo } from "../../../Utils/Context/SearchEngineContext";
import DakheliFilter from "../DakheliFilter/DakheliFilter";
import ResultList from "../ResultList/ResultList";
import Style from "./DakheliResultsContainer.module.scss";

const DakheliResultsContainer = ({
  data,
  openModalHandler,
  openModalHandler2,
  searchUpdate,
  updatePage,
}) => {
  const { FilterData, setFilterData } = useContext(FilterInfo);
  const options = [
    { label: "ارزانترین", value: "LowestPrice" },
    { label: "گرانترین", value: "HighestPrice" },
    { label: "متراژ بالا", value: "HighestArea" },
    { label: "متراژ پایین", value: "LowestArea" },
  ];
  const onChangeHandler=(e)=>{
    if(e){
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: FilterData.sortby,
        isTavizi: true,
      };
      setFilterData(items);
      searchUpdate();
    }
    else{
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: FilterData.sortby,
        isTavizi: false,
      };
      setFilterData(items);
      searchUpdate();
    }
  }

  const SortHandler = async (e) => {
    if (e.target.value === "LowestPrice") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 0,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestPrice") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 1,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestArea") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 2,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "LowestArea") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 3,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    }
  };

  return (
    <section className={Style.ContainerHolder}>
      <div className={Style.FilterIcon} onClick={() => openModalHandler2()}>
        <FontAwesomeIcon icon={faFilter} />
      </div>
      <div className={Style.FilterPart}>
        <DakheliFilter searchUpdate={searchUpdate} />
      </div>
      <div className={Style.ListPart}>
        <p onClick={() => openModalHandler()} className={Style.SortItemBTN}>
          <FontAwesomeIcon icon={faSort} />
           مرتب سازی بر اساس
        </p>
        <div className={Style.SortItemHolder}>
          <p>
            مرتب سازی بر اساس  <FontAwesomeIcon icon={faSort} />
             : 
          </p>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={SortHandler}
            defaultValue={
              FilterData.sortby === 0
                ? "LowestPrice"
                : FilterData.sortby === 1
                ? "HighestPrice" 
                : FilterData.sortby === 2
                ? "HighestArea" 
                : FilterData.sortby === 3
                ? "LowestArea" 
                : null
            }
          />
          <div className={Style.TahatorHolder}>
            <Switch onChange={onChangeHandler} /> تهاتر
          </div>
        </div>
        <hr />
        {data === undefined ? (
          <div className="w-100 text-center mt-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : data.list.length === 0 ? (
          <p className="w-100 text-center">!موردی یافت نشد</p>
        ) : (
          <ResultList
            list={data.list}
            totalPosts={data.pagination.totalPages * 32}
            updatePage={updatePage}
          />
        )}
      </div>
    </section>
  );
};

export default DakheliResultsContainer;
