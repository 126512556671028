import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { errorToast,succToast } from '../../../Utils/Toast/Toast';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import Style from './ChangePasswordForm.module.scss'
import { changePasswordAPI } from '../../../Api/BarangizanServices';
import {  useNavigate } from 'react-router-dom';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';

const ChangePasswordForm = () => {
    const history = useNavigate();
    const {register,handleSubmit, formState: { errors } } = useForm();
    const[loadingState,setLoadingState]=useState()
    const onSubmit = (data) => {
      setLoadingState(true)
      if(data.Password!==data.rePassword){
        errorToast('رمز های وارد شده یکسان نیست!')
        setLoadingState(false)
      }
      else{
        
        let info={
          password:data.Password
        }
        changePasswordAPI(info,(succ,res)=>{
          if(succ){
            succToast(res.data.message)
            setLoadingState(false)
            setTimeout(()=>history('../panel'))
          }
          else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
          }
        })
      }

    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
      <LoadingPage show={loadingState}/>
        <h4>
            رمزعبور جدید
            <FontAwesomeIcon icon={faKey}/>
        </h4>

        <label className={Style.inputName}>رمزعبور جدید<span className={Style.redColor}>*</span></label>
        <Input type='password' {...register('Password',{ required: true })}  inputtype={errors.Password ? "valid" : "text"} />

        <label className={Style.inputName}>تکرار رمزعبور<span className={Style.redColor}>*</span></label>
        <Input type='password'  {...register('rePassword',{ required: true })}  inputtype={errors.rePassword ? "valid" : "text"} />

        <div className={Style.btnHolder}>
            <Button value='ثبت' classbtn={'confrimBtn'}/>
        </div>
    </form>
  );
};

export default ChangePasswordForm;
