import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegisterForeignUserAPI, RegisterUserAPI, VerifyAccountAPI, VerifyForeignAccountAPI } from '../../../Api/BarangizanServices';
import { itemInfo } from '../../../Utils/Context/UserPanelItemIdContext';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Timer from '../../Timer/Timer';
import Style from './ConfirmCodeForm.module.scss'
const ConfirmCodeForm = () => {
  const{type}=useParams()
    const {register,handleSubmit, formState: { errors } } = useForm();
    const {state}=useLocation()
    const history = useNavigate();
    const[resend,setResend]=useState(false)
    const[timer,setTimer]=useState('')
    const[loadingState,setLoadingState]=useState(false)
    const{setItemId}=useContext(itemInfo)
    useEffect(()=>{
      sendUserInfoHandler()
    },[])

    const sendUserInfoHandler=()=>{
      setLoadingState(true)
      let info={
        UserType:parseInt(state.UserType),
        FirstName:state.FirstName,
        LastName:state.LastName,
        PhoneNumber:type==='khareji'?state.Email:state.PhoneNumber,
        Email:state.Email,
        Password:state.Password,
        ConfirmPassword:state.ConfirmPassword
      }
      if(type==='dakheli'){
        RegisterUserAPI(info,(succ,res)=>{
          if(succ){
            if(res.newSms){
              succToast('کد تایید برای شما ارسال شد!')
            }
            setLoadingState(false)
            setTimer(parseInt(res.data.data.totalSeconds))
            setResend(false)
            setTimeout(()=>setResend(true),parseInt(`${parseInt(res.data.data.totalSeconds)}000`))
          }
          else{
            setLoadingState(false)
            errorToast(res.data.errors[0])
            setTimeout(() => {
              history(`../${type}/register`)
            }, 2000);
          }
        })
      }
      else if(type==='khareji'){
        RegisterForeignUserAPI(info,(succ,res)=>{
          if(succ){
            if(res.newSms){
              succToast('کد تایید برای شما ارسال شد!')
            }
            setLoadingState(false)
            setTimer(parseInt(res.data.data.totalSeconds))
            setResend(false)
            setTimeout(()=>setResend(true),parseInt(`${parseInt(res.data.data.totalSeconds)}000`))
          }
          else{
            setLoadingState(false)
            errorToast(res.data.errors[0])
            setTimeout(() => {
              history(`../${type}/register`)
            }, 2000);
          }
        })
      }
      else{
        errorToast('با لینک درست وارد شوید')
            setTimeout(() => {
              history(`../${type}/register`)
            }, 2000);
      }
    }
    const onSubmit = (data) => {
      setLoadingState(true)
      let info={
        UserType:parseInt(state.UserType),
        FirstName:state.FirstName,
        LastName:state.LastName,
        PhoneNumber:type==='khareji'?state.Email:state.PhoneNumber,
        Email:state.Email,
        Password:state.Password,
        ConfirmPassword:state.ConfirmPassword,
        code:data.code
      }
      if(type==='dakheli'){
        VerifyAccountAPI(info,(succ,res)=>{
          if(succ){
            setLoadingState(false)
            succToast(res.data.message)
            setItemId(1)
            localStorage.setItem('BarangizanToken',res.data.data.token)
            setTimeout(()=>history(`../${type}/panel`))
          }
          else{
            setLoadingState(false)
            errorToast(res.data.errors[0])
          }
        })
      }
      else if(type==='khareji'){
        VerifyForeignAccountAPI(info,(succ,res)=>{
          if(succ){
            setLoadingState(false)
            succToast(res.data.message)
            setItemId(1)
            localStorage.setItem("BarangizanToken", res.data.data.token);
            localStorage.setItem("userType", res.data.data.userType);
            localStorage.setItem("isForeign", res.data.data.isForeign);
            if (res.data.data.userType === 1 && res.data.data.isForeign) {
              setTimeout(() => history(`../${type}/englishpanel/profile`), 2000);
            } else {
              setTimeout(() => history(`../${type}/panel`), 2000);
            }
          }
          else{
            setLoadingState(false)
            errorToast(res.data.errors[0])
          }
        })
      }
      else{
        errorToast('با لینک درست وارد شوید')
            setTimeout(() => {
              history(`../${type}/register`)
            }, 2000);
      }
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
        <LoadingPage show={loadingState}/>
        <h4>
             کد تایید دریافتی را وارد کنید!
            <FontAwesomeIcon icon={faDoorOpen}/>
        </h4>

        <label className={Style.inputName}>کد تایید<span className={Style.redColor}>*</span></label>
        <Input type='number' {...register('code',{ required: true })} style={{textAlign:'center'}} inputtype={errors.code ? "valid" : "text"} />

        {resend?<p className={Style.resend} onClick={()=>sendUserInfoHandler()}>ارسال دوباره</p>:<Timer  Time={timer} />}
        <div className={Style.btnHolder}>
            <Button value='تایید' classbtn={'confrimBtn'}/>
        </div>
    </form>
  )
}

export default ConfirmCodeForm