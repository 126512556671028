import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  getCountriesListAPI,
  getForeignCitylistAPI,
  getForeignPropertyDetailsAPI,
  getForeignPropertyTypeAPI,
  RequestEditForeignPropertyAPI,
} from "../../../Api/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
import Select from "react-select";
import Style from "./EditForeignPropertyEnglishPanel.module.scss";

const EditForeignPropertyEnglishPanel = ({ closeModal, code, getUpdate }) => {
  const [loadingState, setLoadingState] = useState(false);
  const [loadingType, setLoadingType] = useState(true);
  const [RentState, setRentState] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [propertyDetail, setPropertyDetail] = useState();
  const [propertyTypeValue, setPropertyValue] = useState();
  const [countryList, setCountryList] = useState("");
  const [citiesList, setCitiestList] = useState();
  const [CityName, setCityName] = useState("");
  const [countrySelect, setCountrySelect] = useState();
  const [UserList, setUserList] = useState();
  const [userSelect, setUserSelect] = useState();
  const [featureListState, setFeatureListState] = useState();
  const [description, setDescription] = useState("");
  const [ImageSelected, setImageSelected] = useState();
  const [TahatorState, setTahatorState] = useState(false);
  const [countryListTahator, setCountryListTahator] = useState(null);
  useEffect(() => {
    getDataHandler();
  }, []);

  const getDataHandler = async () => {
    const data = await getCountriesListAPI();
    setCountryList(data.data.list);
    const propertyType = await getForeignPropertyTypeAPI();
    setPropertyType(propertyType.data.list);
    setLoadingType(false);
    const detail = await getForeignPropertyDetailsAPI(code);
    setPropertyDetail(detail.data.estate);
    setTahatorState(detail.data.estate.isTahator);
    const cities = await getForeignCitylistAPI(detail.data.estate.farsiCountry);
    setCitiestList(cities.data.list);
    setFeatureListState(detail.data.featuresList);
    setRentState(detail.data.estate.isEjareyi);
  };
  const getCityListHandler = async (info) => {
    setCountrySelect(info);
    const cities = await getForeignCitylistAPI(info.value);
    setCitiestList(cities.data.list);
  };
  const checkedImageHandler = (e) => {
    setImageSelected(e);
  };
  const defaultCountry =
    propertyDetail &&
    countryList &&
    countryList.find((element) => element.englishTitle === propertyDetail.country);
  const defaultCity =
    propertyDetail &&
    citiesList &&
    citiesList.find((element) => element.englishTitle === propertyDetail.city);

  const defaultType =
    propertyDetail &&
    propertyType &&
    propertyType.find(
      (element) => element.title === propertyDetail.estatesType
    );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  const defaultTahatorCountries = propertyDetail?.tahatorCountries?.split("_");
  const TahatorHandler = (e) => {
    setTahatorState(e.target.checked);
  };
  const seletCountryTahatorHandler = (e) => {
    let CountryListSelected = [];
    e.forEach((element) => {
      CountryListSelected.push(element.label);
    });
    setCountryListTahator(CountryListSelected.join("_"));
  };

  const onSubmit = (data) => {
    if (ImageSelected === undefined || ImageSelected.length === 0) {
      errorToast("please choose one picture!");
    } else {
      setLoadingState(true);
      let formData = new FormData();
      formData.append("EstateCode", code);
      formData.append("IsTahator", data.Tahator);
      formData.append(
        "TahatorCountries",
        countryListTahator === null
          ? defaultTahatorCountries
          : countryListTahator
      );
      formData.append(
        "EstatesTypeId",
        propertyTypeValue === undefined
          ? propertyDetail.estateTypeId
          : propertyTypeValue.value
      );
      formData.append("title", data.title);
      formData.append(
        "description",
        description === "" ? propertyDetail.description : description
      );
      formData.append(
        "Country",
        countrySelect === undefined ? defaultCountry.title : countrySelect.label
      );
      formData.append(
        "CountryId",
        countrySelect === undefined ? defaultCountry.id : countrySelect.value
      );
      formData.append(
        "city",
        CityName === "" ? defaultCity.title : CityName.label
      );
      formData.append("Metraj", data.Metraj);
      formData.append("price", data.price);
      formData.append("address", data.Address);
        console.log(data.Features[0])
      for (var i = 0; i < parseInt(featureListState.length); i++) {
        
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString = [];
        data.Features[i].value.forEach((element) => {
          featureString.push(element.value);
        });
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
      for (var j = 0; j < parseInt(ImageSelected.length); j++) {
        formData.append(`KeepImages[${j}].Id`, ImageSelected[j]);
      }
      for (let index = 0; index < ImageFile.length; index++) {
        formData.append(`Images[${index}].IsVideo`, false);
        formData.append(`Images[${index}].Item`, ImageFile[index]);
      }

      RequestEditForeignPropertyAPI(formData, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false);
          reset();
          closeModal();
          getUpdate();
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    }
  };
  let defaultValueFeature = [];
  featureListState &&
    featureListState.forEach((element) => {
      let variable = element.value !== null ? element.englishValue.split(','): [""];
      let testarray=[]
      let index=0
      variable.slice().reverse().forEach((el) => {
        testarray.push({
          label: el,
          value: element.value.split(',')[index],
        });
        index=index+1

      });
      defaultValueFeature.push(testarray);
    });
    
  const [ImageFile, setImageFile] = useState([]);
  const [ImageUrls, setImageUrls] = useState([]);
  const imageChange = (evt) => {
    setImageFile(evt.target.files);
    let imageUrlList = [];
    Array.from(evt.target.files).forEach((element) =>
      imageUrlList.push(URL.createObjectURL(element))
    );
    setImageUrls(imageUrlList);
  };
  const citySelectHandler=(e)=>{
    setCityName(citiesList.find(element=>element.englishTitle===e.label).title)
  }
  return (
    <>
      {propertyDetail !== undefined ? (
        <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
          <LoadingPage show={loadingState} />
          <ul className={Style.InputHolder}>
            <li>
              <label htmlFor="type">Property Type</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={setPropertyValue}
                  options={
                    propertyType &&
                    propertyType.map((item) => {
                      return { value: item.id, label: item.englishtitle };
                    })
                  }
                  name="type"
                  placeholder="select..."
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                  defaultValue={{
                    label: propertyDetail.estateType,
                    value: propertyDetail.estateTypeId,
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="title">Property title</label>
              <input
                {...register("title", { required: false })}
                className={errors.title ? "valid" : "text"}
                autoComplete="title"
                type="text"
                name="title"
                defaultValue={propertyDetail.title}
                style={{
                  marginBottom: "0.5rem",
                  direction: "ltr",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              />
            </li>

            <li>
              <label htmlFor="country"> Country</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("country", { required: false })}
                  onChange={getCityListHandler}
                  options={
                    countryList &&
                    countryList.map((item) => {
                      return { value: item.id, label: item.englishTitle };
                    })
                  }
                  name="province"
                  placeholder="select"
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                  defaultValue={{
                    label: defaultCountry.englishTitle,
                    value: defaultCountry.id,
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="city"> City</label>
              {defaultCity === undefined ? null : (
                <div className={Style.selectOption}>
                  <Select
                    {...register("city", { required: false })}
                    options={
                      citiesList &&
                      citiesList.map((item) => {
                        return { value: item.id, label: item.englishTitle };
                      })
                    }
                    name="city"
                    onChange={citySelectHandler}
                    placeholder="select"
                    className={Style.selectOptionsearch}
                    isLoading={defaultCity === undefined}
                    defaultValue={{
                      label: defaultCity.englishTitle,
                      value: defaultCity.id,
                    }}
                  />
                </div>
              )}
            </li>

            <li>
              <label htmlFor="Metraj">Area </label>
              <input
                {...register("Metraj", { required: true })}
                className={errors.Metraj ? "valid" : "text"}
                autoComplete="Metraj"
                type="text"
                name="Metraj"
                defaultValue={propertyDetail.area}
                style={{
                  marginBottom: "0.5rem",
                  direction: "ltr",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              />
            </li>
            <li>
              <label htmlFor="price">Price </label>
              <input
                {...register("price", { required: true })}
                className={errors.price ? "valid" : "text"}
                autoComplete="price"
                type="number"
                name="price"
                defaultValue={propertyDetail.price}
                style={{
                  marginBottom: "0.5rem",
                  direction: "ltr",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              />
            </li>

            <li>
              <label htmlFor="foreignEstateSellingType">Selling Type</label>
              <div className={Style.selectOption}>
                <select
                  defaultValue={propertyDetail.foreignEstateSellingType}
                  name="foreignEstateSellingType"
                  id=""
                  className="text"
                  {...register("foreignEstateSellingType", { required: false })}
                >
                  <option value={0}>Cash</option>
                  <option value={1}>Installments</option>
                  <option value={2}>Cash,Installments</option>
                </select>
              </div>
            </li>
            <li className={Style.CheckBoxHolder}>
              <label htmlFor="Rent">  It is rented </label>
              <input
                defaultChecked={propertyDetail.isEjareyi}
                style={{ accentColor: "blue" }}
                type={"checkbox"}
                id={"Rent"}
                onChange={(e) => setRentState(e.target.checked)}
              />
            </li>

            {RentState ? (
              <li>
                <label htmlFor="prepayment"> Pre Payment </label>
                <input
                  {...register("prepayment", { required: true })}
                  className={errors.prepayment ? "valid" : "text"}
                  autoComplete="prepayment"
                  type="number"
                  name="prepayment"
                  defaultValue={propertyDetail.pish}
                  style={{
                    marginBottom: "0.5rem",
                    direction: "ltr",
                    textAlign: "left",
                    fontSize: "1rem",
                  }}
                />
              </li>
            ) : null}
            {RentState ? (
              <li>
                <label htmlFor="monthlyRent">Rent </label>
                <input
                  {...register("monthlyRent", { required: true })}
                  className={errors.monthlyRent ? "valid" : "text"}
                  autoComplete="monthlyRent"
                  type="number"
                  name="monthlyRent"
                  defaultValue={propertyDetail.mahiane}
                  style={{
                    marginBottom: "0.5rem",
                    direction: "ltr",
                    textAlign: "left",
                    fontSize: "1rem",
                  }}
                />
              </li>
            ) : null}

            {RentState ? (
              <li>
                <label htmlFor="ChargingFee">ChargingFee </label>
                <input
                  {...register("ChargingFee", { required: true })}
                  className={errors.ChargingFee ? "valid" : "text"}
                  autoComplete="ChargingFee"
                  type="number"
                  name="ChargingFee"
                  defaultValue={propertyDetail.sharj}
                  style={{
                    marginBottom: "0.5rem",
                    direction: "ltr",
                    textAlign: "left",
                    fontSize: "1rem",
                  }}
                />
              </li>
            ) : null}
            <li>
              <label htmlFor="Tahator" className={Style.TahatorCheckBox}>
                <input
                  type={"checkbox"}
                  id="Tahator"
                  {...register("Tahator", { required: false })}
                  defaultChecked={propertyDetail.isTahator}
                  onChange={TahatorHandler}
                  style={{
                    marginBottom: "0.5rem",
                    direction: "ltr",
                    textAlign: "left",
                    fontSize: "1rem",
                  }}
                />
                Exchange
              </label>
            </li>
            {TahatorState ? (
              <li>
                <label htmlFor="type"> Countries for Exchange</label>
                <div className={Style.selectOption}>
                  <Select
                    {...register("type", { required: false })}
                    onChange={seletCountryTahatorHandler}
                    options={countryList?.map((item) => {
                      return { value: item.title, label: item.englishTitle };
                    })}
                    name="type"
                    placeholder=" select..."
                    className={Style.selectOptionsearch}
                    defaultValue={defaultTahatorCountries?.map((item) => {
                      return { value: item, label: item };
                    })}
                    isMulti
                  />
                </div>
              </li>
            ) : null}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="Address"> Address</label>
            <textarea
              {...register("Address", { required: true })}
              className={errors.Address ? "valid" : "text"}
              autoComplete="Address"
              type="text"
              name="Address"
              defaultValue={propertyDetail.address}
              style={{
                marginBottom: "0.5rem",
                direction: "ltr",
                textAlign: "left",
                fontSize: "1rem",
              }}
            />
          </div>
          <ul className={Style.InputHolder}>
            {featureListState &&
              featureListState.map((item, i) => {
                return (
                  <li key={item.id}>
                    <label htmlFor="featureCount">{`  Feature ${i + 1}`}</label>
                    <input
                      {...register(`Features[${i}].Key`)}
                      className={errors.username ? "valid" : "text"}
                      autoComplete={`Features[${i}].Key`}
                      type="text"
                      name={`Features[${i}].Key`}
                      style={{ marginBottom: "0.5rem" }}
                      defaultValue={item.englishText}
                      readOnly
                    />
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <div className={Style.selectOption}>
                          <Select
                            // defaultValue={{label:defaultValueFeature[i].label,value:defaultValueFeature[i].value}}
                            defaultValue={defaultValueFeature[i]}
                            // defaultValue={defaultValueFeature[i].map((item) => {
                            //   return {
                            //     label: item.label,
                            //     value: item.value,
                            //   };
                            // })}
                            options={item.options.map((item) => {
                              return {
                                label: item.englishText,
                                value: item.text,
                              };
                            })}
                            name={`Features[${i}].value`}
                            // onChange={setFeatureTest}
                            onChange={onChange}
                            placeholder="Select..."
                            className={Style.selectOptionsearch}
                            isMulti
                          />
                        </div>
                      )}
                      name={`Features[${i}].value`}
                      control={control}
                      rules={{ required: false }}
                      // defaultValue={defaultValueFeature[i].map((item) => {
                      //   return {
                      //     label: item.label,
                      //     value: item.value,
                      //   };
                      // })}
                      defaultValue={defaultValueFeature[i]}
                      // defaultValue={{label:defaultValueFeature[i].label,value:defaultValueFeature[i].value}}
                    />
                  </li>
                );
              })}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="description"> More Details</label>
            <textarea
              defaultValue={propertyDetail.description}
              {...register("description", { required: true })}
              className={errors.description ? "valid" : "text"}
              autoComplete="description"
              type="text"
              name="description"
              style={{
                marginBottom: "0.5rem",
                direction: "ltr",
                textAlign: "left",
                fontSize: "1rem",
              }}
            />
          </div>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={checkedImageHandler}
          >
            {propertyDetail.images.map((item) => {
              return (
                <Checkbox value={item.id}>
                  <div className={Style.ImageHolder}>
                    <img
                      src={`https://api.barangizan.ir${item.url}`}
                      alt="property"
                    />
                  </div>
                </Checkbox>
              );
            })}
          </Checkbox.Group>
          <ul className={Style.ImagePart}>
            <li>
              <label htmlFor="picture" className={Style.ImageLabel}>
                <input
                  onChange={(e) => imageChange(e)}
                  type="file"
                  name=""
                  id="picture"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple

                />
                آپلود تصاویر
              </label>
            </li>
          </ul>
          <ul className={Style.ImagePart}>
            {ImageUrls &&
              ImageUrls.map((item, index) => {
                return (
                  <li key={index} className={Style.ImagePerview}>
                    <img
                      src={item}
                      className={Style.ImagePerview}
                      alt=""
                      onClick={() => window.open(item, "_blank")}
                    />
                  </li>
                );
              })}
          </ul>

          <Button className="w-100 my-3" type="submit">
            Edit
          </Button>
        </form>
      ) : (
        <Spinner animation="border" variant="warning" />
      )}
    </>
  );
};

export default EditForeignPropertyEnglishPanel;
