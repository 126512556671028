import React, { useState } from 'react'
import Style from './ThumbsSwiper.module.scss'
import { Swiper, SwiperSlide  } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

const ThumbsSwiper = ({data}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);


  return (
    <div className={Style.SectionHolder}>
        <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={Style.mySwiper2}
      >
        {
          data&&data.map(item=>{
            return(
              <SwiperSlide key={item.id} className={Style.SwiperSlide} >
                {
                  item.isVideo? <video className={Style.VideoHolder} controls>
                  <source
                    src={`https://api.barangizan.ir${item.url}`}
                    type="video/mp4"
                  />
                  Your browser does not support HTML video.
                </video>:<img src={`https://api.barangizan.ir${item.url}`} alt='ملک'/>
                }
              </SwiperSlide >
            )
          })
        }

      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className={Style.mySwiper}
      >
         {
          data&&data.map(item=>{
            return(
              <SwiperSlide key={item.id} className={Style.SwiperSlide} >
                {
                  item.isVideo? <video className={Style.VideoHolder} controls>
                  <source
                    src={`https://api.barangizan.ir${item.url}`}
                    type="video/mp4"
                  />
                  Your browser does not support HTML video.
                </video>:<img src={`https://api.barangizan.ir${item.url}`} alt='ملک'/>
                }
              </SwiperSlide >
            )
          })
        }
      </Swiper>
    </div>
  )
}

export default ThumbsSwiper