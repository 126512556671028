import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Style from "./CooperationFormPerson.module.scss";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { CooperationFormPersonAPI } from "../../../Api/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";

const CooperationFormPerson = () => {
  const history = useNavigate();
  const [loadinstState, setLoadingState] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoadingState(true);
    let formData = new FormData();
    formData.append("Name", data.Name);
    formData.append("PhoneNumber", data.ownerPhoneNumber);
    formData.append("WhatsappNumber", data.whatsappNumber);
    formData.append("Email", data.mail);
    formData.append("NationalCardFrontImage", data.nationalCart1[0]);
    formData.append("NationalCardBackImage", data.nationalCart2[0]);
    formData.append("IdCardFirstPageImage", data.nationalCart3[0]);
    formData.append("Description", data.explain);
    CooperationFormPersonAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        reset();
        setLoadingState(false);
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
      <LoadingPage show={loadinstState} />
      <h4>
        فرم همکاری  
        <FontAwesomeIcon icon={faHandshake} />
      </h4>
      <div className={Style.TopInputs}>
        <div className={Style.FormCol}>
          <label className={Style.inputName}>
            نام <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="text"
            {...register("Name", { required: true })}
            inputtype={errors.Name ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            تلفن همراه <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="tel"
            placeholder="+9891111111111"
            {...register("ownerPhoneNumber", { required: true })}
            inputtype={errors.ownerPhoneNumber ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            شماره واتساپ <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="tel"
            {...register("whatsappNumber", { required: true })}
            inputtype={errors.whatsappNumber ? "valid" : "text"}
          />
        </div>
        <div className={`${Style.FormCol} ${Style.FormCol2}`}>
          <label className={Style.inputName}>ایمیل </label>
          <Input
            type="mail"
            {...register("mail", { required: false })}
            inputtype={errors.mail ? "valid" : "text"}
          />

          <label htmlFor="nationalCart1" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart1"
              style={{ display: "none" }}
              {...register("nationalCart1", { required: true })}
            />
            آپلود روي كارت ملي فرد
          </label>
          {errors.nationalCart1 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
          <label htmlFor="nationalCart2" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart2"
              style={{ display: "none" }}
              {...register("nationalCart2", { required: true })}
            />
            آپلود پشت كارت ملي فرد
          </label>
          {errors.nationalCart2 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
          <label htmlFor="nationalCart3" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart3"
              style={{ display: "none" }}
              {...register("nationalCart3", { required: true })}
            />
            آپلود صفحه اول شناسنامه فرد
          </label>
          {errors.nationalCart3 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
        </div>
      </div>
      <div className={Style.ExplainHolder}>
        <label className={Style.inputName}>
          توضيحات درخواست و زمينه هاي فعاليت{" "}
        </label>
        <Input
          type="texarea"
          {...register("explain", { required: true })}
          inputtype={errors.explain ? "validTextArea" : "textArea"}
        />
      </div>
      <Button
        type="submit"
        value="ارسال فرم"
        classbtn="submitBtn"
        style={{ width: "100%", margin: "1rem 0" }}
      />
    </form>
  );
};

export default CooperationFormPerson;
