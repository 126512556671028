import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Style from './PropertyProjectSlider.module.scss'

import "swiper/css/navigation"
import SwiperCore, {
    Navigation
  } from 'swiper';
SwiperCore.use([Navigation]);
const PropertyProjectSlider = ({info}) => {
  return (
    <div className={Style.SliderHolder}>
        <Swiper navigation={true} className={Style.swiper}>
          {
            info.map(item=>{
              return(
                <SwiperSlide className={Style.swiperSlide}><img src={`https://api.barangizan.ir/${item}`} alt="Building" /></SwiperSlide>
              )
            })
          }
        </Swiper>
    </div>
  )
}

export default PropertyProjectSlider