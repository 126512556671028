import React from 'react'
import Style from './TourContainer.module.scss'
import TourOptions from './TourOptions/TourOptions'
const TourContainer = ({data}) => {
  return (
    <section className={Style.ContainerHolder}>
        {data&&<img src={`https://api.barangizan.ir${data.data.image}`} alt=""  />}
        <p>
           {data!==undefined?data.data.description:'درحال دریافت'}
        </p>
        <TourOptions data={data}/>
    </section>
  )
}

export default TourContainer