import React, { useState, useContext } from "react";

export const profile = React.createContext(null);

export const UseUserInfo = () => {
  const pc = useContext(profile);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const UserInfoProvider= ({ children }) => {
  const [userInfo, setUserInfo] = useState();

  return (
    <profile.Provider
      value={{
        userInfo,
        setUserInfo: (value) => {
            setUserInfo(value);
        },
      }}
    >
      {children}
    </profile.Provider>
  );
};

export { UserInfoProvider };
