import React from 'react'
import TopPartHeader from '../TopPartHeader/TopPartHeader'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Style from '../AmlakDakheliHeader/AmlakDakheliHeaders.module.scss'
import { faBuilding, faHandshake, faMap, faNewspaper } from '@fortawesome/free-regular-svg-icons'
import { faBars, faGavel, faHome, faList, faPhone, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const MagazineHeader = () => {
    return (
        <div className='pageHolder' style={{background:'#F2F2F2'}}>
            <TopPartHeader/>
            <Navbar collapseOnSelect expand="lg"  variant='light' className={Style.NavbarHolder}>
            <Container className={Style.NavbarContainer} >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand className={Style.navLogo}>
                <img className={Style.logoNavbar} src='/Assets/Images/Logo/logo.png' alt='برانگیزان'/>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav" className={Style.CollapseHolder}>

                
                <Nav  className={`${Style.NavbarContainer}`}>
                <NavDropdown title={<FontAwesomeIcon icon={faBars}/>} id="basic-nav-dropdown" className={Style.navCourse}>
                    <LinkContainer to={'/khareji'}><Nav.Link className={Style.navText}>  املاک خارجی</Nav.Link></LinkContainer>
                    <LinkContainer to={'/tour'}><Nav.Link className={Style.navText}> توراملاک</Nav.Link></LinkContainer>
                    <LinkContainer to={'/magazine'}><Nav.Link className={Style.navText}> مجله املاک</Nav.Link></LinkContainer>
                </NavDropdown>
                <LinkContainer to={'/'}><Nav.Link className={Style.navText}> <FontAwesomeIcon icon={faHome}/> خانه</Nav.Link></LinkContainer>
                <LinkContainer to={'/panel'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faNewspaper}/> درباره مجله</Nav.Link></LinkContainer>
                <LinkContainer to={'/guide'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faList}/> لیست قیمت ها</Nav.Link></LinkContainer>
                <LinkContainer to={'/termsandrules'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faGavel}/> شرایط و قوانین</Nav.Link></LinkContainer>
                <LinkContainer to={'dakheli/contactus'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faPhone}/> تماس با ما</Nav.Link></LinkContainer>
                </Nav>
                
            </Navbar.Collapse>
            <div className={Style.CreateAdvertising}>
                درخواست ثبت ملک در مجله 
            </div>
            
            </Container>
        </Navbar>
            
        </div>
    )
}

export default MagazineHeader
