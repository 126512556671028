import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import Style from './ForgetPasswordForm.module.scss'
const ForgetPasswordForm = () => {

    const history=useNavigate()

    const {register,handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
      setTimeout(() => {
        history('/verifyphone',{state:data})
      }, 500);
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
        <h4>
             فراموشی رمز عبور
            <FontAwesomeIcon icon={faKey}/>
        </h4>

        <label className={Style.inputName}>شماره تلفن همراه <span className={Style.redColor}>*</span></label>
        <Input type='tel' maxLength='11' {...register('phoneNumber',{ required: true })} placeholder={"مثال:09XXXXXXXXX"} inputtype={errors.phoneNumber ? "valid" : "text"} />

        <div className={Style.btnHolder}>
            <Button value='ارسال' classbtn={'confrimBtn'}/>
        </div>
    </form>
  );
};

export default ForgetPasswordForm;
