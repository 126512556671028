import React from 'react'
import Footer from '../../Components/Footer/Footer'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import TiketContainerMessage from '../../Components/TiketContainer/TiketContainerMessage'

const UserPanelTiketMessage = () => {
  return (
    <div className='pageHolder '>
    {
        window.location.pathname.split('/')[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>
    }
    <TiketContainerMessage/>
    <Footer/>
    </div>
  )
}

export default UserPanelTiketMessage