import React from 'react'
import Style from './LandingParts.module.scss'

const LandingParts = ({source,name}) => {
    return (
        <div className={Style.PartHolder}>
            <img src={`https://api.barangizan.ir${source}`} alt="barangizan" />
            <span></span>
            <p>{name}</p>
            <div className={Style.Divider} >  <h6 >مشاهده</h6> <hr /></div>
        </div>
    )
}

export default LandingParts
