import {
  faHammer,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import AmlakDakheliHeaders from "../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders";
import AmlakKharejiHeader from "../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader";
import Style from "./TermsAndRules.module.scss";
import { Collapse } from "antd";
import AuthTerms from './AuthTerms'
const { Panel } = Collapse;

const TermsAndRules = () => {
  const { type } = useParams();

  return (
    <div className="pageHolder">
      {type === "khareji" ? <AmlakKharejiHeader /> : <AmlakDakheliHeaders />}
      <h3 className={Style.TitleHolder}>
        {" "}
        <FontAwesomeIcon icon={faHammer} color={"goldenrod"} />
         شرایط و قوانین سایت املاک برانگیزان {" "}
        <FontAwesomeIcon icon={faHammer} color={"goldenrod"} />
      </h3>

      <img
        className={Style.ImagePart}
        src="/Assets/Images/Terms/terms.svg"
        alt="Register"
      />

      <Collapse
        defaultActiveKey={["1"]}
        className={Style.CollapseHolder}  
      >
        <Panel showArrow={false} header={` شرایط و قوانین عضویت در سایت `} key="1" extra={<FontAwesomeIcon icon={faLock} color="gold" className={Style.IconHandler}/>}>
          <p className={Style.TextHolder}>{AuthTerms}</p>
        </Panel>
      </Collapse>
      <Footer />
    </div>
  );
};

export default TermsAndRules;
