import React from "react";

var ImageStateContext = React.createContext();
var ImageDispatchContext = React.createContext();

function ImageReducer(state, action) {
  switch (action.type) {
    case "load":
      return {...state, Image: action.payload};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ImageProvider({children}) {
  var [state, dispatch] = React.useReducer(ImageReducer, {
    Image: [],
  });
  return (
    <ImageStateContext.Provider value={state}>
      <ImageDispatchContext.Provider value={dispatch}>
        {children}
      </ImageDispatchContext.Provider>
    </ImageStateContext.Provider>
  );
}

function useImageState() {
  var context = React.useContext(ImageStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useImageDispatch() {
  var context = React.useContext(ImageDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export {ImageProvider, useImageState, useImageDispatch, plusImage};

// ###########################################################
function plusImage(dispatch,image) {
  dispatch({
    type: "load",
    payload:image
  });
}
