import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {   getPropertyGalleryAPI,  getUnitGalleryAPI,  getUnitInfoAPI } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import Modal from '../../Components/Modal/Modal'
import PropertyContainer from '../../Components/PropertyContainer/PropertyContainer'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import ThumbsSwiper from '../../Components/Sliders/ThumbsSwiper/ThumbsSwiper'
import TopPageDetail from '../../Components/TopPageDetail/TopPageDetail'
import UnitContainer from '../../Components/UnitContainer/UnitContainer'
import ScrollToTap from '../../Utils/ScrollToTap/ScrollToTap'

const Unit = () => {
  const [purchased, setPurchased] = useState(false);
  const [gallery, setGallery] = useState();
  const {id}=useParams()

  const closeModalHandler=()=>{
      setPurchased(false)
  }
  const openModalHandler=()=>{
      setPurchased(true)
      getPropertyGalleryHandller()
  }
  const [propertyInfoState,setPropertyInfoState]=useState()
  useEffect(()=>{
    getPropertyInfoHandler()
  },[])
  const getPropertyInfoHandler=async()=>{
    const data=await getUnitInfoAPI(id)
    setPropertyInfoState(data.data)
  }

  const getPropertyGalleryHandller=async()=>{
    const data=await getUnitGalleryAPI(id)
    setGallery(data.data.gallery)
  }

  
  
  return (
<ScrollToTap>
    <div className={'pageHolder'}>
          <Modal show={purchased} modalClose={closeModalHandler} translate={50}>
            <ThumbsSwiper data={gallery}/>
          </Modal>
          <AmlakKharejiHeader/>
            <TopPageDetail title={propertyInfoState!==undefined?`${propertyInfoState.project.title} - ${propertyInfoState.project.blockName} - ${propertyInfoState.project.floor}` :'درحال دریافت'} date={propertyInfoState!==undefined?propertyInfoState.project.dateTime:'درحال دریافت'}/>
            <UnitContainer openModalHandler={openModalHandler} data={propertyInfoState} getUpdate={getPropertyInfoHandler}/>
          <Footer/>
      </div>
</ScrollToTap>
  )
}

export default Unit