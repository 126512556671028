import React, { useContext } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import TopPartHeader from '../TopPartHeader/TopPartHeader'
import Style from '../AmlakDakheliHeader/AmlakDakheliHeaders.module.scss'
import { faBuilding, faHandshake, faMap } from '@fortawesome/free-regular-svg-icons'
import { faBars, faHammer, faHome,faPhone,faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { ForeignBarterFilterInfo } from '../../../Utils/Context/ForeingBarterFilterData'
const AmlakKharejiHeader = () => {
    const navigate=useNavigate()
    const { ForeignBarterFilterData,setForeignBarterFilterData } =useContext(ForeignBarterFilterInfo);
    const setFilter=()=>{
        setForeignBarterFilterData({
            estateType: undefined,
            code: undefined,
            country: undefined,
            city: undefined,
            minArea: undefined,
            maxArea:undefined,
            foreignEstateSellingType:undefined,
            minPrice: undefined,
            maxPrice:undefined,
            sortby:0,
            isEjreyi: false,
            isTavizi: true,
          })
          
          
    }
    
    return (
        <div className='pageHolder' style={{background:'#F2F2F2'}}>
            <TopPartHeader/>
            <Navbar collapseOnSelect expand="lg"  variant='light' className={Style.NavbarHolder}>
            <Container className={Style.NavbarContainer} >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand className={Style.navLogo}>
                <img className={Style.logoNavbar} src='/Assets/Images/Logo/logo.png' alt='برانگیزان'/>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav" className={Style.CollapseHolder}>

                
                <Nav  className={`${Style.NavbarContainer}`}>
                <NavDropdown title={<FontAwesomeIcon icon={faBars}/>} id="basic-nav-dropdown" className={Style.navCourse}>
                    <LinkContainer to={'/khareji'}><Nav.Link className={Style.navText}>  املاک خارجی</Nav.Link></LinkContainer>
                    <LinkContainer to={'/tour'}><Nav.Link className={Style.navText}> توراملاک</Nav.Link></LinkContainer>
                    <LinkContainer to={'/magazine'}><Nav.Link className={Style.navText}> مجله املاک</Nav.Link></LinkContainer>
                </NavDropdown>
                <LinkContainer to={'/'}><Nav.Link className={Style.navText}> <FontAwesomeIcon icon={faHome}/> خانه</Nav.Link></LinkContainer>
                {/* <LinkContainer to={'/panel'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faList}/>  درباره ما</Nav.Link></LinkContainer> */}
                <LinkContainer to={'/tour'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faMap}/> تورهای بازدید املاک </Nav.Link></LinkContainer>
                {/* <LinkContainer to={'/forum'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faBuilding}/> اقامت ملکی</Nav.Link></LinkContainer> */}
                <LinkContainer to={'/projects'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faBuilding}/>  پروژهای ملکی </Nav.Link></LinkContainer>
                <LinkContainer to={'/khareji/termsandrules'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faHammer}/> شرایط و قوانین </Nav.Link></LinkContainer>
                <LinkContainer onClick={setFilter} to={'/khareji/foreignbarter'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faSync}/>  تهاتر  </Nav.Link></LinkContainer>
                <LinkContainer to={'/khareji/crequest'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faHandshake}/> درخواست همکاری </Nav.Link></LinkContainer>
                <LinkContainer to={'/khareji/contactus'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faPhone}/>  تماس با ما </Nav.Link></LinkContainer>
                {/* <LinkContainer to={'/crequest'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faBuilding}/> فروش هتل  </Nav.Link></LinkContainer> */}
                </Nav>
                
            </Navbar.Collapse>
            {/* <div className={Style.CreateAdvertising} onClick={()=>history("../khareji/panel")}>
                ثبت آگهی
            </div> */}
            
            </Container>
        </Navbar>
        </div>
    )
}

export default AmlakKharejiHeader
