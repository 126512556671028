import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { getTourListAPI, ShomalTourRegisterAPI } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Button from '../../Button/Button';
import Input from '../../Input/Input'
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Style from './ShomalTourRegisterForm.module.scss'
const ShomalTourRegisterForm = () => {
    const {register,handleSubmit, formState: { errors },reset } = useForm();
    const[Maloliat,setMaloliat]=useState(false)
    const[PPlNumber,setPPlNumber]=useState(0)
    const[TourListState,setTourListState]=useState()
    const[loadingState,setLoadingState]=useState(false)
    const[checkedValue,setCheckeValue]=useState(false)

    const onChangeCheckBox = e => {
        setCheckeValue(e.target.checked);
    };
    const runCallback = (cb) => {
        return cb();
      };
    const MaloliatHandler=(value)=>{
        if(value==='0'){
            setMaloliat(false)
        }
        else{
            setMaloliat(true)
        }
    }
    const onSubmit = (data) => {
        if(checkedValue===false){
            errorToast('ابتدا شرایط و قوانین را تایید فرمایید!')
        }
        else{
            setLoadingState(true)
            let formData=new FormData()
            formData.append('tourId',data.TourDate)
            formData.append('firstName',data.Name)
            formData.append('lastname',data.lastName)
            formData.append('nationalCode',data.nationalCode)
            formData.append('IdNumber',data.ShenasnameNumber)
            formData.append('Email',data.email)
            formData.append('PhoneNumber',data.ownerPhoneNumber)
            formData.append('Whatsapp',data.whatsappNumber)
            formData.append('BloodType',data.BloodType)
            formData.append('IsDisabled',Maloliat)
            formData.append('Disability',data.disabledtText)
            formData.append('ParticipantsCount',parseInt(data.PPlNumber)+parseInt(1))
            formData.append('Descripiton',data.explain)
            formData.append('NationalCodeFrontImage',data.nationalCart1[0])
            formData.append('NationalCodeBackImage',data.nationalCart2[0])
            formData.append('IdCardImage',data.nationalCart3[0])
            for (var i = 0; i < parseInt(data.PPlNumber); i++){
                formData.append(`TourParticipants[${i}].IsUnderUnderCertainAge`,data.certainAge[i+2])
                formData.append(`TourParticipants[${i}].FirstName`,data.TourParticipants[i+2].FirstName)
                formData.append(`TourParticipants[${i}].LastName`,data.TourParticipants[i+2].LastName)
                formData.append(`TourParticipants[${i}].NationalCode`,data.TourParticipants[i+2].nationalCode)
                formData.append(`TourParticipants[${i}].PhoneNumber`,data.TourParticipants[i+2].ownerPhoneNumber)
            }
            
            ShomalTourRegisterAPI(formData,(succ,res)=>{
                setLoadingState(true)
                if(succ){
                    succToast(res.data.message)
                    reset()
                    setLoadingState(false)
                    window.location.replace(res.data.data.payment.url);
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
    
            })
        }
        
       
    }
    
    useEffect(()=>{
        getTourListHandler()
    },[])
    const getTourListHandler=async()=>{
        const data=await getTourListAPI()
        setTourListState(data.data.list)
    }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder} style={{width:'95%'}}>
        <LoadingPage show={loadingState}/>
        <h4>
            فرم ثبت نام در تور   
            <FontAwesomeIcon icon={faHandshake}/>
        </h4>
        <div className={Style.TopInputs}>
            <div className={Style.FormCol}>
                <label className={Style.inputName}>نام <span className={Style.redColor}>*</span></label>
                <Input type='text' {...register('Name',{ required: true })}  inputtype={errors.Name ? "valid" : "text"} />
                 <label className={Style.inputName}> نام خانوادگی <span className={Style.redColor}>*</span></label>
                <Input type='text' {...register('lastName',{ required: true })}  inputtype={errors.lastName ? "valid" : "text"} />
                 <label className={Style.inputName}> کدملی <span className={Style.redColor}>*</span></label>
                <Input type='number' {...register('nationalCode',{ required: true })}  inputtype={errors.nationalCode ? "valid" : "text"} />
                 <label className={Style.inputName}> شماره شناسنامه <span className={Style.redColor}>*</span></label>
                <Input type='number' {...register('ShenasnameNumber',{ required: true })}  inputtype={errors.ShenasnameNumber ? "valid" : "text"} />
                <label className={Style.inputName}>ایمیل <span className={Style.redColor}>*</span></label>
                <Input type='mail' {...register('email',{ required: false })}  inputtype={errors.email ? "valid" : "text"} />
                <label className={Style.inputName}>تلفن همراه <span className={Style.redColor}>*</span></label>
                <Input type='tel' placeholder='+9891111111111' {...register('ownerPhoneNumber',{ required: true })}  inputtype={errors.ownerPhoneNumber ? "valid" : "text"} />
                <label className={Style.inputName}>شماره واتساپ <span className={Style.redColor}>*</span></label>
                <Input type='tel' {...register('whatsappNumber',{ required: true })}  inputtype={errors.whatsappNumber ? "valid" : "text"} />
                <label className={Style.inputName}>گروه خونی</label>
                <Input type='text' {...register('BloodType',{ required: false })}  inputtype={errors.BloodType ? "valid" : "text"} />
                <div className={Style.LocationHolder} >
                    <select name="" id="" style={{width:'100%'}} onChange={(e)=>MaloliatHandler(e.target.value)} >
                        <option value="0">معلولیت جسمی ندارم</option>
                        <option value="1">معلولیت جسمی دارم</option>
                    </select>
                </div>
                {
                    Maloliat?<Input type='text' {...register('disabledtText',{ required: true })}  inputtype={errors.malilatText ? "valid" : "text"}  placeholder='معلولیت خود را بنویسید'/>:null
                }
            </div>
            <div  className={`${Style.FormCol} ${Style.FormCol2}`}>
                <label className={Style.inputName}>تاریخ شرکت در تور  <span className={Style.redColor}>*</span></label>
                <div className={Style.LocationHolder} style={{marginTop:'0'}}>
                    <select name="" id="" style={{width:'100%'}} {...register('TourDate',{ required: true })} defaultValue='0'>
                        <option value="0" disabled >  یک گزینه را انتخاب کنید</option>
                        {TourListState!==undefined?
                            TourListState.map(item=>{
                                return(
                                    <option key={item.id} value={item.id}>{`${item.title} - ${item.startDate}`}</option>
                                )
                            }):<option value="-1">لطفا منتظر بمانید</option>
                            
                        }
                    </select>
                </div>
                <label className={Style.inputName}> تعداد افراد مازاد شرکت کننده در تور <span className={Style.redColor}>*</span></label>
                <Input type='number' min="0" max="12" {...register('PPlNumber',{ required: false })}  inputtype={errors.PPlNumber ? "valid" : "text"} onChange={(e)=>setPPlNumber(e.target.value)} placeholder={'در صورت نبودن شخص اضافه عدد 0 وارد کنید!'}/>
                

                <label htmlFor="nationalCart1" className={Style.FileLabel} style={{marginTop:'1rem'}}>
                    <input type="file" name="" id="nationalCart1" style={{display:'none'}} {...register('nationalCart1',{ required: true })}/>
                    
                    آپلود روي كارت ملي 
                </label>  
                {errors.nationalCart1?<p style={{color:'red'}}>آپلود تصویر روي كارت ملي الزامی است</p>:null}          
                <label htmlFor="nationalCart2" className={Style.FileLabel}>
                    <input type="file" name="" id="nationalCart2" style={{display:'none'}} {...register('nationalCart2',{ required: true })}/>
                    آپلود پشت كارت ملي
                </label>     
                {errors.nationalCart2?<p style={{color:'red'}}>آپلود تصویر پشت كارت ملي الزامی است</p>:null}          
                <label htmlFor="nationalCart3" className={Style.FileLabel}>
                    <input type="file" name="" id="nationalCart3" style={{display:'none'}} {...register('nationalCart3',{ required: true })}/>
                    آپلود صفحه اول شناسنامه  
                </label>       
                {errors.nationalCart3?<p style={{color:'red'}}>آپلود تصویر صفحه اول شناسنامه الزامی است</p>:null}        

            </div>
        </div>
        <ul className={Style.MembersInfo}>
        {
            runCallback(() => {
            const row = [];
            for (var i = 2; i <= parseInt(PPlNumber)+parseInt(1); i++) {
                row.push(
                             <li key={i} className={Style.EachMemberInfo}>
                                <Input type='text' {...register(`TourParticipants[${i}].FirstName`,{ required: true })}  inputtype={errors.Name ? "valid" : "text"} placeholder={ ` نام شخص ${i}`} />
                                <Input type='text' {...register(`TourParticipants[${i}].LastName`,{ required: true })}  inputtype={errors.lastName ? "valid" : "text"}  placeholder={`  نام خانوادگی شخص ${i}`}/>
                                <Input type='number' {...register(`TourParticipants[${i}].nationalCode`,{ required: true })}  inputtype={errors.nationalCode ? "valid" : "text"}  placeholder={` کدملی شخص ${i}` }/>
                                <Input type='tel' placeholder='+9891111111111' {...register(`TourParticipants[${i}].ownerPhoneNumber`,{ required: true })}  inputtype={errors.ownerPhoneNumber ? "valid" : "text"} />
                                <label htmlFor={`certainAge${i}`} style={{width:"100%"}}>
                                شخص دارای سن کمتر از 10 سال میباشد
                                <input id={`certainAge${i}`} type='checkbox'  {...register(`certainAge[${i}]`,{ required: false })} />
                                </label>
                            </li>
                );
            }
            return row;
            })
        }   
        </ul>
        <div className={Style.ExplainHolder}>
            <label className={Style.inputName}>توضيحات لازم(در صورت بیماری خاص لطفا ذکر بفرمایید)</label>
            <Input type='texarea' {...register('explain',{ required: false })}  inputtype={errors.explain ? "valid" : "textArea"} />
        </div>
        <p className={Style.Warning}><span className={Style.redColor}>*</span>در صورت وجود نقص در اطلاعات وارد شده، مسئولیت عواقب آن بر عهده شما می باشد </p>
        <p className={Style.Warning}><span className={Style.redColor}>*</span>بعد از ارسال فرم و بررسی توسط کارشناس با شما تماس گرفته خواهد شد </p>
        <Checkbox onChange={onChangeCheckBox} className={Style.CheckBoxHolder}><Link to={'/termsandrules'}>قوانین و مقرارت</Link> مربوط به بازدید املاک خارجی را مطالعه کردم و موافقم</Checkbox>
        <Button value='ارسال فرم' classbtn='submitBtn' style={{width:'100%',margin:'1rem 0'}}/>        
    </form>
  )
};

export default ShomalTourRegisterForm;
