import React from 'react'
import AddForeignPropertyEnglishForm from '../../Forms/AddForeignPropertyEnglishForm/AddForeignPropertyEnglishForm'

const ForeignUserPanelAddProperty = () => {
  return (
    <div className='pageHolder'>
        <AddForeignPropertyEnglishForm/>
    </div>
  )
}

export default ForeignUserPanelAddProperty