import { Alert } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getForeignUSerPropertyListAPI,
  getUSerPropertyListAPI,
} from "../../../Api/BarangizanServices";
import EditForeignPropertyRegisterForm from "../../Forms/EditForeignPropertyRegisterForm/EditForeignPropertyRegisterForm";
import EditPropertyRegisterForm from "../../Forms/EditPropertyRegisterForm/EditPropertyRegisterForm";
import PropertyUserPanlePerview from "../../PropertyContainer/PropertyUserPanlePerview/PropertyUserPanlePerview";

const UserPanelPersonAdvertising = () => {
  const { type } = useParams();
  const [propertyList, setPropertyList] = useState();
  const [show, setShow] = useState(false);
  const [propertyCode, setPropertyCode] = useState();
  useEffect(() => {
    type === "khareji"
      ? getForeignPropertyListHandler()
      : getPropertyListHandler();
  }, []);
  const getPropertyListHandler = async () => {
    const data = await getUSerPropertyListAPI();
    setPropertyList(data.data.data);
  };
  const getForeignPropertyListHandler = async () => {
    const data = await getForeignUSerPropertyListAPI();
    setPropertyList(data.data.data);
  };
  const handelModal = (code) => {
    setPropertyCode(code);
    setShow(true);
  };
  const userType =
    localStorage.getItem("isForeign") === "true" ? "khareji" : "dakheli";
  return (
    <section className={"flexStyle bg-white h-100"}>
      <Modal show={show} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>ویرایش</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          {userType === "khareji" ? (
            <EditForeignPropertyRegisterForm
              code={propertyCode}
              closeModal={() => setShow(false)}
              getUpdate={() => getPropertyListHandler()}
            />
          ) : (
            <EditPropertyRegisterForm
              code={propertyCode}
              closeModal={() => setShow(false)}
              getUpdate={() => getPropertyListHandler()}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <Alert
        type="warning"
        message={` کاربر گرامی! برای تغییر یا حذف ملک خود لطفا به پشتیبانی تیکت بزنید! `}
        showIcon
        className="mt-2"
        style={{ width: "95%" }}
      />
      {propertyList === undefined ? (
        <Spinner animation="border" variant="warning" className="mt-5" />
      ) : (
        propertyList.map((item, index) => {
          return (
            <PropertyUserPanlePerview
              key={index}
              data={item}
              getUpdate={() => getPropertyListHandler()}
              openModal={handelModal}
            />
          );
        })
      )}
    </section>
  );
};

export default UserPanelPersonAdvertising;
