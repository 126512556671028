import React, { useState, useContext } from "react";

export const ForeignBarterFilterInfo = React.createContext(null);

export const UserFilterInfo = () => {
  const pc = useContext(ForeignBarterFilterInfo);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const ForeignBarterFilterInfoProvider= ({ children }) => {
  const [ForeignBarterFilterData, setForeignBarterFilterData] = useState(JSON.parse(localStorage.getItem('Filters2')));
  const closeIt=()=>{
    localStorage.setItem('Filters2',JSON.stringify(ForeignBarterFilterData))
  } 
  window.onbeforeunload = closeIt;

  return (
    <ForeignBarterFilterInfo.Provider
      value={{
        ForeignBarterFilterData,
        setForeignBarterFilterData: (value) => {
            setForeignBarterFilterData(value);
        },
      }}
    >
      {children}
    </ForeignBarterFilterInfo.Provider>
  );
};

export { ForeignBarterFilterInfoProvider };
