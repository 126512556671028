import React from 'react'
import { useForm } from 'react-hook-form';
import Style from './SharjWalletForm.module.scss'
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDollar } from '@fortawesome/free-solid-svg-icons';
import { CharegeAccountAPI } from '../../../Api/BarangizanServices';
import { useState } from 'react';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
const SharjWalletForm = () => {
    const {register,handleSubmit, formState: { errors } } = useForm();
    const[loadingState,setLoadingState]=useState(false)
    const onSubmit = (data) => {
        setLoadingState(true)
        CharegeAccountAPI(data.money,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                window.location.replace(res.data.data.payment.url);
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
    }

  return (
    <form  onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
        <ul className={Style.itemsHolder}>
            <li>
                <label className={Style.inputName}><FontAwesomeIcon icon={faCommentDollar} color='green'/>  شارژ کیف پول </label>
                <Input type='number' min='50000' {...register('money',{ required: true })} placeholder={"مثال:به تومان وارد شود"} inputtype={errors.money ? "valid" : "text"} />
            </li>
            <li className={Style.btnHolder}>
                <Button value='پرداخت' classbtn='submitBtn' style={{width:'100%'}}/>
            </li>
        </ul>
    </form>
  )
}

export default SharjWalletForm