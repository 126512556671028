import { ArrowDownOutlined } from '@ant-design/icons'
import React from 'react'
import { Button } from 'react-bootstrap'
import Style from './MessageList.module.scss'
const MessageList = ({listInfo}) => {
    
  return (
    <ul className={Style.ListHolder}>
        {
            listInfo.slice(0).reverse().map(item=>{
                return(
                    <li key={item.id}>
                        <div className={item.isAdmin?Style.MessageDetailAdmin:Style.MessageDetailUser}>
                            <p>{item.isAdmin?"ادمین":'کاربر'}</p>
                            <span>{item.dateAdded}</span>
                        </div>
                        <div className={Style.MessageText}>
                            <p >{
                                item.message
                                }
                            </p>
                        </div>
                        {
                        item.file&&<div className='w-100 p-3'><Button variant='info' className='text-light'>دانلود فایل <ArrowDownOutlined /></Button></div>
                            
                        }
                    </li>
                )
            })
        }

    </ul>
  )
}

export default MessageList