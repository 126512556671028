import  Axios  from 'axios';

export const getAxios=()=>{

    return Axios.create({
        baseURL:"https://api.barangizan.ir/api/",
        headers: {
            "Authorization" : `Bearer ${localStorage.getItem("BarangizanToken")}`,
            "Access-Control-Allow-Origin":'*'
        }
    });
};
