import {  Radio, Switch } from 'antd';
import React, { useContext } from 'react';
import { ForeignFilterInfo } from '../../../Utils/Context/ForeignSearchEngineContext';
import { ForeignBarterFilterInfo } from '../../../Utils/Context/ForeingBarterFilterData';
import Style from './ForeignSortItemBarterModal.module.scss'

const ForeignSortItemBarterModal = ({searchUpdate}) => {
    const options = [
        { label: "ارزانترین", value: "LowestPrice" },
        { label: "گرانترین", value: "HighestPrice" },
        { label: "متراژ بالا", value: "HighestArea" },
        { label: "متراژ پایین", value: "LowestArea" },
      ];
    const { ForeignBarterFilterData, setForeignBarterFilterData } =useContext(ForeignBarterFilterInfo);
    // const onChangeHandler = (e) => {
    //   if(e){
    //     let items = {
    //       estateType: ForeignBarterFilterData.estateType,
    //       code: ForeignBarterFilterData.code,
    //       country: ForeignBarterFilterData.country,
    //       foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
    //       city: ForeignBarterFilterData.city,
    //       minArea: ForeignBarterFilterData.minArea,
    //       maxArea: ForeignBarterFilterData.maxArea,
    //       minPrice: ForeignBarterFilterData.minPrice,
    //       maxPrice: ForeignBarterFilterData.maxPrice,
    //       sortby: ForeignBarterFilterData.sortby,
    //       isTavizi:true,
    //       isEjreyi:ForeignBarterFilterData.isEjreyi
    //     };
    //     setForeignBarterFilterData(items);
    //     searchUpdate();
    //   }
    //   else{
    //     let items = {
    //       estateType: ForeignBarterFilterData.estateType,
    //       code: ForeignBarterFilterData.code,
    //       country: ForeignBarterFilterData.country,
    //       foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
    //       city: ForeignBarterFilterData.city,
    //       minArea: ForeignBarterFilterData.minArea,
    //       maxArea: ForeignBarterFilterData.maxArea,
    //       minPrice: ForeignBarterFilterData.minPrice,
    //       maxPrice: ForeignBarterFilterData.maxPrice,
    //       sortby: ForeignBarterFilterData.sortby,
    //       isTavizi:false,
    //       isEjreyi:ForeignBarterFilterData.isEjreyi
    //     };
    //     setForeignBarterFilterData(items);
    //     searchUpdate();
    //   }
    // };
    // const onChangeEjareHandler=(e)=>{
    //   if(e){
    //     let items = {
    //       estateType: ForeignBarterFilterData.estateType,
    //       code: ForeignBarterFilterData.code,
    //       country: ForeignBarterFilterData.country,
    //       foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
    //       city: ForeignBarterFilterData.city,
    //       minArea: ForeignBarterFilterData.minArea,
    //       maxArea: ForeignBarterFilterData.maxArea,
    //       minPrice: ForeignBarterFilterData.minPrice,
    //       maxPrice: ForeignBarterFilterData.maxPrice,
    //       sortby: ForeignBarterFilterData.sortby,
    //       isTavizi:ForeignBarterFilterData.isTavizi,
    //       isEjreyi:true,
          
    //     };
    //     setForeignBarterFilterData(items);
    //     searchUpdate();
    //   }
    //   else{
    //     let items = {
    //       estateType: ForeignBarterFilterData.estateType,
    //       code: ForeignBarterFilterData.code,
    //       country: ForeignBarterFilterData.country,
    //       foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
    //       city: ForeignBarterFilterData.city,
    //       minArea: ForeignBarterFilterData.minArea,
    //       maxArea: ForeignBarterFilterData.maxArea,
    //       minPrice: ForeignBarterFilterData.minPrice,
    //       maxPrice: ForeignBarterFilterData.maxPrice,
    //       sortby: ForeignBarterFilterData.sortby,
    //       isTavizi:ForeignBarterFilterData.isTavizi,
    //       isEjreyi:false,
    //     };
    //     setForeignBarterFilterData(items);
    //     searchUpdate();
    //   }
    // }
    function onChange(e) {
        if (e.target.value === "LowestPrice") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 0,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestPrice") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 1,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestArea") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 2,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "LowestArea") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 3,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        }
        }
        
  return (
    <section className={Style.SortItemModalHolder}>
    {/* <Radio.Group className={Style.typeCheckbox} options={options}  onChange={onChange} defaultValue={
       ForeignBarterFilterData.sortby === 0
       ? "LowestPrice"
       : ForeignBarterFilterData.sortby === 1
       ? "HighestPrice" 
       : ForeignBarterFilterData.sortby === 2
       ? "HighestArea" 
       : ForeignBarterFilterData.sortby === 3
       ? "LowestArea" 
       : null
    }/> */}

</section>
  )
}

export default ForeignSortItemBarterModal