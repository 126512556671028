import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import Style from "./TransactionsList.module.scss";
const TransactionsList = ({ data }) => {
  return (
    <ul className={Style.ListHolder}>
      {data &&
        data.payments.map((item) => {
          return item.isDeposit ? (
            <li key={item.id}>
              <div className={Style.PriceInfo}>
                <h3>{separate(item.amount)} تومان</h3>
                <p>
                  {item.date} <FontAwesomeIcon icon={faCalendar} />
                </p>
                <span>{item.descripiton&&item.descripiton}</span>
              </div>
              <div className={Style.transactionType}>
                <p className={Style.AddMoney}>
                  {" "}
                  <FontAwesomeIcon icon={faPlus} /> به کیف پول شما اضافه شده{" "}
                </p>
              </div>
            </li>
          ) : (
            <li key={item.id}>
              <div className={Style.PriceInfo}>
                <h3>{separate(item.amount)} تومان</h3>
                <p>
                {item.date} <FontAwesomeIcon icon={faCalendar} />
                </p>
                <span>{item.descripiton&&item.descripiton}</span>
              </div>
              <div className={Style.transactionType}>
                <p className={Style.removeMoney}>
                  {" "}
                  <FontAwesomeIcon icon={faMinus} /> ازکیف پول شما برداشته شد
                </p>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default TransactionsList;
