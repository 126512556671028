import React, { useState } from "react";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./ForeignUserPanelContainer.module.scss";
import ForeignUserPanelSideBar from "./ForeignUserPanelSideBar/ForeignUserPanelSideBar";
import { useParams } from "react-router-dom";
import ForeignUserPanelProfile from "./ForeignUserPanelProfile/ForeignUserPanelProfile";
import ForeignUserPanelAddProperty from "./ForeignUserPanelAddProperty/ForeignUserPanelAddProperty";
import ForeignPropertyRegistredList from "./ForeignPropertyRegistredList/ForeignPropertyRegistredList";
import ForeignTikets from "./ForeignTikets/ForeignTikets";
import ForeignTiketsList from "./ForeignTiketsList/ForeignTiketsList";
const ForeignUserPanelContainer = () => {
  const [mobileSideBar, setMobileSideBar] = useState(false);
  const { part } = useParams();
  return (
    <section className={Style.SectionHolder}>
      <div
        className={
          mobileSideBar ? Style.ActiveSideBarHolder : Style.SideBarHolder
        }
      >
        <ForeignUserPanelSideBar CloseSideBar={() => setMobileSideBar(false)} />
      </div>
      <div className={Style.ContentHolder}>
        <div
          className={Style.OpenSideBar}
          onClick={() => setMobileSideBar(true)}
          style={{ display: mobileSideBar ? "none" : null }}
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </div>
        {part === "profile" ? (
          <ForeignUserPanelProfile />
        ) : part === "addpropert" ? (
          <ForeignUserPanelAddProperty />
        ) : part === "propertylist" ? (
          <ForeignPropertyRegistredList />
        ) : part === "sendtiket" ? (
          <ForeignTikets />
        ) : part === "tiketlist" ? (
          <ForeignTiketsList />
        ) : null}
      </div>
    </section>
  );
};

export default ForeignUserPanelContainer;
