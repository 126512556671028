import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTourListAPI, getTourListForonePropertyAPI } from '../../../Api/BarangizanServices';
import Footer from '../../../Components/Footer/Footer';
import TourList from '../../../Components/Lists/TourList/TourList';
import LoadingPage from '../../../Components/Loading/LoadingPage/LoadingPage';
import TourHeader from '../../../Components/SiteHeaders/TourHeader/TourHeader';
import TourTabs from '../../../Components/Tabs/TourTabs/TourTabs';

const TourListPage = () => {
    
    const { state } = useLocation();
    const[TourListState,setTourListState]=useState()
    
    useEffect(()=>{
        state!==null?getTourListHandlerForOneProperty():getTourListHandler()
        
    },[])
    const getTourListHandler=async()=>{
        const data=await getTourListAPI()
        setTourListState(data.data.list)
    }
    const getTourListHandlerForOneProperty=async()=>{
        const data=await getTourListForonePropertyAPI(state)
        setTourListState(data.data.list)
    }
    let LoadingHandler=null
    if(TourListState===undefined){
        LoadingHandler=<LoadingPage show={true}/>
    }
    else{
        LoadingHandler=<TourList data={TourListState}/>
    }

    // const data=[
    //     {
    //         id:1,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:2,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:3,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:4,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:5,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:6,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:7,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:8,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:9,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    //     {
    //         id:10,
    //         name:'تورشماره 1',
    //         date:'13 فروردین',
    //         price:'3000',
    //         imgSrc:'/Assets/Images/Slider/Tour.PNG'
    //     },
    // ]
    const[PropertyKind,setPropertyKind]=useState(false)
  return(
      <div className='pageHolder'>
          <TourHeader/>
          <TourTabs tab={PropertyKind} setProperty={(e)=>setPropertyKind(e)}/>
          {LoadingHandler}
          <Footer/>
      </div>
  )
};

export default TourListPage;
