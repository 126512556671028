import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Checkbox } from "antd";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "react-bootstrap";
import {
  getCityApi,
  getCountriesListAPI,
  getPropertyDetailsAPI,
  getPropertyTypeAPI,
  getProvinceApi,
  RequestEditPropertyAPI,
} from "../../../Api/BarangizanServices";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
import Style from "./EditPropertyRegisterForm.module.scss";
const EditPropertyRegisterForm = ({ code,closeModal,getUpdate }) => {
  const [loadingState, setLoadingState] = useState();
  const [PropertyDetailState, setPropertyDetailState] = useState();
  const [propertyType, setPropertyType] = useState([]);
  const [propertyValue, setPropertyValue] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [cityName, setCityName] = useState("");
  const [featureListState, setFeatureListState] = useState();
  const [UserList, setUserList] = useState();
  const [userSelect, setUserSelect] = useState();
  const [ImageSelected, setImageSelected] = useState();
  const [countryList, setCountryList] = useState(null);
  const [loadingType, setLoadingType] = useState(true);
  const [TahatorState, setTahatorState] = useState(false);
  const [countryListTahator, setCountryListTahator] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    getPropertyDetails();
  }, []);
  const getPropertyDetails = async () => {
    const countries = await getCountriesListAPI();
    setCountryList(countries.data.list);
    const data = await getPropertyDetailsAPI(code);
    setPropertyDetailState(data.data.estate);
    setFeatureListState(data.data.featuresList);
    setTahatorState(data.data.estate.isTahator);
    // const features=await getFeaturesListOptionsAPI()
    const data1 = await getProvinceApi();
    setProvince(data1.data);
    getCityListHandler({ label: data.data.estate.province });

    const propertyType = await getPropertyTypeAPI();
    setPropertyType(propertyType.data.list);
    setLoadingType(false);
  };

  const getCityListHandler = async (id) => {
    setProvinceName(id.label);
    const data = await getCityApi(id.label);
    setCity(data.data);
  };
  let provinceList = [];
  province.forEach((element, index) => {
    provinceList.push({ value: index, label: element });
  });
  const defaultProvince = provinceList.find(
    (element) => element.label === PropertyDetailState.province
  );

  let CityList = [];
  city.forEach((element, index) => {
    CityList.push({ value: index, label: element });
  });
  const defaultCity = CityList.find(
    (element) => element.label === PropertyDetailState.city
  );

  const [description, setDescription] = useState("");
  let defaultValueFeature = [];
  featureListState &&
    featureListState.forEach((element) => {
      let value = element.value !== null ? element.value.split(",") : ["ندارد"];
      let testarray = [];
      value.forEach((element) => {
        testarray.push({
          label: element,
          value: element,
        });
      });
      defaultValueFeature.push(testarray);
    });

  const onSubmit = (data) => {
    if (ImageSelected === undefined || ImageSelected.length === 0) {
      errorToast("حداقل یک عکس انتخاب شود!");
    } else {
      setLoadingState(true);

      let formData = new FormData();
      formData.append("EstateCode", code);
      formData.append("IsTahator", data.Tahator);
      formData.append(
        "TahatorCountries",
        countryListTahator === null
          ? defaultTahatorCountries
          : countryListTahator
      );
      formData.append(
        "EstatesType",
        propertyValue === "" ? PropertyDetailState.type : propertyValue.label
      );
      formData.append(
        "EstatesTypeId",
        propertyValue === "" ? PropertyDetailState.estatesTypeId : propertyValue.value
      );
      formData.append("title", data.title);
      formData.append(
        "description",
        description === "" ? PropertyDetailState.description : description
      );
      formData.append(
        "province",
        provinceName === "" ? defaultProvince.label : provinceName
      );
      formData.append(
        "city",
        cityName === "" ? defaultCity.label : cityName.label
      );
      formData.append("Metraj", data.Metraj);
      formData.append("price", data.price);
      formData.append("Address", data.Address);
      for (var i = 0; i < parseInt(featureListState.length); i++) {
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString = [];
        data.Features[i].value &&
          data.Features[i].value.forEach((element) => {
            featureString.push(element.label);
          });
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
      for (var j = 0; j < parseInt(ImageSelected.length); j++) {
        formData.append(`KeepImages[${j}].Id`, ImageSelected[j]);
      }
      for (let index = 0; index < ImageFile.length; index++) {
        formData.append(`Images[${index}].IsVideo`, false);
        formData.append(`Images[${index}].Item`, ImageFile[index]);
      }
      RequestEditPropertyAPI(formData, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false);
          closeModal()
          getUpdate()
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    }
  };

  const checkedImageHandler = (e) => {
    setImageSelected(e);
  };
  const [ImageFile, setImageFile] = useState([]);
  const [ImageUrls, setImageUrls] = useState([]);
  const imageChange = (evt) => {
    setImageFile(evt.target.files);
    let imageUrlList = [];
    Array.from(evt.target.files).forEach((element) =>
      imageUrlList.push(URL.createObjectURL(element))
    );
    setImageUrls(imageUrlList);
  };
  const defaultTahatorCountries =
    PropertyDetailState?.tahatorCountries?.split("_");
  const TahatorHandler = (e) => {
    setTahatorState(e.target.checked);
  };
  const seletCountryTahatorHandler = (e) => {
    let CountryListSelected = [];
    e.forEach((element) => {
      CountryListSelected.push(element.label);
    });
    setCountryListTahator(CountryListSelected.join("_"));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      {PropertyDetailState === undefined ||
      province.length === 0 ||
      city.length === 0 ? (
        <Spinner animation="border" variant="warning" />
      ) : (
        <>
          <ul className={Style.InputHolder}>
            <li>
              <label htmlFor="type">نوع ملک</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("type", { required: false })}
                  onChange={setPropertyValue}
                  options={propertyType.map((item) => {
                    return { value: item.id, label: item.title };
                  })}
                  defaultValue={{
                    label: PropertyDetailState.type,
                    value: PropertyDetailState.typeId,
                  }}
                  name="type"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  isLoading={loadingType}
                />
              </div>
            </li>
            <li>
              <label htmlFor="title">عنوان ملک</label>
              <input
                {...register("title", { required: true })}
                className={errors.username ? "valid" : "text"}
                autoComplete="title"
                type="text"
                name="title"
                defaultValue={PropertyDetailState.title}
              />
            </li>

            <li>
              <label htmlFor="province"> استان</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("province", { required: false })}
                  onChange={getCityListHandler}
                  options={provinceList}
                  name="province"
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={defaultProvince}
                />
              </div>
            </li>
            <li>
              <label htmlFor="city"> شهر</label>
              <div className={Style.selectOption}>
                <Select
                  {...register("city", { required: false })}
                  options={CityList}
                  name="city"
                  onChange={setCityName}
                  placeholder="انتخاب کنید"
                  className={Style.selectOptionsearch}
                  defaultValue={defaultCity}
                />
              </div>
            </li>

            <li>
              <label htmlFor="Metraj">متراژ </label>
              <input
                {...register("Metraj", { required: true })}
                className={errors.username ? "valid" : "text"}
                autoComplete="Metraj"
                type="text"
                name="Metraj"
                defaultValue={PropertyDetailState.area}
              />
            </li>
            <li>
              <label htmlFor="price">قیمت </label>
              <input
                {...register("price", { required: true })}
                className={errors.username ? "valid" : "text"}
                autoComplete="price"
                type="number"
                name="price"
                placeholder="به تومان"
                defaultValue={PropertyDetailState.price}
              />
            </li>

            <li>
              <label htmlFor="Tahator" className={Style.TahatorCheckBox}>
                <input
                  type={"checkbox"}
                  id="Tahator"
                  {...register("Tahator", { required: false })}
                  defaultChecked={PropertyDetailState.isTahator}
                  onChange={TahatorHandler}
                />
                 تهاتر 
              </label>
            </li>
            {TahatorState ? (
              <li>
                <label htmlFor="type"> کشور برای تهاتر</label>
                <div className={Style.selectOption}>
                  <Select
                    {...register("type", { required: false })}
                    onChange={seletCountryTahatorHandler}
                    options={countryList?.map((item) => {
                      return { value: item.title, label: item.title };
                    })}
                    name="type"
                    placeholder="انتخاب کنید"
                    className={Style.selectOptionsearch}
                    defaultValue={defaultTahatorCountries?.map((item) => {
                      return { value: item, label: item };
                    })}
                    isMulti
                  />
                </div>
              </li>
            ) : null}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="Address">آدرس ملک</label>
            <textarea
              {...register("Address", { required: true })}
              className={errors.Address ? "valid" : "text"}
              autoComplete="Address"
              type="text"
              name="Address"
              defaultValue={PropertyDetailState.address}
            />
          </div>
          <ul className={Style.InputHolder}>
            {featureListState &&
              featureListState.map((item, i) => {
                return (
                  <li key={item.id}>
                    <label htmlFor="featureCount">{`  ویژگی ${i + 1}`}</label>
                    <input
                      {...register(`Features[${i}].Key`)}
                      className={errors.username ? "valid" : "text"}
                      autoComplete={`Features[${i}].Key`}
                      type="text"
                      name={`Features[${i}].Key`}
                      style={{ marginBottom: "0.5rem" }}
                      defaultValue={item.text}
                      readOnly
                    />
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <div className={Style.selectOption}>
                          <Select
                            defaultValue={defaultValueFeature[i]}
                            options={item.options.map((item) => {
                              return {
                                label: item.text,
                                value: item.text,
                              };
                            })}
                            name={`Features[${i}].value`}
                            // onChange={setFeatureTest}
                            onChange={onChange}
                            placeholder="انتخاب کنید"
                            className={Style.selectOptionsearch}
                            isMulti
                          />
                        </div>
                      )}
                      name={`Features[${i}].value`}
                      control={control}
                      rules={{ required: false }}
                      defaultValue={defaultValueFeature[i]}
                    />
                  </li>
                );
              })}
          </ul>
          <div className={Style.DescHolder}>
            <label htmlFor="description">توضیحات ملک</label>
            <textarea
              defaultValue={PropertyDetailState.description}
              {...register("description", { required: true })}
              className={errors.description ? "valid" : "text"}
              autoComplete="description"
              type="text"
              name="description"
              placeholder={"مثال:میزان بر زمین، فاصله از جاده اصلی...."}
            />
          </div>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={checkedImageHandler}
          >
            {PropertyDetailState.images.map((item, index) => {
              return (
                <Checkbox value={item.id}>
                  <div key={index} className={Style.ImageHolder}>
                    <img
                      src={`https://api.barangizan.ir${item.url}`}
                      alt="property"
                    />
                  </div>
                </Checkbox>
              );
            })}
          </Checkbox.Group>
          <ul className={Style.ImagePart}>
            <li>
              <label htmlFor="picture" className={Style.ImageLabel}>
                <input
                  onChange={(e) => imageChange(e)}
                  type="file"
                  name=""
                  id="picture"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple
                />
                آپلود تصاویر
              </label>
            </li>
          </ul>
          <ul className={Style.ImagePart}>
            {ImageUrls &&
              ImageUrls.map((item, index) => {
                return (
                  <li key={index} className={Style.ImagePerview}>
                    <img
                      src={item}
                      className={Style.ImagePerview}
                      alt=""
                      onClick={() => window.open(item, "_blank")}
                    />
                  </li>
                );
              })}
          </ul>
          <Button className="w-100 my-3" type="submit">
            ثبت
          </Button>
        </>
      )}
    </form>
  );
};

export default EditPropertyRegisterForm;
