import React, { useState } from 'react'
import { useEffect } from 'react'
import { getPropertyResevedAPI } from '../../../Api/BarangizanServices'
import PropertyUserPanlePerview from '../../PropertyContainer/PropertyUserPanlePerview/PropertyUserPanlePerview'
import LoadingPage from "../../Loading/LoadingPage/LoadingPage"
import { Alert } from 'antd'
const UserPanelPropertyReserved = () => {
    const[propertyList,setPropertyList]=useState()
    useEffect(()=>{
        getUserPropertyReservd()
    },[])
    const getUserPropertyReservd=async()=>{
        const data=await getPropertyResevedAPI()
        setPropertyList(data.data.list)
    }
    let loadingHandler=null
    if(propertyList===undefined){
        loadingHandler=<LoadingPage show={true}/>
    }
    else if(propertyList.length===0){
        loadingHandler=            <Alert
        type="warning"
        message={` کاربرگرامی شما ملکی رزو نکرده اید!`}
        showIcon
        className="mt-2"
        style={{width:'95%'}}
    />
    }
    else{
        loadingHandler=propertyList.map((item,index)=>{
            return(
                <PropertyUserPanlePerview key={index} data={item}/>
            )
        })
    }
  return (
    <section className={'flexStyle bg-white'}>
        <LoadingPage show={propertyList!==undefined?false:true}/>
    {
       loadingHandler
    }
</section>
  )
}

export default UserPanelPropertyReserved