import { Alert } from 'antd'
import React, { useEffect, useState } from 'react'
import { getVisitPropertyChoice } from '../../../Api/BarangizanServices'
import LoadingPage from '../../Loading/LoadingPage/LoadingPage'
import PropertyUserPanlePerview from '../../PropertyContainer/PropertyUserPanlePerview/PropertyUserPanlePerview'

const UserPanelVisitChoice = () => {
    const[propertyList,setPropertyList]=useState()
    useEffect(()=>{
        getVisitChoiceHandler()
    },[])
    const getVisitChoiceHandler=async()=>{
        const data=await getVisitPropertyChoice()
        setPropertyList(data.data.list)
    }
    let loadingHandler=null
    if(propertyList===undefined){
        loadingHandler=<LoadingPage show={true}/>
    }
    else if(propertyList.length===0){
        loadingHandler=        <Alert
        type="warning"
        message=" شما ملکی را برای بازدید انتخاب نکرده اید!"
        showIcon
        className=" mt-2"
        style={{width:'95%'}}
      />
    }
    else{
        loadingHandler=propertyList.map((item,index)=>{
            return(
                <PropertyUserPanlePerview key={index} data={item} className='w-100'/>
            )
        })
    }
    console.log(propertyList)
  return (
    <div className='flexStyle bg-light'>
         {loadingHandler}
    </div>
  )
}

export default UserPanelVisitChoice