import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import ConfirmCodeForm from '../../../Components/Forms/ConfirmCodeForm/ConfirmCodeForm'
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import Style from './ConfirmCode.module.scss'
const ConfirmCode = () => {
    const { state } = useLocation();

  return (
    <div className={'pageHolder'}>
        {
            window.location.pathname.split('/')[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>
        }
        <section className={Style.ConfirmCodeSection}>
            <img src="/Assets/Images/Auth/Register.svg" alt="Register" />
            <div className={Style.formHolder}>
                <ConfirmCodeForm/>
            </div>
        </section>
        <Footer/>

    </div>
  )
}

export default ConfirmCode