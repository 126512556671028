
import React from 'react'
import Style from './UnitContainer.module.scss'
import PropertyDetails from './PropertyDetails/PropertyDetails'
import PropertyMedia from './PropertyMedia/PropertyMedia'
import UnitReserv from './UnitReserv/UnitReserv'

const UnitContainer = ({openModalHandler,data,getUpdate}) => {
  
  
  return (
    <section className={Style.ContainerHolder}>
        <div className={Style.LeftPart}><UnitReserv openModal={openModalHandler} data={data} getUpdate={getUpdate}/></div>
        <div className={Style.RightPart}>
            <PropertyMedia data={data&&data.project}/>
            <PropertyDetails data={data&&data.project}/>
        </div>
    </section>
  )
}

export default UnitContainer