import React, { useContext, useEffect, useState } from "react";
import Footer from '../../Components/Footer/Footer'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import { getDakheliSearchAPI } from "../../Api/BarangizanServices";
import LoadingPage from "../../Components/Loading/LoadingPage/LoadingPage";
import Modal from "../../Components/Modal/Modal";
import DakheliFilter from "../../Components/Results/DakheliFilter/DakheliFilter";
import DakheliResultsContainer from "../../Components/Results/DakheliResultsContainer/DakheliResultsContainer";
import SortItemModal from "../../Components/Results/SortItemModal/SortItemModal";
import { FilterInfo } from "../../Utils/Context/SearchEngineContext";
import SortItemBarterModal from "../../Components/Results/SortItemBarterModal/SortItemBarterModal";
import DakheliBarterResultContainer from "../../Components/Results/DakheliBarterResultContainer/DakheliBarterResultContainer";
import DakheliBarterFilter from "../../Components/Results/DakheliBarterFilter/DakheliBarterFilter";
import { DakheliBarterFilterInfo } from "../../Utils/Context/DakheliBarterFilterData";

const DakheliBarter = () => {
  const { DakheliBarterFilterData } = useContext(DakheliBarterFilterInfo);
  const [propertyList, setPropertyList] = useState();
  const [purchased, setPurchased] = useState(false);
  const [purchased2, setPurchased2] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const closeModalHandler = () => {
    setPurchased(false);
    setPurchased2(false);
  };
  const openModalHandler = () => {
    setPurchased(true);
  };
  const openModalHandler2 = () => {
    setPurchased2(true);
  };
  useEffect(() => {
    getPropertyListHandler(1);
  }, []);
  const getPropertyListHandler = async (page) => {
    setLoadingState(true)
      const data = await getDakheliSearchAPI(page, DakheliBarterFilterData);
      window.scrollTo(0, 0);
    setPropertyList(data.data);
    setLoadingState(false)
  };

  return (
    <div className='pageHolder'>
        <AmlakDakheliHeaders/>
        <LoadingPage show={loadingState}/>
      <Modal show={purchased} modalClose={closeModalHandler} translate={30}>
        <SortItemBarterModal  searchUpdate={()=>getPropertyListHandler(1)} />
      </Modal>
      <Modal show={purchased2} modalClose={closeModalHandler} translate={50}>
        <DakheliBarterFilter searchUpdate={() => getPropertyListHandler(1)} />
      </Modal>

      <DakheliBarterResultContainer
        updatePage={(e) => getPropertyListHandler(e)}
        searchUpdate={() => getPropertyListHandler(1)}
        data={propertyList}
        openModalHandler={openModalHandler}
        openModalHandler2={openModalHandler2}
      />
        <Footer/>
    </div>
  )
}

export default DakheliBarter