import React, { useState } from 'react';
import { useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

import Style from './FlipBookSlider.module.scss'

// import samplePDF from "./test.pdf";

const FlipBookSlider = ({data}) => {
  //widthState
  const[WidthSlider,setWidthSlider]=useState(300)
  //HeightState
  const[heightSlider,setHeightSlider]=useState(600)

  useEffect(()=>{
    //calculateScreenWidth
    setWidthHandler()
  },[])
  const setWidthHandler=()=>{
    //setHeightWidthBaseOnScreenWidth
    if(window.innerWidth<350){
      setHeightSlider(650)
      setWidthSlider(250)
    }
    else if(window.innerWidth<500){
      setHeightSlider(700)
      setWidthSlider(350)
    }
    else if(window.innerWidth<900){
      setHeightSlider(700)
      setWidthSlider(400)
    }
    else{
      setHeightSlider(800)
      setWidthSlider(500)
    }
  }
  return (

    //useReactPageFlipForSlider
    <HTMLFlipBook style={{direction:'rtl'}} width={WidthSlider} height={heightSlider} autoSize={true} maxShadowOpacity={0.5} showCover={true} mobileScrollSupport={true}>
        {
            //MappingDataForSlider
            data.data.map(item=>{
                return(
                    <img className={Style.ImageHolder} key={item.id} src={`https://api.barangizan.ir${item.url}`} alt="magazine" />
                )
            })
        }
    </HTMLFlipBook>

    )
};

export default FlipBookSlider;
