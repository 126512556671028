
import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber'
import Style from './TourOptions.module.scss'
const TourOptions = ({data}) => {
  const navigate=useNavigate()
  return (
    <section className={Style.SectionHolder}>
        <h3>جزئیات</h3>
        <ul className={Style.DetailsList}>

          <li  className={Style.Detail}>
              <h5>{'نام تور'}</h5>
              <span>{data&&data.data.title}</span>
          </li>
          <li  className={Style.Detail}>
              <h5>{'هزینه ثبت نام '}</h5>
              <span>{separate(data&&data.data.price)} تومان</span>
          </li>

          <li  className={Style.Detail}>
              <h5>{'هزینه برای هرشخص اضافه'}</h5>
              <span>{separate(data&&data.data.pricePerPerson)} تومان</span>
          </li>
          <li  className={Style.Detail}>
              <h5>{`هزینه برای هرشخص زیر ${data&&data.data.certainAge} سال`}</h5>
              <span>{separate(data&&data.data.pricePerPersonUnderCertainAge)} تومان</span>
          </li>

          <li  className={Style.Detail}>
              <h5>{'زمان تور'}</h5>
              <span>{data&&data.data.startDate}</span>
          </li>
          <li  className={Style.Detail}>
              <h5>{'ظرفیت باقی مانده '}</h5>
              <span>{data&&data.data.left}</span>
          </li>

          <li  className={Style.Detail}>
            <Button variant='success' className='w-100' onClick={()=>navigate('/dakheli/tourregister')}>ثبت نام</Button>
          </li>

        </ul>
    </section>
  )
}

export default TourOptions