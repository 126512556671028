import { faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'antd'
import React, { useContext } from 'react'
import { contact } from '../../Utils/Context/ContactInfoContext'
import Style from './Footer.module.scss'

const Footer = () => {
    const isForeign=()=>window.location.pathname.split('/')[1]==='khareji'
    const{contactDetail}=useContext(contact)
    const telegram = contactDetail&&contactDetail.list.find(element => element.key === "Telegram");
    const instagram = contactDetail&&contactDetail.list.find(element => element.key === "Instagram");
    const whatsapp = contactDetail&&contactDetail.list.find(element => element.key === "Whatsapp");
    
    return (
        <section className={`flexStyle ${Style.FooterContainer}`}>
            <div className={Style.TopFooter}>

                <div className={Style.LogoPart}>
                    <img src="/Assets/Images/Logo/logo.png" alt="املاک" />
                    <p>املاک برانگیزان</p>
                </div>
                <div className={Style.SocialMedia}>
                    {/* <a href={`${contactDetail !==undefined?whatsapp.value:"https://api.whatsapp.com/send?phone=989926202287"}`} rel="noreferrer" target={'_blank'}><FontAwesomeIcon icon={faWhatsapp}/></a>
                    <a href={`${contactDetail !==undefined?telegram.value:"https://t.me/+989926202287"}`} rel="noreferrer" target={'_blank'} ><FontAwesomeIcon icon={faTelegram}/></a>
                    <a href={`${contactDetail !==undefined?instagram.value:"https://www.instagram.com/amlak.barangizan"}`} rel="noreferrer" target={'_blank'}><FontAwesomeIcon icon={faInstagram}/></a> */}
                    <a href={isForeign()?`https://api.whatsapp.com/send?phone=905338838282`:`https://api.whatsapp.com/send?phone=989926202287`} rel="noreferrer" target={'_blank'}><FontAwesomeIcon icon={faWhatsapp}/></a>
                    <a href={isForeign()?`https://t.me/+905338838282`:`https://t.me/+989926202287`} rel="noreferrer" target={'_blank'} ><FontAwesomeIcon icon={faTelegram}/></a>
                    <a href={isForeign()?`https://www.instagram.com/applyglobal`:'https://www.instagram.com/amlak.barangizan'} rel="noreferrer" target={'_blank'}><FontAwesomeIcon icon={faInstagram}/></a>
                </div>
            </div>
            
            <p>طراحی و اجرا توسط شرکت <a href="https://jahaanweb.ir/">جهان وب</a></p>
        </section>
    )
}

export default Footer
