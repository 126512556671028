import { Radio, Switch } from "antd";
import React, { useContext } from "react";
import { DakheliBarterFilterInfo } from "../../../Utils/Context/DakheliBarterFilterData";
import { FilterInfo } from "../../../Utils/Context/SearchEngineContext";
import Style from "./SortItemBarterModal.module.scss";

const SortItemBarterModal = ({ searchUpdate }) => {
  const { DakheliBarterFilterData, setDakheliBarterFilterData } = useContext(DakheliBarterFilterInfo);
  const options = [
    { label: "ارزانترین", value: "LowestPrice" },
    { label: "گرانترین", value: "HighestPrice" },
    { label: "متراژ بالا", value: "HighestArea" },
    { label: "متراژ پایین", value: "LowestArea" },
  ];
 

  function onChange(e) {
    if (e.target.value === "LowestPrice") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 0,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestPrice") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 1,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestArea") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 2,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "LowestArea") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 3,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    }
  }
  return (
    <section className={Style.SortItemModalHolder}>
      <Radio.Group
        className={Style.typeCheckbox}
        options={options}
        onChange={onChange}
        defaultValue={
          DakheliBarterFilterData.sortby === 0
            ? "LowestPrice"
            : DakheliBarterFilterData.sortby === 1
            ? "HighestPrice"
            : DakheliBarterFilterData.sortby === 2
            ? "HighestArea"
            : DakheliBarterFilterData.sortby === 3
            ? "LowestArea"
            : null
        }
      />

    </section>
  );
};

export default SortItemBarterModal;
