import {toast,Slide} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const errorToast=(message)=>{
    toast(message ,{
        style:{
        width:"320px",
        // minWidth:"300px",
        height: "80px !important",
        direction:"rtl",
        borderRight:"1.2rem solid red",
        borderTopRightRadius:"0.4rem",
        borderBottomRightRadius:"0.4rem",
        margin:"3rem auto 0",
        fontFamily:'iran-sans',
        fontSize:'0.8rem',
        lineHeight:"1.5rem"
    },
    transition:Slide,
    autoClose:false,
    draggable: true,
    progress: undefined,
    hideProgressBar: true,
    closeOnClick: true,
    position: "top-center",} )
}
export const succToast=(message)=>{
    toast(message ,{
        style:{
        width:"320px",
        minWidth:"300px",
        height: "80px !important",
        direction:"rtl",
        borderRight:"1.2rem solid green",
        borderTopRightRadius:"1rem",
        borderBottomRightRadius:"1rem",
        margin:"3rem auto 0",
        fontFamily:'iran-sans'
    },
    transition:Slide,
    autoClose:true,
    draggable: true,
    progress: undefined,
    hideProgressBar: true,
    closeOnClick: true,
    position: "top-center",} )
}

export const notifToast=(title,message)=>{
    
    toast(title+"\n"+message ,{
        style:{
        width:"320px",
        minWidth:"300px",
        height: "120px !important",
        direction:"rtl",
        borderRight:"1.2rem solid black",
        borderTopRightRadius:"1rem",
        borderBottomRightRadius:"1rem",
        margin:"3rem auto 0",
        fontFamily:'iran-sans',
        lineHeight:"2",
        textAlign:"center",
        whiteSpace:"pre-wrap"
    },
    transition:Slide,
    autoClose:true,
    draggable: true,
    progress: undefined,
    hideProgressBar: true,
    closeOnClick: true,
    position: "top-center",} )
}