import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AddForeignPropertyAPI, getCountriesListAPI, getFeaturesListOptionsAPI, getForeignCitylistAPI, getForeignPropertyTypeAPI } from '../../../Api/BarangizanServices';
import Style from "./ForeignPropertyRegisterForm.module.scss"
import { Alert } from "antd";
import Select from "react-select";
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Button from '../../Button/Button';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';

const ForeignPropertyRegisterForm = () => {
    const [loadingState, setLoadingState] = useState(false);
    const [loadingType, setLoadingType] = useState(true);
    const [RentState, setRentState] = useState(false);
    const [propertyType, setPropertyType] = useState();
    const [propertyTypeValue, setPropertyValue] = useState("");
    const [countryList, setCountryList] = useState("");
    const [citiesList, setCitiestList] = useState([]);
    const [CityName, setCityName] = useState("");
    const [coutrySelect, setCountrySelect] = useState("");
    const [featureListState, setFeatureListState] = useState();
    const [description, setDescription] = useState("");
    const [ImageUrls, setImageUrls] = useState();
    const [ImageFile, setImageFile] = useState();
    const[TahatorState,setTahatorState]=useState(false)
    const [countryListTahator, setCountryListTahator] = useState(null);
    const imageChange = (evt) => {
        setImageFile(evt.target.files);
        let imageUrlList = [];
        Array.from(evt.target.files).forEach((element) =>
          imageUrlList.push(URL.createObjectURL(element))
        );
        setImageUrls(imageUrlList);
      };
    
      useEffect(() => {
        getDataHandler();
      }, []);
      const getDataHandler = async () => {
        const data = await getCountriesListAPI();
        setCountryList(data.data.list);
        const features = await getFeaturesListOptionsAPI(1);
        setFeatureListState(features.data.data);
        const propertyType = await getForeignPropertyTypeAPI();
        setPropertyType(propertyType.data.list);
        setLoadingType(false);
      };
      const getCityListHandler = async (info) => {
        setCountrySelect(info);
        const cities = await getForeignCitylistAPI(info.label);
        setCitiestList(cities.data.list);
      };
      const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
      } = useForm();
      const onSubmit = (data) => {
        
          setLoadingState(true);
          let formData = new FormData();
          formData.append("EstatesTypeId", propertyTypeValue.value);
          formData.append("title", data.title);
          formData.append("description", data.description);
          formData.append("address", data.Address);
          formData.append("IsTahator", TahatorState);
          formData.append("TahatorCountries", countryListTahator);
          formData.append("Country", coutrySelect.label);
          formData.append("CountryId", coutrySelect.value);
          formData.append("city", CityName.label);
          formData.append("Metraj", data.Metraj);
          formData.append("price", data.price);
          formData.append("foreignEstateSellingType", data.foreignEstateSellingType);
          formData.append("isEjareyi", RentState);
          formData.append("pish", data.prepayment);
          formData.append("mahiane", data.monthlyRent);
          formData.append("sharj", data.ChargingFee);
          for (let index = 0; index < ImageFile.length; index++) {
            formData.append(`Images[${index}].IsVideo`, false);
            formData.append(`Images[${index}].Item`, ImageFile[index]);
          }
          for (var i = 0; i < parseInt(featureListState.length); i++) {
            formData.append(`Features[${i}].Key`, data.Features[i].Key);
            let featureString = [];
            data.Features[i].value.forEach((element) => {
              featureString.push(element.label);
            });
            formData.append(`Features[${i}].Value`, featureString.toString());
          }
          AddForeignPropertyAPI(formData, (succ, res) => {
            if (succ) {
              succToast(res.data.message);
              setLoadingState(false);
              reset();
            } else {
              errorToast(res.data.errors[0]);
              setLoadingState(false);
            }
          });
        
      }
      const TahatorHandler=(e)=>{
        setTahatorState(e.target.checked)
      }
      const seletCountryTahatorHandler=(e)=>{
        let CountryListSelected=[]
        e.forEach(element => {
          CountryListSelected.push(element.label)
        });
        setCountryListTahator(CountryListSelected.join("_"))
      }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
    <LoadingPage show={loadingState} />
    <ul className={Style.InputHolder}>
      <li>
        <label htmlFor="type">نوع ملک</label>
        <div className={Style.selectOption}>
          <Select
            {...register("type", { required: false })}
            onChange={setPropertyValue}
            options={
              propertyType &&
              propertyType.map((item) => {
                return { value: item.id, label: item.title };
              })
              
            }
            name="type"
            placeholder="انتخاب کنید"
            className={Style.selectOptionsearch}
            isLoading={loadingType}
          />
        </div>
      </li>
      <li>
        <label htmlFor="title">نام ملک</label>
        <input
          {...register("title", { required: false })}
          className={errors.title ? "valid" : "text"}
          autoComplete="title"
          type="text"
          name="title"
          
        />
      </li>

      <li>
        <label htmlFor="province"> کشور</label>
        <div className={Style.selectOption}>
          <Select
            {...register("province", { required: false })}
            onChange={getCityListHandler}
            options={
              countryList &&
              countryList.map((item) => {
                return { value: item.id, label: item.title };
              })
            }
            name="province"
            placeholder="انتخاب کنید"
            className={Style.selectOptionsearch}
            isLoading={loadingType}
          />
        </div>
      </li>
      <li>
        <label htmlFor="city"> شهر</label>
        <div className={Style.selectOption}>
          <Select
            {...register("city", { required: false })}
            options={
              citiesList &&
              citiesList.map((item) => {
                return { value: item.id, label: item.title };
              })
            }
            name="city"
            onChange={setCityName}
            placeholder="انتخاب کنید"
            className={Style.selectOptionsearch}
          />
        </div>
      </li>

      <li>
        <label htmlFor="Metraj">متر مربع </label>
        <input
          {...register("Metraj", { required: true })}
          className={errors.Metraj ? "valid" : "text"}
          autoComplete="Metraj"
          type="text"
          name="Metraj"
          
        />
      </li>
      <li>
        <label htmlFor="price">قیمت </label>
        <input
          {...register("price", { required: true })}
          className={errors.price ? "valid" : "text"}
          autoComplete="price"
          type="number"
          name="price"
          
        />
      </li>

      <li>
        <label htmlFor="foreignEstateSellingType"> نوع فروش </label>
        <div className={Style.selectOption}>
          <select
            name="foreignEstateSellingType"
            id=""
            {...register("foreignEstateSellingType", { required: false })}
          >
            <option value={0}>نقد</option>
            <option value={1}>اقساط</option>
            <option value={2}>نقد،اقساط</option>
          </select>
        </div>
      </li>
      <li className={Style.CheckBoxHolder}>
        <label htmlFor="Rent">  اجاره داده می شود </label>
        <input
          style={{ accentColor: "blue" }}
          type={"checkbox"}
          id={"Rent"}
          onChange={(e) => setRentState(e.target.checked)}
        />
      </li>

      {RentState ? (
        <li>
          <label htmlFor="prepayment">پیش پرداخت  </label>
          <input
            {...register("prepayment", { required: true })}
            className={errors.prepayment ? "valid" : "text"}
            autoComplete="prepayment"
            type="number"
            name="prepayment"
            
          />
        </li>
      ) : null}
      {RentState ? (
        <li>
          <label htmlFor="monthlyRent">اجاره </label>
          <input
            {...register("monthlyRent", { required: true })}
            className={errors.monthlyRent ? "valid" : "text"}
            autoComplete="monthlyRent"
            type="number"
            name="monthlyRent"
            
          />
        </li>
      ) : null}

      {RentState ? (
        <li>
          <label htmlFor="ChargingFee">شارژ </label>
          <input
            {...register("ChargingFee", { required: true })}
            className={errors.ChargingFee ? "valid" : "text"}
            autoComplete="ChargingFee"
            type="number"
            name="ChargingFee"
            
          />
        </li>
      ) : null}
              <li>
            <label htmlFor='isTahator' className={Style.TahatorHolder}>
            <input type="checkbox" name="" id="isTahator" onChange={TahatorHandler}/> تهاتر 
            </label>
            
        </li>
        {
          TahatorState?<li>
          <label htmlFor="type">  کشور برای تهاتر</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={seletCountryTahatorHandler}
              options={
                countryList?.map(item=>{
                  return({ value: item.id, label: item.title })
                })
              }
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isMulti
            />
          </div>
        </li>:null
        }
    </ul>
    <div className={Style.DescHolder}>
      <label htmlFor="Address">آدرس</label>
      <textarea
        {...register("Address", { required: true })}
        className={errors.Address ? "valid" : "text"}
        autoComplete="Address"
        type="text"
        name="Address"
        
      />
    </div>
    <ul className={Style.InputHolder}>
      {featureListState &&
        featureListState.map((item, i) => {
          return (
            <li key={item.id}>
              <label htmlFor="featureCount">{`  ویژگی ${i + 1}`}</label>
              <input
                {...register(`Features[${i}].Key`)}
                className={errors.username ? "valid" : "text"}
                autoComplete={`Features[${i}].Key`}
                type="text"
                name={`Features[${i}].Key`}
                style={{ marginBottom: "0.5rem" }}
                defaultValue={item.text}
                readOnly
              />
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <div className={Style.selectOption}>
                    <Select
                      {...register(`Features[${i}].value`)}
                      options={item.options.map((item) => {
                        return {
                          label: item.text,
                          value: item.text,
                        };
                      })}
                      name={`Features[${i}].value`}
                      // onChange={setFeatureTest}
                      onChange={onChange}
                      placeholder=" select"
                      className={Style.selectOptionsearch}
                      isMulti
                      defaultValue={[{ label: "ندارد", value: 0 }]}
                    />
                  </div>
                )}
                name={`Features[${i}].value`}
                control={control}
                rules={{ required: false }}
                defaultValue={[{ label: "ندارد", value: 0 }]}
              />
            </li>
          );
        })}
    </ul>
    <div className={Style.DescHolder}>
      <label htmlFor="description"> جزئیات </label>
      <textarea className={errors.description ? "valid" : "text"} name={'description'} {...register("description", { required: true })}
        defaultValue={""}
        
      />
    </div>
    <div className={Style.ImagePart}>
      <label htmlFor="imageFile" className={Style.ImageLabel}>
         انتخاب تصویر
        <input
          type="file"
          name="imageFile"
          id="imageFile"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => imageChange(e)}
          multiple
        />
      </label>
    </div>
    <ul className={Style.InputHolder}>
      {ImageUrls &&
        ImageUrls.map((item, index) => {
          return (
            <li key={index} className={Style.ImagePerview}>
              <img
                src={item}
                className={Style.ImagePerview}
                alt=""
                onClick={() => window.open(item, "_blank")}
              />
            </li>
          );
        })}
    </ul>

    <Alert
        type="warning"
        message=" تصاویر فقط با فرمت JPG,JPEG,PNG ارسال شود!"
        showIcon
        className="w-100 mt-2"
      />
    <Alert
        type="warning"
        message=" قیمت ملک به واحد پولی کشوری که ملک درآن قرار دارد وارد شود."
        showIcon
        className="w-100 my-2"
      />
  <Button  value='ثبت' classbtn='submitBtn' style={{width:'100%'}}/>
  </form>
  )
}

export default ForeignPropertyRegisterForm