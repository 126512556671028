import React from 'react'
import Style from './Input.module.css'
const Input = (props,{ref}) => {
    let inputElementType=null
    switch(props.inputtype){
        case 'text':
            inputElementType=<input className={Style.InputStyle} {...props} ref={ref} />
            break;
        case 'textArea' :   
            inputElementType=<textarea className={Style.textareaStyle} {...props} ref={ref} />
            break;
        case 'valid' :   
            inputElementType=<input className={`${Style.isInvalid} ${Style.InputStyle}`} {...props} ref={ref}/>
            break; 
        case 'validTextArea' :   
            inputElementType=<input className={`${Style.isInvalid} ${Style.textareaStyle}`} {...props} ref={ref}/>
            break;  
        default:
            inputElementType=<input className={Style.InputStyle} {...props}/>
            break;
    }
    return (
        <div className={Style.InputHolder}>
            {inputElementType}
        </div>
    )
}
const forwardInput=React.forwardRef(Input)
export default forwardInput
