import React, { useEffect, useState } from 'react'
import { getPropertiDakheliAPI } from '../../../Api/BarangizanServices'
import Divider from '../../../Components/Divider/Divider'
import Footer from '../../../Components/Footer/Footer'
import AmlakDakheliSearchContainer from '../../../Components/SearchContainer/AmlakDakheliSearchContainer/AmlakDakheliSearchContainer'
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import MagazineSlider from '../../../Components/Sliders/HomeSlider/MagazineSlider/MagazineSlider'
import PropertySlider from '../../../Components/Sliders/HomeSlider/PropertySlider/PropertySlider'

const AmlakDakheli = () => {
    const[propertyList,setPropertyList]=useState()

    useEffect(()=>{
        getDataList()
    },[])
    const getDataList=async()=>{
        const data=await getPropertiDakheliAPI()
        setPropertyList(data.data.list)
    }
    
    return (
        <div className='pageHolder'>
            <AmlakDakheliHeaders/>
            <AmlakDakheliSearchContainer/>
            <Divider name={'فروش فوری'}/>
            <PropertySlider data={propertyList}/>
            <Divider name={' مجله املاک برانگیزان'}/>
            <MagazineSlider/>
            <Footer/>
        </div>
    )
}

export default AmlakDakheli
