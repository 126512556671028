import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faListOl,
  faMapPin,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "antd";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PayTahatorComissionAPI } from "../../Api/BarangizanServices";
import { separate } from "../../Utils/SeprateNumber/SeprateThreeNumber";
import { errorToast, succToast } from "../../Utils/Toast/Toast";
import Style from "./TahatorRequestPerview.module.scss";
const TahatorRequestPerview = ({ data, count }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const PayComssionHandler = () => {
    setLoading(true);
    // let info = {
    //   tahatorId: data.id,
    // };
    let formData = new FormData();
    formData.append("tahatorId", data.id);
    PayTahatorComissionAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        window.location.replace(res.data.data.payment.url);
        setLoading(false);
      } else {
        errorToast(res.data.errors[0]);
        setLoading(false);
      }
    });
  };
  return (
    <section className={Style.sectionHolder}>
            <Modal show={show} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>قولنامه</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column justify-content-around w-100">
          <div
            className={"w-100 p-2 bg-light border border-warning"}
            dangerouslySetInnerHTML={{ __html: data.affidavit }}
          ></div>
                <Alert
        type="warning"
        message="درصورت هرگونه مشکل و یا اعترض به پشتیبانی تیکت زده یا تماس بگیرید."
        showIcon
        className="w-100 my-2"
      />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
      <h3 className={Style.RequestTitle}>درخواست {count + 1}</h3>
      <section className={Style.RequestHolder}>
        <div className={Style.PropertyHolder}>
          <h3>ملک شما</h3>
          <img
            onClick={() =>
              navigate(
                `../${
                  data.isProposedEstateIdForeign ? "khareji" : "dakheli"
                }/property/${data.proposedEstate.code}`
              )
            }
            src={`https://api.barangizan.ir${data.proposedEstate.image}`}
            alt="property"
          />
          <ul className={Style.ListHolder}>
            <li>
              {" "}
              <FontAwesomeIcon icon={faBuilding} color={"goldenrod"} />{" "}
              {data.proposedEstate.title}
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faListOl} color={"goldenrod"} /> 
              {data.proposedEstate.code}
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faMapPin} color={"goldenrod"} /> 
              {data.proposedEstate.address}
            </li>
          </ul>
        </div>
        <div className={Style.PropertyHolder}>
          <h3>ملک انتخاب شده</h3>
          <img
            onClick={() =>
              navigate(
                `../${
                  data.isWantedEstateIdForeign ? "khareji" : "dakheli"
                }/property/${data.wantedEstate.code}`
              )
            }
            src={`https://api.barangizan.ir${data.wantedEstate.image}`}
            alt="property"
          />
          <ul className={Style.ListHolder}>
            <li>
              <FontAwesomeIcon icon={faBuilding} color={"goldenrod"} /> 
              {data.wantedEstate.title}
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faListOl} color={"goldenrod"} /> 
              {data.wantedEstate.code}
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faMapPin} color={"goldenrod"} /> 
              {data.wantedEstate.address}
            </li>
          </ul>
        </div>
      </section>
      {data.description === "" ? null : (
        <p className={Style.DescHolder}>
          <span>توضیحات درخواست: </span>
          {data.description}
        </p>
      )}
      {data.status === 0 ? (
        <p className="w-100">
          <FontAwesomeIcon color="orange" icon={faExclamationCircle} />
           در انتظار بررسی درخواست شما توسط صاحب ملک{" "}
        </p>
      ) : data.status === 1 ? (
        <p className="w-100">
          <FontAwesomeIcon color="orange" icon={faExclamationCircle} />
           در انتظار بررسی ادمین{" "}
        </p>
      ) : data.status === 2 ? (
        <>
        <p className="w-100">مبلغ:{separate(data.proposedEstateComission)} تومان</p>
        <Button
          className={`${Style.BtnHolder} bg-success w-100`}
          onClick={() => PayComssionHandler(true)}
          disabled={loading}
        >
          پرداخت کمسیون <FontAwesomeIcon icon={faMoneyBill} />
        </Button>
        </>
      ) : data.status === 3 && data.affidavit === null ? (
        <p className="w-100">
          <FontAwesomeIcon color="orange" icon={faExclamationCircle} />
          درحال تنظیم قولنامه
        </p>
      ) : data.status === 3 && data.affidavit !== null ? (
        <Button
          onClick={() => setShow(true)}
          className={`w-100 bg-success`}
        >
          نمایش قولنامه
        </Button>
      ) : null}
    </section>
  );
};

export default TahatorRequestPerview;
