import React from 'react'
import Style from './AmlakDakheliSearchContainer.module.scss'
import { Input, Select } from 'antd';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import { useImageState } from '../../../Utils/Context/ImageContext';
import { useEffect } from 'react';
import { getFilterDataAPI, getPropertyTypeAPI } from '../../../Api/BarangizanServices';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useContext } from 'react';
import { FilterInfo } from '../../../Utils/Context/SearchEngineContext';
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber';
const { Option } = Select;




const AmlakDakheliSearchContainer = () => {

    
    const{setFilterData}=useContext(FilterInfo)

    const[dataFilter,setDataFilter]=useState()
    const[propertyTypeState,setPropertyTypeState]=useState()
    

    let CitiesChildren=[]
    useEffect(()=>{
        getFilterDataHanlder()
    },[])
    const getFilterDataHanlder=async()=>{
        const data=await getFilterDataAPI()
        setDataFilter(data)
        const propertyType=await getPropertyTypeAPI()
        setPropertyTypeState(propertyType.data.list)
    }

    const history=useNavigate()


    let provincesChildren=[]
    dataFilter&&dataFilter.provinces.forEach(element => {
        provincesChildren.push(
            <Option style={{textAlign:"right"}} key={element}>{element}</Option>
        )
    });

    const rangeNumber=dataFilter&&Math.ceil((dataFilter.maxPrice-dataFilter.minPrice)/5)
     let rangeArray=[]
     let minArray=dataFilter&&dataFilter.minPrice
    for (let index = 1; index <= 5; index++) {
        rangeArray.push(`${separate(minArray)} - ${separate(index===5?dataFilter&&dataFilter.maxPrice:rangeNumber*index)}`)
        minArray=rangeNumber*index+1
    }
    
    const {Image}=useImageState()


    const[citis,setCitis]=useState([])
    function handelProivince(value) {
        setProvinceSelect(value)
        CitiesChildren=[]
        for (let index = 0; index < value.length; index++) {
            const found = dataFilter.cities.find(element => element.province === value[index]);
            CitiesChildren.push(
                <Option style={{textAlign:"right"}} key={found.cityName}>{found.cityName}</Option>
            )
        }
        setCitis(CitiesChildren)
        
    }
    const[estateList,setEstateList]=useState()
    const[propertyCode,setPropertyCode]=useState()
    
    const[minMetraj,setMinMetraj]=useState('')
    const[maxMetraj,setMaxMetraj]=useState('')
    const[citySelect,setCitySelect]=useState()
    const[provinceSelect,setProvinceSelect]=useState()
    const[priceRange,setPriceRange]=useState()
    const [propertyTypeList2, setPropertyTypeList2] = useState();
    const [propertyTypeSelect, SetPropertyTypeSelect] = useState();

    const getListData=()=>{
        let items={
            estateType:propertyTypeSelect&&propertyTypeSelect.toString(),
            code:propertyCode,
            province:provinceSelect&&provinceSelect.toString(),
            city:citySelect&&citySelect.toString(),
            minArea:minMetraj,
            maxArea:maxMetraj,
            minPrice:priceRange&&priceRange.split('-')[0].replace(',',''),
            maxPrice:priceRange&&priceRange.split('-')[1].replace(',',''),
            LowestPrice:null,
            HighestPrice:null,
            HighestArea:null,
            LowestArea:null
        }
        history('/dakheli/results', { state: items });
        setFilterData(items)
    }
    
    let propertyTypeArrayList = [];
    propertyTypeArrayList.push(
      <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
        {"همه"}
      </Option>
    );
    propertyTypeState &&
    propertyTypeState.forEach((element) => {
        propertyTypeArrayList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.id}
        >
          {element.title}
        </Option>
      );
    });
    const propertyTypeChangeHandler = (info) => {
        if (info.includes(1)) {
          let propertyTypeArrayList = [];
          propertyTypeArrayList.push(
            <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
              {"همه"}
            </Option>
          );
          setPropertyTypeList2(propertyTypeArrayList);
        } else if (info.length === 0) {
          let propertyTypeArrayList = [];
          propertyTypeArrayList.push(
            <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
              {"همه"}
            </Option>
          );
          propertyTypeState &&
          propertyTypeState.forEach((element) => {
              propertyTypeArrayList.push(
                <Option
                  style={{ textAlign: "right" }}
                  key={element.title}
                  value={element.title}
                >
                  {element.title}
                </Option>
              );
            });
          setPropertyTypeList2(propertyTypeArrayList);
        }
        SetPropertyTypeSelect(info);
      };


    return (
        <div className={`flexStyle ${Style.ContainerHolder}` }  >
            <img className={Style.ContainerBG} src={`https://api.barangizan.ir${Image.length===0?null:Image.find(element => element.key ==='search' ).value}`} alt="خرید ملک" />
            <section className={Style.SearchFiledHolder}>
               <h3 ><span>بهترین</span> و <span>مناسب ترین</span> ملک را برای خود پیدا کنید</h3>
               <h5 >ما بیش از <span>200</span> واحد ملک برای شما داریم</h5>
                {
                    dataFilter===undefined || propertyTypeState===undefined?<div className='flexStyle'><Spinner animation="border" variant='warning'/></div>:<ul className={Style.InputHolders}>
                    <li className={Style.EachInput}>
                        <Select
                        mode="multiple"
                        allowClear
                        className={Style.SelectOptionHolder}
                        placeholder=" نوع ملک"
                        onChange={propertyTypeChangeHandler}
                        >
                    {propertyTypeList2 === undefined
                        ? propertyTypeArrayList
                        : propertyTypeList2}
                        </Select>
                    </li>
                    <li className={Style.EachInput}>
                        <Select
                        mode="multiple"
                        allowClear
                        className={Style.SelectOptionHolder}
                        placeholder=" استان "
                        onChange={handelProivince}
                        >
                        {provincesChildren}
                        </Select>
                    </li>

                    <li className={Style.EachInput}>
                        <Select
                        mode="multiple"
                        allowClear
                        className={Style.SelectOptionHolder}
                        placeholder=" شهر "
                        onChange={setCitySelect}
                        >
                         {citis} 

                        </Select>
                    </li>
                    <li className={Style.EachInput}>
                        <Input placeholder='کدملک...' className={Style.TextInput} onChange={e=>setPropertyCode(e.target.value)}/>
                    </li>
                    <li className={Style.EachInput}>
                        <Input placeholder='از...مترمربع' className={Style.TextInput} onChange={e=>setMinMetraj(e.target.value)}/>
                    </li>
                    <li className={Style.EachInput}>
                        <Input placeholder='تا...مترمربع' className={Style.TextInput} onChange={e=>setMaxMetraj(e.target.value)}/>
                    </li>
                    <li className={Style.EachInput}>
                        <select  defaultValue={0} className={Style.SelectOptionHolder} onChange={e=>setPriceRange(e.target.value)}>
                            <option value={0} disabled>
                               بازه قیمت به تومان
                            </option>
                           {
                               rangeArray.map((item,index)=>{
                                   return(
                                       <option style={{direction:"ltr",fontFamily:'iran-suns-num'}} key={index} value={item}>{item}</option>
                                   )
                               })
                           }
                        </select>
                    </li>
                    <li className={Style.EachInput}>
                        <Button value={'جست جو'} classbtn={'confrimBtn'} style={{width:'100%'}} onClick={getListData}/>
                    </li>
                </ul>
                }
            </section>
        </div>
    )
}

export default AmlakDakheliSearchContainer
