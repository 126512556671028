import React, { useContext } from 'react'
import { profile } from '../../../Utils/Context/UserInfoContext'
import ForeignUserProfileForm from '../../Forms/ForeignUserProfileForm/ForeignUserProfileForm'

const ForeignUserPanelProfile = () => {
  const {userInfo}=useContext(profile)
  return (
    <div className='flexStyle'>
        {userInfo&&<ForeignUserProfileForm info={userInfo}/>}
    </div>
  )
}

export default ForeignUserPanelProfile