import React, { useState, useContext } from "react";

export const FilterInfo = React.createContext(null);

export const UserFilterInfo = () => {
  const pc = useContext(FilterInfo);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const FilterInfoProvider= ({ children }) => {
  const [FilterData, setFilterData] = useState(JSON.parse(localStorage.getItem('Filters')));
  const closeIt=()=>{
    localStorage.setItem('Filters',JSON.stringify(FilterData))
  } 
  window.onbeforeunload = closeIt;

  return (
    <FilterInfo.Provider
      value={{
        FilterData,
        setFilterData: (value) => {
            setFilterData(value);
        },
      }}
    >
      {children}
    </FilterInfo.Provider>
  );
};

export { FilterInfoProvider };
