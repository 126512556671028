import { faDoorOpen, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '../../Input/Input';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../Button/Button'
import LoadingPage from '../../Loading/LoadingPage/LoadingPage'
import Style from './ContactUsForm.module.scss'
import { ContactUsAPI } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import {Spinner} from 'react-bootstrap'
const ContactUsForm = () => {
    const[loadingState,setLoadingState]=useState(false)
    const {register,handleSubmit,reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
        let formData=new FormData()
        formData.append('Name',data.Name)
        formData.append('Subject',data.Subject)
        formData.append('Text',data.explain)
        ContactUsAPI(formData,(succ,res)=>{
          if(succ){
            setLoadingState(false)
            reset()
            succToast(res.data.message)
          }
          else{
            setLoadingState(false)
            errorToast(res.data.errors[0])
          }
        })
    }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
        <LoadingPage show={loadingState}/>
        <h4>
             ارتباط با ما 
            <FontAwesomeIcon icon={faPhoneAlt}/>
        </h4>

        <label className={Style.inputName}>نام شما</label>
        <Input type='text' {...register('Name',{ required: true })}  inputtype={errors.Name ? "valid" : "text"} />

        <label className={Style.inputName}>موضوع</label>
        <Input type='text' {...register('Subject',{ required: true })}  inputtype={errors.Subject ? "valid" : "text"} />

        <label className={Style.inputName}>متن پیام</label>
        <Input
          type="texarea"
          {...register("explain", { required: true })}
          inputtype={errors.explain ? "validTextArea" : "textArea"}
        />
        
        <div className={Style.btnHolder}>
            <Button value={loadingState?<Spinner animation='border' variant='light'/>:'ارسال'} classbtn={'confrimBtn'} disabled={loadingState}/>
            {/* <Button value='آپلودفایل' classbtn={'primary'}/> */}
        </div>

    </form>
  )
}

export default ContactUsForm