
import React, { useEffect } from 'react';
import Footer from '../../../Components/Footer/Footer';
import ShomalTourRegisterForm from '../../../Components/Forms/ShomalTourRegisterForm/ShomalTourRegisterForm';
import TourHeader from '../../../Components/SiteHeaders/TourHeader/TourHeader';
import ScrollToTap from '../../../Utils/ScrollToTap/ScrollToTap';

const ShomalTourRegister = () => {
  return (
    <div className='pageHolder'>
      <ScrollToTap>
          <TourHeader/>
          <ShomalTourRegisterForm/>
          <Footer/>
      </ScrollToTap>
    </div>
  )
};

export default ShomalTourRegister;
