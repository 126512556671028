import React from 'react';
import Footer from '../../../Components/Footer/Footer';
import RegisterForm from '../../../Components/Forms/RegisterForm/RegisterForm';
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader';
import Style from './Register.module.scss'

const Register = () => {
  return (
    <div className={'pageHolder'}>
        {
            window.location.pathname.split('/')[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>
        }
        <section className={Style.RegisterSection}>
            <img src="/Assets/Images/Auth/Register.svg" alt="Register" />
            <div className={Style.formHolder}><RegisterForm/></div>
        </section>
        <Footer/>

    </div>
  );
};

export default Register;
