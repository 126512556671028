
import { LoadingOutlined } from '@ant-design/icons'
import React, { useEffect, useState }  from 'react'
import { Accordion, Alert, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { getTiketChatListAPI } from '../../Api/BarangizanServices'
import EnglishAnswerTiketForm from '../Forms/EnglishAnswerTiketForm/EnglishAnswerTiketForm'
import EnglishMessageList from '../Lists/EnglishMessageList/EnglishMessageList'

import Style from './EnglishTiketContainerMessage.module.scss'
import EnglishTiketSideInfo from './EnglishTiketSideInfo/EnglishTiketSideInfo'

const EnglishTiketContainerMessage = () => {
  const[chatListState,setChatListState]=useState()
  const {id}=useParams()
  useEffect(()=>{
    getChatListHandler()
  },[])
  const getChatListHandler=async()=>{
    const data=await getTiketChatListAPI(id)
    setChatListState(data.data)
  }
  return (
    <section className={Style.ContainerHolder}>
        <div className={Style.SideInfo}><EnglishTiketSideInfo info={chatListState&&chatListState.ticket}/></div>
        {
          chatListState===undefined?<h5 className='w-100 text-center text-warning mt-3'><LoadingOutlined /> Please Wait... </h5>:<div className={Style.Messages}>
          {
            chatListState.ticket.ticketStatus===3?          <Alert  variant={'warning'}>
            This Tiket is Closed or just a Notification
            </Alert>:          <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                  <Accordion.Header className={Style.AccordionHeader}>Message</Accordion.Header>
                  <Accordion.Body>
                      <EnglishAnswerTiketForm info={chatListState.ticket} getUpdate={getChatListHandler}/>
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>
          }


            <EnglishMessageList listInfo={chatListState.messages}/>
          </div>
        }
    </section>
  )
}

export default EnglishTiketContainerMessage