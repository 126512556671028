import React, { useEffect, useState } from 'react'
import { getFavoritePropertyListAPI } from '../../../Api/BarangizanServices'
import UserPanelDetermineVisitBtnList from '../../Lists/UserPanelDetermineVisitBtnList/UserPanelDetermineVisitBtnList'
import UserPanelPropertiesCheckBox from '../UserPanelPropertiesCheckBox/UserPanelPropertiesCheckBox'
import UserPanelVisitCondition from './UserPanelVisitCondition/UserPanelVisitCondition'

const UserPanelSelectedProperty = () => {
    const[PropertyList,setPropertyList]=useState()
    useEffect(()=>{
        getFavoritePropertyListHandler()
    },[])
    const getFavoritePropertyListHandler=async()=>{
        const data=await getFavoritePropertyListAPI()
        setPropertyList(data.data.list)
    }

    const[checked,setChecked]=useState(false)
    const[visitType,setVisitType]=useState()
  return (
    <div className='flexStyle b-light'>
        <UserPanelPropertiesCheckBox data={PropertyList} setBtn={(e)=>setChecked(e)}/>
        {/* {
            checked?<UserPanelDetermineVisitBtnList setRules={(e)=>setVisitType(e)} type={visitType}/>:null
        }
        {
             checked===true&&visitType!==undefined?<UserPanelVisitCondition type={visitType}/>:null
        } */}
    </div>
  )
}

export default UserPanelSelectedProperty