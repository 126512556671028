import React from 'react'
import { separate } from '../../../../Utils/SeprateNumber/SeprateThreeNumber'
import SharjWalletForm from '../../../Forms/SharjWalletForm/SharjWalletForm'
import Style from './TopWalletPrice.module.scss'
const TopWalletPrice = ({data}) => {
  return (
    <div className={Style.ComponentHolder}>
        <p>موجودی کیف پول شما : {data===undefined?'منتظر باشید':<span>{separate(data.balance)} تومان</span>}</p>
        <SharjWalletForm/>
    </div>
  )
}

export default TopWalletPrice