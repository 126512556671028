import React, { useState, useContext } from "react";

export const ForeignFilterInfo = React.createContext(null);

export const UserFilterInfo = () => {
  const pc = useContext(ForeignFilterInfo);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const ForeignFilterInfoProvider= ({ children }) => {
  const [ForeignFilterData, setForeignFilterData] = useState(JSON.parse(localStorage.getItem('Filters')));
  const closeIt=()=>{
    localStorage.setItem('Filters',JSON.stringify(ForeignFilterData))
  } 
  window.onbeforeunload = closeIt;

  return (
    <ForeignFilterInfo.Provider
      value={{
        ForeignFilterData,
        setForeignFilterData: (value) => {
            setForeignFilterData(value);
        },
      }}
    >
      {children}
    </ForeignFilterInfo.Provider>
  );
};

export { ForeignFilterInfoProvider };
