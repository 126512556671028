import {  Radio, Switch } from 'antd';
import React, { useContext } from 'react';
import { ForeignFilterInfo } from '../../../Utils/Context/ForeignSearchEngineContext';
import Style from './ForeignSortItemModal.module.scss'

const ForeignSortItemModal = ({searchUpdate}) => {
    const options = [
        { label: "ارزانترین", value: "LowestPrice" },
        { label: "گرانترین", value: "HighestPrice" },
        { label: "متراژ بالا", value: "HighestArea" },
        { label: "متراژ پایین", value: "LowestArea" },
      ];
    const { ForeignFilterData, setForeignFilterData } =useContext(ForeignFilterInfo);
    const onChangeHandler = (e) => {
      if(e){
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:true,
          isEjreyi:ForeignFilterData.isEjreyi
        };
        setForeignFilterData(items);
        searchUpdate();
      }
      else{
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:false,
          isEjreyi:ForeignFilterData.isEjreyi
        };
        setForeignFilterData(items);
        searchUpdate();
      }
    };
    const onChangeEjareHandler=(e)=>{
      if(e){
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:ForeignFilterData.isTavizi,
          isEjreyi:true,
          
        };
        setForeignFilterData(items);
        searchUpdate();
      }
      else{
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:ForeignFilterData.isTavizi,
          isEjreyi:false,
        };
        setForeignFilterData(items);
        searchUpdate();
      }
    }
    function onChange(e) {
        if (e.target.value === "LowestPrice") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 0,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestPrice") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 1,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestArea") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 2,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "LowestArea") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 3,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        }
        }
  return (
    <section className={Style.SortItemModalHolder}>
    <Radio.Group className={Style.typeCheckbox} options={options}  onChange={onChange} defaultValue={
       ForeignFilterData.sortby === 0
       ? "LowestPrice"
       : ForeignFilterData.sortby === 1
       ? "HighestPrice" 
       : ForeignFilterData.sortby === 2
       ? "HighestArea" 
       : ForeignFilterData.sortby === 3
       ? "LowestArea" 
       : null
    }/>
      <div className={Style.ExchangeHolder}>
        <label htmlFor="exchange"> تهاتر</label>{" "}
        <Switch id="exchange" onChange={onChangeHandler} />
      </div>
      <div className={Style.ExchangeHolder}>
        <label htmlFor="Rent"> اجاره</label>{" "}
        <Switch id="Rent" onChange={onChangeEjareHandler} />
      </div>
</section>
  )
}

export default ForeignSortItemModal