
import React from 'react';
import Divider from '../../Divider/Divider';
import Style from './CooperationTopPart.module.scss'
const CooperationTopPart = () => {
  return (
    <section className={Style.ComponentHolder}>
      <Divider name={'همکاری با ما'}/>
      <div className={Style.BodyHolder}>
        <img src="/Assets/Images/Logo/logo.png" alt="" />
        <p>شركت مشاوره اي برانگيزان آماده ي همكاري با كليه ي مشاوران املاك ، شركت هاي خدمات گردشگري و رستوران ها و مجموعه هاي تفريحي و گردشگري شمال  مي باشد. براي انجام همكاري در زمينه ي برگزاری تور فيلد هاي خواسته شده را تكميل و مدارك را بارگذاري و ارسال فرموده تا ضمن جلسه ي آنلاين معارفه، تفاهمنامه ي همكاري مربوط به شما ارسال شود.</p>
      </div>
    </section>
  )
};

export default CooperationTopPart;
