import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getContactInfoAPI } from "../../../Api/BarangizanServices";
import { contact } from "../../../Utils/Context/ContactInfoContext";
import Style from "./TopPartHeader.module.scss";
const TopPartHeader = () => {
  const history = useNavigate();
  const isLogin=() =>!!localStorage.getItem("BarangizanToken");
  const{contactDetail,setContactDetail}=useContext(contact)
  const phoneNumber = contactDetail&&contactDetail.list.find(element => element.key === "Phone");
  const Email = contactDetail&&contactDetail.list.find(element => element.key === "Email");

  useEffect(()=>{
      getContactInfo()
  },[])


  const OpenPanelHandler=async()=>{
    if(localStorage.getItem('userType')==="1" && localStorage.getItem('isForeign')==='true'){
      history(`../khareji/englishpanel/profile`)
    }
    else if(localStorage.getItem('userType')==="0" && localStorage.getItem('isForeign')==='true'){
      history(`../khareji/panel`)
    }
    else{
      history(`../dakheli/panel`)
    }
  }

  const getContactInfo=async()=>{
      const data=await getContactInfoAPI()
      setContactDetail(data.data)
  }
  return (
    <div className={Style.HeaderTopPart}>
      {isLogin() ? (
        <div
          className={`${Style.LoginRegisterBtn} shadow-sm`}
          onClick={() =>OpenPanelHandler()}
        >
          <FontAwesomeIcon icon={faUser} />
           پنل کاربری{" "}
        </div>
      ) : (
        <div
          className={`${Style.LoginRegisterBtn} shadow-sm`}
          onClick={() => history(`../${window.location.pathname.split('/')[1]}/register`)}
        >
          <FontAwesomeIcon icon={faUser} /> ثبت نام/ورود
        </div>
      )}

      <div className={Style.ContactWays}>
        <a href={`tel:${contactDetail !==undefined?phoneNumber.value: "01134523621"}`}>
          <FontAwesomeIcon icon={faPhone} />
          {contactDetail !==undefined?phoneNumber.value: "01134523621"}
        </a>
        <a href={`mailto:${contactDetail !==undefined?Email.value: "amlak@barangizan.ir"}`} className={Style.mail}>
          <FontAwesomeIcon icon={faEnvelope} />
          {contactDetail !==undefined?Email.value: "amlak@barangizan.ir"}
          
        </a>
      </div>
    </div>
  );
};

export default TopPartHeader;
