import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../Components/Footer/Footer';
import LoginForm from '../../../Components/Forms/LoginForm/LoginForm';
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader';
import Style from './Login.module.scss'
const Login = () => {
  const {type}=useParams()
  console.log(type)
  return(
    <div className={'pageHolder'}>
        {type==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>}
        <section className={Style.LoginSection}>
            <img src="/Assets/Images/Auth/Login.svg" alt="Register" />
            <div className={Style.formHolder}><LoginForm/></div>
        </section>
        <Footer/>

    </div>
  )
};

export default Login;
