import { Alert,Input } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { CreateTahatorRequestAPI, getUserTahtorEstateAPI } from "../../../Api/BarangizanServices";
import PropertySelectPerview from "../../PropertySelectPerview/PropertySelectPerview";
import { Radio } from "antd";
import Style from "./TahatorRequestForm.module.scss";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
const { TextArea } = Input;
const TahatorRequestForm = ({ data,closeModal }) => {
  const [EstateState, setEstateState] = useState();
  const [desc, setDesc] = useState("");
  const [PropertySelectInfo, setPropertySelectInfo] = useState();
  useEffect(() => {
    getEstatesListHandler();
  }, []);
  const getEstatesListHandler = async () => {
    const data = await getUserTahtorEstateAPI();
    setEstateState(data.data.list);
  };
  const SelectPropertyHandler=(e)=>{
    setPropertySelectInfo(e.target.value)
  }
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
        if(PropertySelectInfo===undefined){
            errorToast('ابتدا یک ملک را انتخاب کنید!')
        }else{
      let formData=new FormData()
      formData.append('Description',desc)
      formData.append('ProposedEstateCode',PropertySelectInfo.code)
      formData.append('IsProposedEstateIdForeign',PropertySelectInfo.isForeign)
      formData.append('WantedEstateCode',window.location.pathname.split('/')[3])
      formData.append('IsWantedEstateIdForeign',window.location.pathname.split('/')[1]==='khareji'?true:false)
      CreateTahatorRequestAPI(formData,(succ,res)=>{
          if(succ){
              succToast(res.data.message)
              closeModal()
          }
          else{
              errorToast(res.data.errors[0])
          }
      })
        }

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alert
        message={` این ملک با ملک های کشور های ${data} قابل تهاتر است! `}
        type="warning"
        showIcon
      />
      <Radio.Group onChange={SelectPropertyHandler} className={Style.RadioGroup}>
        {EstateState===undefined?<Spinner animation="border" variant="warning" className="mt-3"/>:EstateState.map((item, index) => {
           return <Radio value={item}><PropertySelectPerview key={index} data={item}/></Radio>
         
        })}
      </Radio.Group>
      <TextArea value={desc} onChange={(e)=>setDesc(e.target.value)} rows={4} placeholder="توضیحات"  className="RTL my-3"/>
      <Button onClick={onSubmit} className="w-100 text-white shadow-sm" variant="warning">ثبت</Button>
    </form>
  );
};

export default TahatorRequestForm;
