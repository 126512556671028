import React from 'react'
import { useState } from 'react';
import Style from './ForeignUserProfileForm.module.scss'
import { useForm } from "react-hook-form";
import { UserInfoUpdateAPI } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Button from '../../Button/Button';

const ForeignUserProfileForm = ({info}) => {
  console.log(info)
    const[loadingState,setLoadingState]=useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
      const onSubmit = (data) => {
        setLoadingState(true)
        UserInfoUpdateAPI(data,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                setLoadingState(false)
                setTimeout(()=>window.location.reload(),1000)
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
      };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
        <ul className={Style.itemsHolder}>
        <li>
          <label className={Style.inputName}>
            SurName<span className={Style.redColor}>*</span>
          </label>
          <input
            name="firstName"
            {...register("firstName", { required: true})}
            placeholder={"for Example:jimmy"}
            className={errors.firstName ? Style.valid : 'textEn'}
            defaultValue={info.firstName}
          />
        </li>
        <li>
          <label className={Style.inputName}>
          CompanyName<span className={Style.redColor}>*</span>
          </label>
          <input
            {...register("lastName", { required: true })}
            placeholder={"for Example:foroughi"}
            className={errors.lastName ? Style.valid : 'textEn'}
            defaultValue={info.lastName}
          />
        </li>
        <li>
          <label className={Style.inputName}>mail<span className={Style.redColor}>*</span></label>
          <input
            type="mail"
            {...register("email", { required: true })}
            placeholder={"for Example:jimmy@gmail.com"}
            className={errors.lastname ? Style.valid : 'textEn'}
            readOnly
            defaultValue={info.email}
          />
        </li>
        <li>
          <label className={Style.inputName}>
             phone number
          </label>
          <input
            type="tel"
            {...register("phoneNumber", { required: false })}
            className={errors.phoneNumber ? Style.valid : 'textEn'}
            // defaultValue={info.phoneNumber}
          />
        </li>
        <li className={Style.btnHolder}>
          <Button value="submit" classbtn="submitBtn" style={{ width: "100%" }} />
        </li>
      </ul>
    </form>
  )
}

export default ForeignUserProfileForm