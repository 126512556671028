import React from 'react'
import FailedPaymentContainer from '../../../Components/PaymentContainer/FailedPaymentContainer/FailedPaymentContainer'

const FailedPayment = () => {
  return (
    <div className='pgHolder bg-light'>
        <FailedPaymentContainer/>
    </div>
  )
}

export default FailedPayment