import React, { useEffect, useState } from 'react'
import { getForeignPropertyAPI } from '../../../Api/BarangizanServices'
import Divider from '../../../Components/Divider/Divider'
import Footer from '../../../Components/Footer/Footer'
import AmlakKharejiSearchContainer from '../../../Components/SearchContainer/AmlakKharejiSearchContainer/AmlakKharejiSearchContainer'
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import ProjectSlider from '../../../Components/Sliders/HomeSlider/ProjectSlider/ProjectSlider'
import PropertySlider from '../../../Components/Sliders/HomeSlider/PropertySlider/PropertySlider'

const AmlakKhareji = () => {
    const[propertyList,setPropertyList]=useState()
    useEffect(()=>{
        window.scrollTo(0, 0);
        getForeignPropertyHandler()
    },[])
    const getForeignPropertyHandler=async()=>{
        const data=await getForeignPropertyAPI()
        setPropertyList(data.data.list)
    }
    return (
        <div className='pageHolder'>
            <AmlakKharejiHeader/>
            <AmlakKharejiSearchContainer/>
            <Divider name={'آخرین املاک'}/>
            <PropertySlider data={propertyList}/>
            <Divider name={'آخرین پروژه ها'}/>
            <ProjectSlider/>
            <Footer/>
        </div>
    )
}

export default AmlakKhareji
