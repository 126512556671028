import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import Style from './PropertyRegisterForm.module.scss'
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import Select from "react-select";
import { addPropertyUserAPI, getCityApi, getCountriesListAPI, getFeaturesListOptionsAPI, getPropertyTypeAPI, getProvinceApi } from '../../../Api/BarangizanServices';
import { Alert, Checkbox } from 'antd';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import { Link } from 'react-router-dom';
const PropertyRegisterForm = () => {
    const[loadingState,setLoadingState]=useState(false)
  const [propertyTypeValue, setPropertyValue] = useState("");
  const [province, setProvince] = useState([]);
  const [provinceName, setProvinceName] = useState("");
  const [city, setCity] = useState([]);
  const [cityName, setCityName] = useState("");
  const [policy, setPolicy] = useState(false);
  const[loadingType,setLoadingType]=useState(true)
  const [propertyType,setPropertyType]=useState([])
  const[featureListState,setFeatureListState]=useState()
  const[TahatorState,setTahatorState]=useState(false)
  const [countryList, setCountryList] = useState(null);
  const [countryListTahator, setCountryListTahator] = useState(null);
  const {register,handleSubmit,control,reset, formState: { errors } } = useForm();
  useEffect(()=>{
    getDataHandler()
  },[])
  const getDataHandler = async () => {
    const countires = await getCountriesListAPI();
    setCountryList(countires.data.list);
    const data = await getProvinceApi();
    setProvince(data.data);
    const features=await getFeaturesListOptionsAPI(0)
    setFeatureListState(features.data.data)
    const propertyType=await getPropertyTypeAPI()
    setPropertyType(propertyType.data.list)
    setLoadingType(false)
  };
      const getCityListHandler = async (id) => {
        setProvinceName(id.label);
        const data = await getCityApi(id.label);
        setCity(data.data);
      };
      let provinceList = [];
      province.forEach((element, index) => {
        provinceList.push({ value: index, label: element });
      });
      let CityList = [];
      city.forEach((element, index) => {
        CityList.push({ value: index, label: element });
      });
      const [ImageFile, setImageFile] = useState([]);
      const[ImageUrls,setImageUrls]=useState([])

      const imageChange = (evt) => {
        setImageFile(evt.target.files);
        let imageUrlList=[]
        Array.from(evt.target.files).forEach(element=>imageUrlList.push(URL.createObjectURL(element)))
        setImageUrls(imageUrlList)
        
      };
    const onSubmit = (data) => {
        if(!policy) return errorToast("قوانین و مقرارت مطالعه و تایید بفرمایید!")
        setLoadingState(true)
        let formData = new FormData();
        formData.append("EstatesTypeId", propertyTypeValue.value);
        formData.append("EstatesType", propertyTypeValue.label);
        formData.append("description", data.description);
        formData.append("IsTahator", TahatorState);
        formData.append("TahatorCountries", countryListTahator);
        
        formData.append("province", provinceName);
        formData.append("city", cityName.label);
        formData.append("Metraj", data.metraj);
        formData.append("price", data.price);
        formData.append("Address", data.Address);
        for (let index = 0; index < ImageFile.length; index++) {
            formData.append(`Images[${index}].IsVideo`,false)
            formData.append(`Images[${index}].Item`,ImageFile[index])
        }
        for (var i = 0; i < parseInt(featureListState.length); i++) {
          formData.append(`Features[${i}].Key`, data.Features[i].Key);
          let featureString=[]
          data.Features[i].value.forEach(element=>{
            featureString.push(element.label)
          })
          formData.append(`Features[${i}].Value`, featureString.toString());
        }
        addPropertyUserAPI(formData,(succ,res)=>{
            if(succ){
                succToast(res.data.message)
                reset()
                setLoadingState(false)
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white',border:'1px solid #F9C326',height:"2.7rem" }),
        
      }
      const TahatorHandler=(e)=>{
        setTahatorState(e.target.checked)
      }
      const seletCountryTahatorHandler=(e)=>{
        let CountryListSelected=[]
        e.forEach(element => {
          CountryListSelected.push(element.label)
        });
        setCountryListTahator(CountryListSelected.join("_"))
      }

  return (
    <form  onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
    <ul className={Style.itemsHolder}>
        <li>
                <label className={Style.inputName}>نوع ملک<span className={Style.redColor}>*</span></label>
                <Select
                    {...register("type", { required: false })}
                    onChange={setPropertyValue}
                    options={propertyType.map(item=>{
                      return ({ value: item.id, label: item.title })
                    })}
                    name="type"
                    placeholder="انتخاب کنید"
                    styles={colourStyles}
                    
                    />
        </li>
        <li >
            
        <label className={Style.inputName}> استان<span className={Style.redColor}>*</span></label>
        <Select
              {...register("province", { required: false })}
              onChange={getCityListHandler}
              options={provinceList}
              name="province"
              placeholder="انتخاب کنید"
              styles={colourStyles}
            />
        </li>
        <li>
        <label className={Style.inputName}> شهر<span className={Style.redColor}>*</span></label>
        <Select
              {...register("city", { required: false })}
              options={CityList}
              name="city"
              onChange={setCityName}
              placeholder="انتخاب کنید"
              styles={colourStyles}
            />
        </li>
        <li>
            <label className={Style.inputName}>متراژ<span className={Style.redColor}>*</span></label>
            <Input type='text'  {...register('metraj',{ required: true })} placeholder={"مثال: 200"}  inputtype={errors.metraj ? "valid" : "text"} />
        </li>
        <li>
            <label className={Style.inputName}>قیمت<span className={Style.redColor}>*</span></label>
            <Input type='number'  {...register('price',{ required: true })} placeholder={"به صورت عدد و به تومان"}  inputtype={errors.price ? "valid" : "text"} />
        </li>
        <li>
            <label htmlFor='isTahator' className={Style.TahatorHolder}>
            <input type="checkbox" name="" id="isTahator" onChange={TahatorHandler}/> تهاتر 
            </label>
            
        </li>
        {
          TahatorState?<li>
          <label htmlFor="type">  کشور برای تهاتر</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={seletCountryTahatorHandler}
              options={
                countryList?.map(item=>{
                  return({ value: item.id, label: item.title })
                })
              }
              name="type"
              placeholder="انتخاب کنید"
              className={Style.selectOptionsearch}
              isMulti
            />
          </div>
        </li>:null
        }

        <li className={Style.TextAreaHandel}>
            <label className={Style.inputName}>آدرس دقیق ملک<span className={Style.redColor}>*</span></label>
            <textarea
            {...register("Address", { required: true })}
            className={errors.Address ? "valid" : "text"}
            autoComplete="Address"
            type="text"
            name="Address"
            />
        </li>
        <li className={Style.TextAreaHandel}>
            <label className={Style.inputName}>توضیحات<span className={Style.redColor}>*</span></label>
            <textarea
            {...register("description", { required: true })}
            className={errors.description ? "valid" : "text"}
            autoComplete="description"
            type="text"
            name="description"
            placeholder={"مثال:فاصله تا امتیازات، عرض کوچه و سایر امکانات...."}
            />
        </li>
        </ul>
        <ul className={Style.InputHolder}>
        {
          featureListState&&featureListState.map((item,i)=>{
            return(
              <li key={item.id}>
                <input
                  {...register(`Features[${i}].Key`)}
                  className={errors.username ? "valid" : "text"}
                  autoComplete={`Features[${i}].Key`}
                  type="text"
                  name={`Features[${i}].Key`}
                  style={{ marginBottom: "0.5rem" }}
                  defaultValue={item.text}
                  readOnly
                />
                  <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <div className={Style.selectOption}>
                    <Select
                       {...register(`Features[${i}].value`)}
                      options={
                          item.options.map(item=>{
                            return(
                              {
                                label:item.text,
                                value:item.text
                              }
                            )
                          })
                      }
                      name={`Features[${i}].value`}
                      // onChange={setFeatureTest}
                      onChange={onChange}
                      placeholder="انتخاب کنید"
                      className={Style.selectOptionsearch}
                      isMulti
                      
                      defaultValue={[{label:"ندارد",value:0}]}
                    />
                  </div> 
                  )}
                  name={`Features[${i}].value`}
                  control={control}
                  rules={{ required: false }}
                  
                  defaultValue={[{label:"ندارد",value:0}]}
                />
              </li>
            )
          })
        }
      </ul>
        <ul className={Style.itemsHolder}>
        <li>
            <label htmlFor="picture" className={Style.FileLabel}>
                <input onChange={(e) => imageChange(e)} type="file" name="" id="picture" style={{display:'none'}}  accept="image/*" multiple/>
                آپلود تصاویر
            </label> 
        </li>

    </ul>
    <ul className={Style.itemsHolder}>
        {
          ImageUrls&&ImageUrls.map((item,index)=>{
            return(
              <li key={index} className={Style.ImagePerview} ><img src={item} className={Style.ImagePerview} alt="" onClick={()=>window.open(item, '_blank')}/></li>
            )
          })
        }
    </ul>
    <section className={'w-100 p-3'}>
        <Checkbox onChange={(e)=>setPolicy(e.target.checked)}>تمامی <Link to={`../${window.location.pathname[1]}/termsandrules`}>قوانین و مقرارت</Link> سایت برانگیزان را خوانده و قبول میکنم</Checkbox>
        <Alert
        type="warning"
        message=" تصاویر فقط با فرمت JPG,JPEG,PNG ارسال شود!"
        showIcon
        className="w-100 mt-2"
      />
    </section>
    <div className='w-50'> <Button  value='ثبت' classbtn='submitBtn' style={{width:'100%'}}/></div>
</form>
  )
}

export default PropertyRegisterForm