import React, { useEffect } from 'react'
import { useState } from 'react'
import { getTiketListAPI } from '../../../Api/BarangizanServices'
import TiketLists from '../../Lists/TiketLists/TiketLists'

const UserPanelUsertikets = () => {
  const[ListState,setListState]=useState()
  useEffect(()=>{
    getTiketListHandler()
  },[])

  const getTiketListHandler=async()=>{
    const data=await getTiketListAPI()
    setListState(data.data.list)
  }
  return (
    <div className='pageHolder'>
        <TiketLists dataList={ListState}/>
    </div>
  )
}

export default UserPanelUsertikets