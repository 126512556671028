import { Alert } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  AddTofavoritAPI,
  RemoveTofavoritAPI,
  ResevePropertyAPI,
} from "../../../Api/BarangizanServices";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
import Style from "./PropertyReserv.module.scss";
const PropertyReserv = ({
  openModal,
  data,
  getUpdate,
  visitModal,
  tahatorModal,
}) => {
  const [loadingState, setLoadingState] = useState(false);
  const isLogin = () => !!localStorage.getItem("BarangizanToken");
  const { type } = useParams();
  const navigate = useNavigate();
  const AddTofavoritHandler = () => {
    let info = {
      code: data.estate.code,
    };
    if (isLogin()) {
      if (data.isInUsersFavorites) {
        RemoveTofavoritAPI(info, (succ, res) => {
          if (succ) {
            succToast(res.data.message);
            getUpdate();
          } else {
            errorToast(res.data.errors[0]);
          }
        });
      } else {
        AddTofavoritAPI(info, (succ, res) => {
          if (succ) {
            succToast(res.data.message);
            getUpdate();
          } else {
            errorToast(res.data.message);
          }
        });
      }
    } else {
      navigate(`../${type}/panel`);
    }
  };

  const ResevedPropertyHandler = () => {
    if (isLogin()) {
      setLoadingState(true);
      let info = {
        code: data.estate.code,
      };
      ResevePropertyAPI(info, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false);
          window.location.replace(res.data.data.payment.url);
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    } else {
      navigate(`../${type}/panel`);
    }
  };
  return (
    <section className={Style.SectionHolder}>
      <LoadingPage show={loadingState} />
      <img src="/Assets/Images/Property/property.svg" alt="ملک" />
      <ul className={Style.InfoHolder}>
        <li className={Style.PropertyCode}>
          <p>کدملک</p>
          <span>{data !== undefined ? data.estate.code : "درحال دریافت"}</span>
        </li>
        <li>
          <p>متراژ</p>
          <span>{data !== undefined ? data.estate.area : "درحال دریافت"}</span>
        </li>
        <li className={Style.PriceHolder}>
          <p>مبلغ کل</p>
          <span>
            {data !== undefined
              ? separate(
                  data.estate.price === data.estate.discountedPrice
                    ? data.estate.price
                    : data.estate.discountedPrice
                )
              : "درحال دریافت"}{" "}
            {data && data.estate.currency}
          </span>
        </li>
        <li className={Style.PriceHolder}>
          <p>مبلغ برای رزرو</p>
          <span>
            {data !== undefined
              ? separate(data.estate.prePaymentPrice)
              : "درحال دریافت"}{" "}
            تومان
          </span>
        </li>
        {data?.estate.isEjareyi ? (
          <li className={Style.PriceHolder}>
            <p>مبلغ پیش پرداخت اجاره</p>
            <span>
              {data !== undefined ? separate(data.estate.pish) : "درحال دریافت"}{" "}
              {data?.estate.currency}
            </span>
          </li>
        ) : null}
        {data?.estate.isEjareyi ? (
          <li className={Style.PriceHolder}>
            <p>مبلغ اجاره ماهیانه</p>
            <span>
              {data !== undefined
                ? separate(data.estate.mahiane)
                : "درحال دریافت"}{" "}
              {data?.estate.currency}
            </span>
          </li>
        ) : null}
        {data?.estate.isEjareyi ? (
          <li className={Style.PriceHolder}>
            <p>مبلغ شارژ ماهیانه</p>
            <span>
              {data !== undefined
                ? separate(data.estate.sharj)
                : "درحال دریافت"}{" "}
              {data?.estate.currency}
            </span>
          </li>
        ) : null}

        <li className={Style.ModalGallery} onClick={() => openModal()}>
          <p>گالری تصاویر</p>
        </li>
        <li className={Style.ModalFavorit} onClick={AddTofavoritHandler}>
          {data && data.isInUsersFavorites ? (
            <p>حذف از لیست منتخب</p>
          ) : (
            <p>منتخب برای بازدید </p>
          )}
        </li>
        <li className={Style.ModalFavorit} onClick={() => visitModal()}>
          <p>بازدید ملک </p>
        </li>
        {data && data.isEstateReserved ? (
          <li className={Style.ModalReserv}>
            <p>این ملک رزرو شده است! </p>
          </li>
        ) : (
          <li
            className={Style.ModalReserv}
            onClick={() => ResevedPropertyHandler()}
          >
            <p>رزرو </p>
          </li>
        )}
        {data?.estate.isTahator && isLogin() ? (
          <li
            className={Style.TahatorBTN}
            onClick={() => tahatorModal(data.estate.tahatorCountries)}
          >
            <p>درخواست تهاتر</p>
          </li>
        ) : null}
        {data?.estate.isEjareyi && isLogin() ? (
          <li>
            <Alert
              style={{ textAlign: "right" }}
              message="برای اجاره این ملک، در پنل کاربری ازطریق تیکت درخواست خود را ثبت کنید!"
              type="warning"
            />
          </li>
        ) : null}
        {/*             
            <li className={Style.ModalBuy} >
                <p>درخواست خرید آنلاین </p>
            </li> */}
      </ul>
    </section>
  );
};

export default PropertyReserv;
