import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getForeignPropertyInfoAPI, getForeingPropertyGalleryAPI, getPropertyGalleryAPI, getPropertyInfoAPI } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import Modal from '../../Components/Modal/Modal'
import PropertyContainer from '../../Components/PropertyContainer/PropertyContainer'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import ThumbsSwiper from '../../Components/Sliders/ThumbsSwiper/ThumbsSwiper'
import TopPageDetail from '../../Components/TopPageDetail/TopPageDetail'
import ScrollToTap from '../../Utils/ScrollToTap/ScrollToTap'

const Property = () => {
  const [purchased, setPurchased] = useState(false);
  const [gallery, setGallery] = useState();
  const {id,type}=useParams()

  const closeModalHandler=()=>{
      setPurchased(false)
  }
  const openModalHandler=()=>{
      setPurchased(true)
      type==='khareji'?getForeignPropertyGalleryHandller():getPropertyGalleryHandller()
  }
  const [propertyInfoState,setPropertyInfoState]=useState()
  useEffect(()=>{
    type==='khareji'?getForeingPropertyInfoHandler():getPropertyInfoHandler()
  },[])
  const getPropertyInfoHandler=async()=>{
    const data=await getPropertyInfoAPI(id)
    setPropertyInfoState(data.data)
  }
  const getForeingPropertyInfoHandler=async()=>{
    const data=await getForeignPropertyInfoAPI(id)
    setPropertyInfoState(data.data)
  }
  const getPropertyGalleryHandller=async()=>{
    const data=await getPropertyGalleryAPI(id)
    setGallery(data.data.gallery)
  }
  const getForeignPropertyGalleryHandller=async()=>{
    const data=await getForeingPropertyGalleryAPI(id)
    setGallery(data.data.gallery)
  }
  

  
  return (
<ScrollToTap>
      <div className={'pageHolder'}>
          <Modal show={purchased} modalClose={closeModalHandler} translate={50}>
            <ThumbsSwiper data={gallery}/>
          </Modal>
          {window.location.pathname.split('/')[1]==='dakheli'?<AmlakDakheliHeaders/>:<AmlakKharejiHeader/>}
            <TopPageDetail title={propertyInfoState!==undefined?`${propertyInfoState.estate.title} - ${propertyInfoState.estate.address!==null?propertyInfoState.estate.address:""}` :'درحال دریافت'} date={propertyInfoState!==undefined?propertyInfoState.estate.date:'درحال دریافت'}/>
            <PropertyContainer openModalHandler={openModalHandler} data={propertyInfoState} getUpdate={type==='khareji'?()=>getForeingPropertyInfoHandler():()=>getPropertyInfoHandler()}/>
          <Footer/>
      </div>
</ScrollToTap>
  )
}

export default Property