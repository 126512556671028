import React, { useEffect, useState } from 'react'
import Style from './Timer.module.scss'
const Timer = ({Time}) => {

    useEffect(()=>{
        TimerCountDown(Time)
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[Time])

    const[hour,setHour]=useState('00')
    const[Minuts,setMinuts]=useState('00')
    const[seconds,setSeconds]=useState('00')
    let interval=null
    const TimerCountDown=(Time)=>{
        
        const getDay=new Date().getTime()+parseInt(`${Time}000`)
        interval=setInterval(()=>{
            const now =new Date().getTime()
            const distance=getDay-now;
            const hours=Math.floor(distance%(24*60*60*1000)/(1000*60*60))
            const minuts=Math.floor(distance%(60*60*1000)/(1000*60))
            const TimerSecond=Math.floor(distance%(60*1000)/(1000))
            if(distance<0){
                //Stop Timer
                clearInterval(interval.current)
            }else{
                //update Timer
                setHour(hours)
                setMinuts(minuts)
                setSeconds(TimerSecond)
            }
        })
    }
    
    
    return (
        <ul className={Style.countdown}>
            <li><span id="seconds"></span>{seconds<10 && seconds>0? "0"+seconds:seconds}</li>
            <li><span id="seprator"></span>:</li>
            <li><span id="minutes"></span>{Minuts<10  && Minuts>0? "0"+Minuts:Minuts}</li>
            <li><span id="seprator"></span>{hour>0? ':':null}</li>
            {
                hour>0?<li><span id="hour"></span>{hour}</li>:null
            }
        </ul>
    )
}

export default Timer
