import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Style from './PropertySlider.module.scss'
import "swiper/css/pagination"
import SwiperCore, {
    Pagination
  } from 'swiper';
import PropertyPerviewSlide from '../../../PropertyPerviewSlide/PropertyPerviewSlide';
import LoadingPage from '../../../Loading/LoadingPage/LoadingPage';
  
  // install Swiper modules
  SwiperCore.use([Pagination]);
  
const PropertySlider = ({data}) => {
    let loading=null
  if(data===undefined){
      loading=<LoadingPage show={true}/>  
  }
  else{
    loading=<Swiper  spaceBetween={30}  className={Style.swiper} 
    breakpoints={{
        // when window width is >= 640px
        250: {
            slidesPerView: 1.3,
        },
        640: {
          slidesPerView: 3,
        },
        // when window width is >= 768px
        999: {
            slidesPerView:4.3,
          },
      }}
    >
      {
        data.map((item)=>{
          return(

            <SwiperSlide  key={item.code} className={Style.swiperSlide}><PropertyPerviewSlide item={item}/></SwiperSlide>
          )
        })
      }
    </Swiper>
  }
    return (
        <div className={Style.SliderHolder}>
          {
            loading
          }
        </div>
    )
}

export default PropertySlider
