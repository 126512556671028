import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import ContactUsForm from '../../Components/Forms/ContactUsForm/ContactUsForm'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import Style from  './ContactUs.module.scss'
const ContactUs = () => {
    const{type}=useParams()
  return (
    <div className='pageHolder'>
        {type==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>}
        <section className={Style.LoginSection}>
            <img src="/Assets/Images/ContactUs/contactUs.svg" alt="Register" />
            <div className={Style.formHolder}><ContactUsForm/></div>
        </section>
        <Footer/>
    </div>
  )
}

export default ContactUs