import React, { useState } from 'react'
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Style from './ForeignTiketsListTabel.module.scss'
import { Link } from 'react-router-dom';
import Divider from '../../Divider/Divider';

class ForeignTiketsListTabel extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block',direction:'rtl' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            پاکسازی
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    let dataSource=[]
    let index = 1;
    this.props.dataList &&
      this.props.dataList.forEach((element) => {
        dataSource.push({
          key:index,
          id:element.id,
          subject: element.subject,
          situation: element.ticketStatus===0?'Waiting':element.ticketStatus===1?"LastMessageByAdmin":element.ticketStatus===2?'LastMessageByStore':"Closed",
          date: element.enlishDateAdded,
          ticketPriorities:element.priorities===0?'Very Low':element.priorities===1?'Low':element.priorities===2?'Moderate':element.priorities===3?'High':'veryHigh',
        });
        index = index + 1;
      });
    const columns = [
      {
        title: 'Index',
        dataIndex: 'key',
        key: 'key',
        width: '7%',

      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: '33%',
        ...this.getColumnSearchProps('subject'),
        render: (text,record) => <Link to={`/panel/enmessage/${record.id}`}>{text}</Link>,
      },
      {
        title: 'Situation',
        dataIndex: 'situation',
        key: 'situation',
        width: '20%',
        ...this.getColumnSearchProps('situation'),
      },
      {
        title: 'Importance',
        dataIndex: 'ticketPriorities',
        key: 'ticketPriorities',
        width: '10%',
        ...this.getColumnSearchProps('ticketPriorities'),
      },

      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '30%',
        ...this.getColumnSearchProps('date'),
      },
      
    ];
    return <div className={Style.TabelHolder}>
        <Divider name='لیست تیکت های شما' bgColor='#fff'/>
        <Table columns={columns} dataSource={dataSource.reverse()} loading={this.props.dataList === undefined ? true : false}/>
        
        </div>;
  }
}

export default ForeignTiketsListTabel