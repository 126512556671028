import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import Style from "./UserPanelContainer.module.scss";
import UserPanelContracts from "./UserPanelContracts/UserPanelContracts";
import UserPanelMagazineAdvertisingRegister from "./UserPanelMagazineAdvertising/UserPanelMagazineAdvertising";
import UserPanelPersonAdvertising from "./UserPanelPersonAdvertising/UserPanelPersonAdvertising";
import UserPanelProfile from "./UserPanelProfile/UserPanelProfile";
import UserPanelPropertyRegister from "./UserPanelPropertyRegister/UserPanelPropertyRegister";
import UserPanelPropertyReserved from "./UserPanelPropertyReserved/UserPanelPropertyReserved";
import UserPanelSelectedProperty from "./UserPanelSelectedProperty/UserPanelSelectedProperty";
import UserPanelSideBar from "./UserPanelSideBar/UserPanelSideBar";
import UserPanelSendTikets from "./UserPanelSendTikets/UserPanelSendTikets";
import UserPanelTourRegistered from "./UserPanelTourRegistered/UserPanelTourRegistered";
import UserPanelWallet from "./UserPanelWallet/UserPanelWallet";
import UserPanelUsertikets from "./UserPanelUsertikets/UserPanelUsertikets";
import { itemInfo } from "../../Utils/Context/UserPanelItemIdContext";
import UserPanelVisitChoice from "./UserPanelVisitChoice/UserPanelVisitChoice";
import { useParams } from "react-router-dom";
import ForeignPropertyRegisterForm from "../Forms/ForeignPropertyRegisterForm/ForeignPropertyRegisterForm";
import UserPanelTahtorRequests from "./UserPanelTahtorRequests/UserPanelTahtorRequests";
import UserPanelMagazinRequest from "./UserPanelMagazinRequest/UserPanelMagazinRequest";
import UserpanelTahatorResived from "./UserpanelTahatorResived/UserpanelTahatorResived";
const UserPanelContainer = () => {
  const [mobileSideBar, setMobileSideBar] = useState(false);
  const { itemId } = useContext(itemInfo);
  const { type } = useParams();
  return (
    <section className={Style.SectionHolder}>
      <div
        className={
          mobileSideBar ? Style.ActiveSideBarHolder : Style.SideBarHolder
        }
      >
        <UserPanelSideBar CloseSideBar={() => setMobileSideBar(false)} />
      </div>
      <div className={Style.ContentHolder}>
        <div
          className={Style.OpenSideBar}
          onClick={() => setMobileSideBar(true)}
          style={{ display: mobileSideBar ? "none" : null }}
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </div>
        {itemId === 1 ? (
          <UserPanelProfile />
        ) : itemId === 2 ? (
          type === "dakheli" ? (
            <UserPanelPropertyRegister />
          ) : (
            <ForeignPropertyRegisterForm />
          )
        ) : itemId === 3 ? (
          <UserPanelPersonAdvertising />
        ) : itemId === 4 ? (
          <UserPanelMagazineAdvertisingRegister />
        ) : itemId === 5 ? (
          <UserPanelPropertyReserved />
        ) : itemId === 6 ? (
          <UserPanelTourRegistered />
        ) : itemId === 7 ? (
          <UserPanelContracts />
        ) : itemId === 8 ? (
          <UserPanelVisitChoice />
        ) : itemId === 9 ? (
          <UserPanelWallet />
        ) : itemId === 11 || itemId === 10 ? (
          <UserPanelSendTikets />
        ) : itemId === 12 ? (
          <UserPanelUsertikets />
        ) : itemId === 13 ? (
          <UserPanelSelectedProperty />
        ) : itemId === 14 ? (
          <UserPanelTahtorRequests />
        ) : itemId === 15 ? (
          <UserPanelMagazinRequest />
        ) : itemId === 16 ? (
          <UserpanelTahatorResived />
        ) : null}
      </div>
    </section>
  );
};

export default UserPanelContainer;
