import { Checkbox, Col, Row } from "antd";
import React from "react";
import { Spinner} from "react-bootstrap";
import Style from './UserPanelPropertiesCheckBox.module.scss'
import PropertyUserPanelSelectedPerview from "../../PropertyContainer/PropertyUserPanelSelectedPerview/PropertyUserPanelSelectedPerview";
const UserPanelPropertiesCheckBox = ({ data,setBtn }) => {
    function onChange(checkedValues) {
        console.log(checkedValues)
        if(checkedValues.length===0){
            setBtn(false)
        }
        else{
            setBtn(true)
        }
      }
  return (
    <section className={"w-100 flexStyle"}>
    {/* {
        data===undefined?<Spinner animation="border" variant="warning" className="mt-3"/>:<Checkbox.Group  style={{ width: '100%' }} onChange={onChange}>
        {data.map((item,index) => {
            return (
            
            <Row className="w-100" align="middle" key={item.id} >
                <Col span={24} align="middle" >
                <Checkbox value={item.code}  className={Style.CheckBoxHolder}>
                    <PropertyUserPanelSelectedPerview key={item.code} data={item} className='w-100'/>
                </Checkbox>
                </Col>
            </Row>
            
            );
        })}
      </Checkbox.Group>
    } */}
        {data?.map((item,index) => {
            return (
            
            <Row className="w-100" align="middle" key={item.id} >
                <Col span={24} align="middle" >
                    <PropertyUserPanelSelectedPerview key={item.code} data={item} className='w-100'/>
                </Col>
            </Row>
            
            );
        })}
    </section>
  );
};

export default UserPanelPropertiesCheckBox;
