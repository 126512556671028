import React, { useContext, useState } from 'react';
import KharejiFilter from '../KharejiFilter/KharejiFilter';
import Style from '../DakheliResultsContainer/DakheliResultsContainer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSort } from '@fortawesome/free-solid-svg-icons';
import { Radio, Switch } from "antd";
import ResultList from '../ResultList/ResultList';
import { ForeignFilterInfo } from '../../../Utils/Context/ForeignSearchEngineContext';
import { Spinner } from "react-bootstrap";
import ForeignBarterFilter from '../ForeignBarterFilter/ForeignBarterFilter';
import { ForeignBarterFilterInfo } from '../../../Utils/Context/ForeingBarterFilterData';

const ForeignBarterResult = ({openModalHandler,openModalHandler2,searchUpdate,updatePage,data}) => {
    const options = [
        { label: "ارزانترین", value: "LowestPrice" },
        { label: "گرانترین", value: "HighestPrice" },
        { label: "متراژ بالا", value: "HighestArea" },
        { label: "متراژ پایین", value: "LowestArea" },
      ];

   
    const { ForeignBarterFilterData, setForeignBarterFilterData } = useContext(ForeignBarterFilterInfo);
 

    
    const SortItembgColoHandler = async (e) => {
        if (e.target.value === "LowestPrice") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 0,
            isTavizi:true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestPrice") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 1,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestArea") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 2,
            isTavizi:true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        } else if (e.target.value === "LowestArea") {
          let items = {
            estateType: ForeignBarterFilterData.estateType,
            code: ForeignBarterFilterData.code,
            country: ForeignBarterFilterData.country,
            foreignEstateSellingType:ForeignBarterFilterData.foreignEstateSellingType,
            city: ForeignBarterFilterData.city,
            minArea: ForeignBarterFilterData.minArea,
            maxArea: ForeignBarterFilterData.maxArea,
            minPrice: ForeignBarterFilterData.minPrice,
            maxPrice: ForeignBarterFilterData.maxPrice,
            sortby: 3,
            isTavizi: true,
            isEjreyi:ForeignBarterFilterData.isEjreyi
          };
          setForeignBarterFilterData(items);
          searchUpdate();
        }
      };



  return (
    <section className={Style.ContainerHolder}>
        <div className={Style.FilterIcon} onClick={()=>openModalHandler2()}><FontAwesomeIcon icon={faFilter}/></div>
        <div className={Style.FilterPart}><ForeignBarterFilter updateSearch={()=>searchUpdate(1)}/></div>
        <div className={Style.ListPart}>
            <p onClick={()=>openModalHandler()} className={Style.SortItemBTN}><FontAwesomeIcon icon={faSort}/> مرتب سازی بر اساس</p>
            <div className={Style.SortItemHolder}>
          <p>
            مرتب سازی بر اساس  <FontAwesomeIcon icon={faSort} />
             : 
          </p>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={SortItembgColoHandler}
            defaultValue={
                ForeignBarterFilterData.sortby === 0
                ? "LowestPrice"
                : ForeignBarterFilterData.sortby === 1
                ? "HighestPrice" 
                : ForeignBarterFilterData.sortby === 2
                ? "HighestArea" 
                : ForeignBarterFilterData.sortby === 3
                ? "LowestArea" 
                : null
            }
          />
          
        </div>
        <hr />
        {data === undefined ? (
          <div className="w-100 text-center mt-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : data.list.length === 0 ? (
          <p className="w-100 text-center">!موردی یافت نشد</p>
        ) : (
          <ResultList
            list={data.list}
            totalPosts={data.pagination.totalPages * 32}
            updatePage={updatePage}
          />
        )}
        </div>
    </section>
  )
};

export default ForeignBarterResult;
