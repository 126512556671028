import React from 'react'
import Style from './LoadingPage.module.scss'
import ReactLoading from 'react-loading';

const LoadingPage = ({show}) => {
    return (
        <div className={show?Style.LoadingPageHolder:Style.displaynone}>
            <ReactLoading type={'spinningBubbles'} color='#F9C326'  height={60} width={80} />
            <p className={Style.TextLoading}>از صبوری شما متشکریم</p>
        </div>
    )
}

export default LoadingPage