import { Checkbox, Input } from "antd";
import React, { useContext, useState } from "react";
import Style from "./DakheliFilter.module.scss";
import { Select } from "antd";
import {
  getFilterDataAPI,
  getPropertyTypeAPI,
} from "../../../Api/BarangizanServices";
import { useEffect } from "react";
import Button from "../../Button/Button";
import { useLocation } from "react-router-dom";
import { FilterInfo } from "../../../Utils/Context/SearchEngineContext";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
const { Option } = Select;

const DakheliFilter = ({ searchUpdate }) => {
  const { FilterData, setFilterData } = useContext(FilterInfo);
  const { state } = useLocation();
  const [citis, setCitis] = useState([]);
  const [dataFilter, setDataFilter] = useState();
  const [estateList, setEstateList] = useState();
  const [propertyCode, setPropertyCode] = useState();
  const [minMetraj, setMinMetraj] = useState("");
  const [maxMetraj, setMaxMetraj] = useState("");
  const [priceRange, setPriceRange] = useState();
  const [citySelect, setCitySelect] = useState();
  const [provinceSelect, setProvinceSelect] = useState();
  const [propertyTypeState, setPropertyTypeState] = useState();
  const [propertyTypeList2, setPropertyTypeList2] = useState();
  const [propertyTypeSelect, SetPropertyTypeSelect] = useState();
  
  const propertyTypeChangeHandler = (info) => {
    if (info.includes(1)) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      setPropertyTypeList2(propertyTypeArrayList);
    } else if (info.length === 0) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      propertyTypeState &&
        propertyTypeState.forEach((element) => {
          propertyTypeArrayList.push(
            <Option
              style={{ textAlign: "right" }}
              key={element.title}
              value={element.title}
            >
              {element.title}
            </Option>
          );
        });
      setPropertyTypeList2(propertyTypeArrayList);
    }
    SetPropertyTypeSelect(info);
  };

  useEffect(() => {
    getFilterDataHanlder();
  }, []);
  const getFilterDataHanlder = async () => {
    const data = await getFilterDataAPI();
    setDataFilter(data);
    const propertyType = await getPropertyTypeAPI();
    setPropertyTypeState(propertyType.data.list);
  };
  useEffect(() => {
    test();
  }, [dataFilter]);

  let propertyTypeArrayList = [];
  propertyTypeArrayList.push(
    <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
      {"همه"}
    </Option>
  );
  propertyTypeState &&
    propertyTypeState.forEach((element) => {
      propertyTypeArrayList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.id}
        >
          {element.title}
        </Option>
      );
    });

  const test = () => {
    let DefaultCity = [];
    dataFilter &&
      dataFilter.cities.forEach((element) => {
        DefaultCity.push(
          <Option style={{ textAlign: "right" }} key={element.cityName}>
            {element.cityName}
          </Option>
        );
      });
    setCitis(DefaultCity);
  };

  let provincesChildren = [];
  dataFilter &&
    dataFilter.provinces.forEach((element) => {
      provincesChildren.push(
        <Option style={{ textAlign: "right" }} key={element}>
          {element}
        </Option>
      );
    });

  const rangeNumber =
    dataFilter && Math.ceil((dataFilter.maxPrice - dataFilter.minPrice) / 5);
  let rangeArray = [];
  let minArray = dataFilter && dataFilter.minPrice;
  for (let index = 1; index <= 5; index++) {
    rangeArray.push(
      `${separate(minArray)} - ${separate(
        index === 5 ? dataFilter && dataFilter.maxPrice : rangeNumber * index
      )}`
    );
    minArray = rangeNumber * index + 1;
  }

  let CitiesChildren = [];

  function handelProivince(value) {
    setProvinceSelect(value);
    CitiesChildren = [];
    for (let index = 0; index < value.length; index++) {
      const found =
        dataFilter &&
        dataFilter.cities.find((element) => element.province === value[index]);
      CitiesChildren.push(
        <Option style={{ textAlign: "right" }} key={found.cityName}>
          {found.cityName}
        </Option>
      );

      setCitis(CitiesChildren);
    }
  }
  const defaultPrices = rangeArray.find(
    (element) => element === `${FilterData.minPrice}-${FilterData.maxPrice}`
  );

  let selectPrice = (
    <select
      defaultValue={FilterData.minPrice === undefined ? 0 : defaultPrices}
      className={Style.SelectOptionHolder}
      onChange={(e) => setPriceRange(e.target.value)}
    >
      <option value={0} disabled>
        بازه قیمت
      </option>
      {rangeArray.map((item, index) => {
        return (
          <option style={{ direction: "ltr" }} key={index} value={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
  let defaultProperty = [];

  FilterData.estateType &&
    FilterData.estateType.split(",").forEach((element) => {
      defaultProperty.push(parseInt(element));
    });
  let defaultProvince = [];
  FilterData.province &&
    FilterData.province
      .toString()
      .split(",")
      .forEach((element) => {
        defaultProvince.push(element);
      });
  let defaultCity = [];
  FilterData.city &&
    FilterData.city
      .toString()
      .split(",")
      .forEach((element) => {
        defaultCity.push(element);
      });
  useEffect(() => {
    searchUpdate();
  }, [FilterData]);
  const getListData = async () => {
    let items = {
      estateType:
      propertyTypeSelect === undefined
          ? FilterData.estateType
          : propertyTypeSelect.toString(),
      code: propertyCode === undefined ? FilterData.code : propertyCode,
      province:
        provinceSelect === undefined
          ? defaultProvince.length === 0
            ? undefined
            : defaultProvince
          : provinceSelect.toString(),
      city:
        citySelect === undefined
          ? defaultCity.length === 0
            ? undefined
            : defaultCity
          : citySelect.toString(),
      minArea:
        minMetraj === ""
          ? FilterData.minArea !== 0
            ? state.minArea
            : ""
          : minMetraj,
      maxArea:
        maxMetraj === ""
          ? FilterData.maxArea !== 0
            ? state.maxArea
            : ""
          : maxMetraj,
      minPrice:
        priceRange === undefined
          ? FilterData.minPrice
          : priceRange.split("-")[0],
      maxPrice:
        priceRange === undefined
          ? FilterData.maxPrice
          : priceRange.split("-")[1],
      LowestPrice: null,
      HighestPrice: null,
      HighestArea: null,
      LowestArea: null,
    };
    setFilterData(items);
    // console.log(items)
  };
  return (
    <div className={Style.FilterComponent}>
      {dataFilter === undefined || propertyTypeState === undefined ? null : (
        <section className={Style.sectionFilter}>
          <h3>جست و جو</h3>
          <hr />
          <Select
            mode="multiple"
            allowClear
            className={Style.SelectOptionHolder}
            placeholder=" نوع ملک"
            onChange={propertyTypeChangeHandler}
            defaultValue={defaultProperty}
          >
            {propertyTypeList2 === undefined
              ? propertyTypeArrayList
              : propertyTypeList2}
          </Select>
          <Select
            mode="multiple"
            allowClear
            className={Style.SelectOptionHolder}
            placeholder=" استان "
            onChange={handelProivince}
            defaultValue={defaultProvince}
          >
            {provincesChildren}
          </Select>
          <Select
            mode="multiple"
            allowClear
            className={Style.SelectOptionHolder}
            placeholder=" شهر "
            onChange={setCitySelect}
            defaultValue={defaultCity}
          >
            {citis}
          </Select>
          <Input
            defaultValue={FilterData.code && FilterData.code}
            placeholder="کدملک..."
            className={Style.TextInput}
            onChange={(e) => setPropertyCode(e.target.value)}
          />
          <Input
            defaultValue={FilterData.minArea !== 0 ? FilterData.minArea : null}
            placeholder="از...مترمربع"
            className={Style.TextInput}
            onChange={(e) => setMinMetraj(e.target.value)}
          />
          <Input
            defaultValue={FilterData.maxArea !== 0 ? FilterData.maxArea : null}
            placeholder="تا...مترمربع"
            className={Style.TextInput}
            onChange={(e) => setMaxMetraj(e.target.value)}
          />

          {dataFilter && selectPrice}

          <Button
            value={"جست جو"}
            classbtn={"confrimBtn"}
            style={{ width: "100%" }}
            onClick={() => getListData()}
          />
        </section>
      )}
    </div>
  );
};

export default DakheliFilter;
