import React from 'react';

import Button from '../Button/Button';
import Style from './MagazineMedia.module.scss'
const MagazineMedia = ({openModal,data}) => {

  return(
    <section className={Style.ComponentHolder}>
      <div>
      <img src={`https://api.barangizan.ir${data.cover}`} alt="magazine" onClick={()=>openModal()} />
      </div>
        
      <div>
      <Button classbtn={'confrimBtn'} value='نمایش مجله' style={{width:'100%'}} onClick={()=>openModal()}/>
        {
          data.description===""?null: <div className={Style.DescHolder} dangerouslySetInnerHTML={{__html: data!==undefined?data.description:'درحال دریافت'}}></div>
        }
      </div>
       
    </section>
  )
};

export default MagazineMedia;
