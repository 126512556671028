import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs } from "antd";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../../Utils/Toast/Toast";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import Style from "./RegisterForm.module.scss";

const { TabPane } = Tabs;

const RegisterForm = () => {
  const { type } = useParams();
  const history = useNavigate();
  const[userType,setUserType]=useState("0")
  const[userType2,setUserType2]=useState("0")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data.Password !== data.ConfirmPassword) {
      errorToast("رمزعبور های وارد شده یکسان نیستند!");
    } else {
      setTimeout(() => {
        history(`../${type}/confirmcode`, { state: data });
      }, 500);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={Style.formHolder}
      autoComplete="on"
    >
      <Tabs defaultActiveKey={type==='dakheli'?"2":"1"} type="card" size={"large"} centered>
        <TabPane tab="English" key="1">
          <h4>
            Register
            <FontAwesomeIcon icon={faHandshake} />
          </h4>
          <label className={Style.inputNameEn}>
            Type<span className={Style.redColor}>*</span>
          </label>
          <div className={Style.LocationHolder} style={{ marginTop: "0" }}>
            <select
              name=""
              id=""
              style={{ width: "100%" ,direction:'ltr'}}
              {...register("UserType", { required: true })}
              onChange={(e)=>setUserType(e.target.value)}
            >
              <option disabled style={{textAlign:'left'}}>choose...</option>
              <option value={0} style={{textAlign:'left'}}>User</option>
              <option value={1} style={{textAlign:'left'}}>Agency </option>
            </select>
          </div>
          <label className={Style.inputNameEn}>
          {userType==="0"?"firstName":'surName'}<span className={Style.redColor}>*</span>
          </label>
          <Input
            {...register("FirstName", { required: true })}
            inputtype={errors.FirstName ? "valid" : "text"}
            autoComplete="firstname"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />

          <label className={Style.inputNameEn}>
            {userType==="0"?"lastName":'CompanyName'}<span className={Style.redColor}>*</span>
          </label>
          <Input
            {...register("LastName", { required: true })}
            inputtype={errors.LastName ? "valid" : "text"}
            autoComplete="lastname"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />

          <label className={Style.inputNameEn}>
            Whatsapp Number{" "}
            {type === "dakheli" ? (
              <span className={Style.redColor}>*</span>
            ) : null}
          </label>
          <Input
            type="tel"
            maxLength="11"
            {...register("PhoneNumber", { required: type === "dakheli" })}
            inputtype={errors.PhoneNumber ? "valid" : "text"}
            autoComplete="phoneNumber"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />

          <label className={Style.inputNameEn}>
            Email{" "}
            {type === "khareji" ? (
              <span className={Style.redColor}>*</span>
            ) : null}
          </label>
          <Input
            type="mail"
            {...register("Email", { required: type === "khareji" })}
            inputtype={errors.Email ? "valid" : "text"}
            autoComplete="email"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />

          <label className={Style.inputNameEn}>
            Password <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("Password", { required: true })}
            inputtype={errors.Password ? "valid" : "text"}
            autoComplete="new-password"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />

          <label className={Style.inputNameEn}>
            re Password<span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("ConfirmPassword", { required: true })}
            inputtype={errors.ConfirmPassword ? "valid" : "text"}
            autoComplete="new-password"
            style={{ textAlign: "left" ,direction:'ltr' }}
          />
          <div className={Style.btnHolder}>
            <Button value="SignUp" classbtn={"confrimBtn"} />
            <Button
              value="login page"
              classbtn={"primary"}
              onClick={() => history(`../${type}/login`)}
            />
          </div>
        </TabPane>
        <TabPane tab="فارسی" key="2">
          <h4>
            ثبت نام 
            <FontAwesomeIcon icon={faHandshake} />
          </h4>
          <label className={Style.inputName}>
            شخص<span className={Style.redColor}>*</span>
          </label>
          <div className={Style.LocationHolder} style={{ marginTop: "0" }}>
            <select
              name=""
              id=""
              style={{ width: "100%" }}
              {...register("UserType", { required: true })}
              onChange={(e)=>setUserType2(e.target.value)}
            >
              <option disabled>نوع شخص را انتخاب کنید</option>
              <option value={0}>حقیقی</option>
              <option value={1}>حقوقی</option>
            </select>
          </div>
          <label className={Style.inputName}>
            نام<span className={Style.redColor}>*</span>
          </label>
          <Input
            {...register("FirstName", { required: true })}
            placeholder={"مثال:اصغر"}
            inputtype={errors.FirstName ? "valid" : "text"}
            autoComplete="firstname"
          />

          <label className={Style.inputName}>
           {userType2==="0"?"نام خانوادگی":"نام شرکت"} <span className={Style.redColor}>*</span>
          </label>
          <Input
            {...register("LastName", { required: true })}
              
            placeholder={userType2==="0"?"مثال:محمدی":null}
            inputtype={errors.LastName ? "valid" : "text"}
            autoComplete="lastname"
          />

          <label className={Style.inputName}>
            شماره تلفن همراه{" "}
            {type === "dakheli" ? (
              <span className={Style.redColor}>*</span>
            ) : null}
          </label>
          <Input
            type="tel"
            maxLength="11"
            {...register("PhoneNumber", { required: type === "dakheli" })}
            placeholder={"مثال:09XXXXXXXXX"}
            inputtype={errors.PhoneNumber ? "valid" : "text"}
            autoComplete="phoneNumber"
          />

          <label className={Style.inputName}>
            ایمیل{" "}
            {type === "khareji" ? (
              <span className={Style.redColor}>*</span>
            ) : null}
          </label>
          <Input
            type="mail"
            {...register("Email", { required: type === "khareji" })}
            placeholder={"مثال:asghar@gmail.com"}
            inputtype={errors.Email ? "valid" : "text"}
            autoComplete="email"
          />

          <label className={Style.inputName}>
            رمزعبور <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("Password", { required: true })}
            inputtype={errors.Password ? "valid" : "text"}
            autoComplete="new-password"
          />

          <label className={Style.inputName}>
            تکرار رمزعبور <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("ConfirmPassword", { required: true })}
            inputtype={errors.ConfirmPassword ? "valid" : "text"}
            autoComplete="new-password"
          />
          <div className={Style.btnHolder}>
            <Button value="ثبت نام" classbtn={"confrimBtn"} />
            <Button
              value=" صفحه ورود"
              classbtn={"primary"}
              onClick={() => history(`../${type}/login`)}
            />
          </div>
        </TabPane>
      </Tabs>
    </form>
  );
};

export default RegisterForm;
