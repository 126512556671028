import React,{useState,useContext,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader';
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import { getForeignSearchAPI } from '../../Api/BarangizanServices';
import Modal from "../../Components/Modal/Modal";
import ForeignBarterResult from '../../Components/Results/ForeignBarterResult/ForeignBarterResult';
import ForeignBarterFilter from '../../Components/Results/ForeignBarterFilter/ForeignBarterFilter';
import ForeignSortItemBarterModal from '../../Components/Results/ForeignSortItemBarterModal/ForeignSortItemBarterModal';
import { ForeignBarterFilterInfo } from '../../Utils/Context/ForeingBarterFilterData';

const ForeignBarter = () => {
    const { type } = useParams();
    const { ForeignBarterFilterData } =useContext(ForeignBarterFilterInfo);
  const [propertyList, setPropertyList] = useState();
  const [purchased, setPurchased] = useState(false);
  const [purchased2, setPurchased2] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const closeModalHandler = () => {
    setPurchased(false);
    setPurchased2(false);
  };
  const openModalHandler = () => {
    setPurchased(true);
  };
  const openModalHandler2 = () => {
    setPurchased2(true);
  };
  useEffect(() => {
    getPropertyListHandler(1);

  }, []);
  const getPropertyListHandler = async (page) => {
    setLoadingState(true);
    const data = await getForeignSearchAPI(page, ForeignBarterFilterData);
    window.scrollTo(0, 0);
    setPropertyList(data.data);
    setLoadingState(false);
  };

  return (
    <div className="pageHolder">
      <Modal show={purchased} modalClose={closeModalHandler} translate={30}>
        <ForeignSortItemBarterModal searchUpdate={()=>getPropertyListHandler(1)}/>
      </Modal>
      <Modal show={purchased2} modalClose={closeModalHandler} translate={50}>
        <div className="w-100 mb-3 pb-3">
        <ForeignBarterFilter updateSearch={()=>getPropertyListHandler(1)}/>
        </div>

      </Modal>
      {type === "khareji" ? <AmlakKharejiHeader /> : <AmlakDakheliHeaders />}
      <ForeignBarterResult
        updatePage={(e) => getPropertyListHandler(e)}
        searchUpdate={() => getPropertyListHandler(1)}
        data={propertyList}
        openModalHandler={openModalHandler}
        openModalHandler2={openModalHandler2}
      />
      <Footer />
    </div>
  )
}

export default ForeignBarter