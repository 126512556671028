import React, { useContext, useEffect, useState } from "react";
import { getForeignSearchAPI } from "../../Api/BarangizanServices";
import Button from "../../Components/Button/Button";
import Footer from "../../Components/Footer/Footer";
import Modal from "../../Components/Modal/Modal";
import ForeignSortItemModal from "../../Components/Results/ForeignSortItem/ForeignSortItemModal";
import KharejiFilter from "../../Components/Results/KharejiFilter/KharejiFilter";
import KharejiResultContainer from "../../Components/Results/KharejiResultContainer/KharejiResultContainer";
import SortItemModal from "../../Components/Results/SortItemModal/SortItemModal";
import AmlakKharejiHeader from "../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader";
import { ForeignFilterInfo } from "../../Utils/Context/ForeignSearchEngineContext";

const ResultKhareji = () => {
  const { ForeignFilterData } =useContext(ForeignFilterInfo);
  const [propertyList, setPropertyList] = useState();
  const [purchased, setPurchased] = useState(false);
  const [purchased2, setPurchased2] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const closeModalHandler = () => {
    setPurchased(false);
    setPurchased2(false);
  };
  const openModalHandler = () => {
    setPurchased(true);
  };
  const openModalHandler2 = () => {
    setPurchased2(true);
  };
  useEffect(() => {
    getPropertyListHandler(1);
  }, []);
  const getPropertyListHandler = async (page) => {
    setLoadingState(true);
    const data = await getForeignSearchAPI(page, ForeignFilterData);
    window.scrollTo(0, 0);
    setPropertyList(data.data);
    setLoadingState(false);
  };

  return (
    <div className="pageHolder">
      <Modal show={purchased} modalClose={closeModalHandler} translate={30}>
        <ForeignSortItemModal searchUpdate={()=>getPropertyListHandler(1)}/>
      </Modal>
      <Modal show={purchased2} modalClose={closeModalHandler} translate={50}>
        <div className="w-100 mb-3 pb-3">
        <KharejiFilter updateSearch={()=>getPropertyListHandler(1)}/>
        </div>

      </Modal>
      <AmlakKharejiHeader />
      <KharejiResultContainer
        updatePage={(e) => getPropertyListHandler(e)}
        searchUpdate={() => getPropertyListHandler(1)}
        data={propertyList}
        openModalHandler={openModalHandler}
        openModalHandler2={openModalHandler2}
      />
      <Footer />
    </div>
  );
};

export default ResultKhareji;
