import React from 'react'
import Style from './PropertyMedia.module.scss'
const PropertyMedia = ({data}) => {
  return (
    <section className={Style.SectionHolder}>
        {data&&<img src={`https://api.barangizan.ir${data.image}`} alt="" />}
        <h3>توضیحات </h3>
        <div className={Style.DescHolder} dangerouslySetInnerHTML={{__html: data!==undefined?data.description:'درحال دریافت'}}></div>
        
    </section>
  )
}

export default PropertyMedia