import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Style from "./CooperationForm.module.scss";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  CooperationFormCompanyAPI,
  getCityApi,
  getProvinceApi,
} from "../../../Api/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
const CooperationForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [ProvinceListState, setProvinceListState] = useState();
  const [cityListState, setCityListState] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoadingState(true);
    let formData = new FormData();
    formData.append("CompanyName", data.companyName);
    formData.append("CompanySaveNumber", data.companyNumber);
    formData.append("CompanyNationalCode", data.CompanyNationalCode);
    formData.append("NameOfSignatoryPerson", data.owner);
    formData.append("PhoneNumberOfSignatoryPerson", data.ownerPhoneNumber);
    formData.append("CompanyPhone", data.companyPhone);
    formData.append("CompanyWhatsapp", data.companyWhatsAppPhone);
    formData.append("Province", data.Province);
    formData.append("City", data.City);
    formData.append("Adress", `${data.address1}??${data.address2}`);
    // formData.append("", data.address2);
    formData.append(
      "NationalCardFrontImageOfSignatoryPerson",
      data.nationalCart1[0]
    );
    formData.append(
      "NationalCardBackImageOfSignatoryPerson",
      data.nationalCart2[0]
    );
    formData.append(
      "IdCardFirstPageImageOfSignatoryPerson",
      data.nationalCart3[0]
    );
    formData.append(
      "License",
      data.License[0]
    );
    formData.append("Description", data.explain);
    CooperationFormCompanyAPI(formData, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        reset();
        setLoadingState(false);
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  useEffect(() => {
    getProvinceListHandler();
  }, []);
  const getProvinceListHandler = async () => {
    const Province = await getProvinceApi();
    setProvinceListState(Province.data);
  };
  const getCityListHandler = async (e) => {
    const CityList = await getCityApi(e.target.value);
    setCityListState(CityList.data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
        <LoadingPage show={loadingState}/>
      <h4>
        فرم همکاری  
        <FontAwesomeIcon icon={faHandshake} />
      </h4>
      <div className={Style.TopInputs}>
        <div className={Style.FormCol}>
          <label className={Style.inputName}>
            نام شرکت<span className={Style.redColor}>*</span>
          </label>
          <Input
            type="text"
            {...register("companyName", { required: true })}
            inputtype={errors.companyName ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            شماره ي ثبت شركت<span className={Style.redColor}>*</span>
          </label>
          <Input
            type="number"
            {...register("companyNumber", { required: true })}
            inputtype={errors.companyNumber ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            كد ملي شركت <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="number"
            {...register("CompanyNationalCode", { required: true })}
            inputtype={errors.CompanyNationalCode ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            نام و نام خانوادگي شخص صاحب امضا
            <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="text"
            {...register("owner", { required: true })}
            inputtype={errors.owner ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            تلفن همراه صاحب امضا<span className={Style.redColor}>*</span>
          </label>
          <Input
            type="tel"
            placeholder="+9891111111111"
            {...register("ownerPhoneNumber", { required: true })}
            inputtype={errors.ownerPhoneNumber ? "valid" : "text"}
          />
          <label className={Style.inputName}>تلفن ثابت شركت</label>
          <Input
            type="tel"
            {...register("companyPhone", { required: false })}
            inputtype={errors.companyPhone ? "valid" : "text"}
          />
          <label className={Style.inputName}>
            واتساپ شركت<span className={Style.redColor}>*</span>
          </label>
          <Input
            type="tel"
            {...register("companyWhatsAppPhone", { required: true })}
            inputtype={errors.companyWhatsAppPhone ? "valid" : "text"}
          />
        </div>
        <div className={`${Style.FormCol} ${Style.FormCol2}`}>
          <div className={Style.LocationHolder}>
            {window.location.pathname[1] === "khareji" ? (
              <select
                name=""
                id=""
                {...register("country", { required: true })}
              >
                <option value="">کشور</option>
              </select>
            ) : (
              <select
                {...register("Province", { required: true })}
                defaultValue={"-1"}
                id=""
                onChange={getCityListHandler}
              >
                <option disabled value="-1">
                  استان
                </option>
                {ProvinceListState &&
                  ProvinceListState.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            )}
            <select
              defaultValue={"-1"}
              name=""
              id=""
              {...register("City", { required: true })}
            >
              <option value="-1" disabled>
                شهر انتخاب کنید!
              </option>
              {cityListState &&
                cityListState.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>

          <Input
            type="text"
            style={{ marginBottom: "1rem" }}
            placeholder="آدرس 1"
            {...register("address1", { required: true })}
            inputtype={errors.address1 ? "valid" : "text"}
          />
          
          <Input
            type="text"
            style={{ marginBottom: "1rem" }}
            placeholder="آدرس 2"
            {...register("address2", { required: false })}
            inputtype={errors.address2 ? "valid" : "text"}
          />

          <label htmlFor="nationalCart1" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart1"
              style={{ display: "none" }}
              {...register("nationalCart1", { required: true })}
            />
            آپلود روي كارت ملي فرد صاحب امضا
          </label>
          {errors.nationalCart1 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
          <label htmlFor="nationalCart2" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart2"
              style={{ display: "none" }}
              {...register("nationalCart2", { required: true })}
            />
            آپلود پشت كارت ملي فرد صاحب امضا
          </label>
          {errors.nationalCart2 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
          <label htmlFor="nationalCart3" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="nationalCart3"
              style={{ display: "none" }}
              {...register("nationalCart3", { required: true })}
            />
            آپلود صفحه اول شناسنامه فرد صاحب امضا
          </label>
          {errors.nationalCart3 && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
          <label htmlFor="License" className={Style.FileLabel}>
            <input
              type="file"
              name=""
              id="License"
              style={{ display: "none" }}
              {...register("License", { required: true })}
            />
            آپلود مجوز
          </label>
          {errors.License && (
            <span className="text-danger">آپلود تصویر اجباری است</span>
          )}
        </div>
      </div>
      <div className={Style.ExplainHolder}>
        <label className={Style.inputName}>
          توضيحات درخواست و زمينه هاي فعاليت{" "}
        </label>
        <Input
          type="texarea"
          {...register("explain", { required: true })}
          inputtype={errors.explain ? "validTextArea" : "textArea"}
        />
      </div>
      <Button
        value="ارسال فرم"
        classbtn="submitBtn"
        style={{ width: "100%", margin: "1rem 0" }}
      />
      <p className={Style.TextRef}>
        اگر صاحب شرکت نیستید و تمایل به همکاری دارید{" "}
        <Link
          to={
            window.location.pathname.split("/")[1] === "khareji"
              ? "/khareji/crequestperson"
              : "/dakheli/crequestperson"
          }
        >
          اینجا
        </Link>{" "}
        پیام دهید
      </p>
    </form>
  );
};

export default CooperationForm;
