import React from 'react'
import MagazinePropertyRegisterForm from '../../Forms/MagazinePropertyRegisterForm/MagazinePropertyRegisterForm'

const UserPanelMagazineAdvertisingRegister = () => {
  return (
    <div className='flexStyle bg-white'>
        <MagazinePropertyRegisterForm/>
    </div>
  )
}

export default UserPanelMagazineAdvertisingRegister