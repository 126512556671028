import React, { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Style from "./PropertyProjectTables.module.scss";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
class PropertyProjectTables extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`جست و جو...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block", direction: "rtl" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            جست و جو
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            پاکسازی
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let dataSoruce = [];
    let index = 1;
    this.props.data &&
      this.props.data.forEach((element) => {
        dataSoruce.push({
          key: index,
          id: element.code,
          blockName: element.blockName,
          floor: element.floor,
          name: element.title,
          totalMeter: element.area,
          price: `${element.price} - ${this.props.type.currency}` ,
          type:
            this.props.type.foreignEstateSellingType === 0
              ? "نقد"
              : this.props.type.foreignEstateSellingType === 1
              ? "اقساط"
              : "نقد و اقساط",
        });
        index = index + 1;
      });
    const columns = [
      {
        title: "نام بلوک",
        dataIndex: "blockName",
        key: "blockName",
        ...this.getColumnSearchProps("blockName"),
      },
      {
        title: "نام واحد",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: " طبقه",
        dataIndex: "floor",
        key: "floor",
        ...this.getColumnSearchProps("floor"),
      },

      {
        title: "متراژ",
        dataIndex: "totalMeter",
        key: "totalMeter",
        ...this.getColumnSearchProps("totalMeter"),
      },

      {
        title: "قیمت واحد",
        dataIndex: "price",
        key: "price",
        ...this.getColumnSearchProps("price"),
        sorter: (a, b) => a.price.length - b.price.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "نقد اقساط",
        dataIndex: "type",
        key: "type",
        ...this.getColumnSearchProps("type"),
        
      },
      {
        title: "عملیات",
        key: "action",
        render: (text, record) => (
          <Link to={`../unit/${record.id}`}
          >
          نمایش واحد <FontAwesomeIcon icon={faSignOutAlt}/>
        </Link>
        ),
        align:'center'
      },
    ];
    return (
      <div className={Style.TabelHolder}>
        <Table
          
          columns={columns}
          dataSource={dataSoruce}
          pagination={{
            position: ["bottomCenter"],
            total: this.props.totalPages,
            pageSize: 30,
          }}
        />
      </div>
    );
  }
}

export default PropertyProjectTables;
