
import { LoadingOutlined } from '@ant-design/icons'
import React, { useEffect, useState }  from 'react'
import { Accordion, Alert, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { getTiketChatListAPI } from '../../Api/BarangizanServices'
import AnswerTiketForm from '../Forms/AnswerTiketForm/AnswerTiketForm'
import MessageList from '../Lists/MessageList/MessageList'
import Style from './TiketContainerMessage.module.scss'
import TiketSideInfo from './TiketSideInfo/TiketSideInfo'

const TiketContainerMessage = () => {
  const[chatListState,setChatListState]=useState()
  const {id}=useParams()
  useEffect(()=>{
    getChatListHandler()
  },[])
  const getChatListHandler=async()=>{
    const data=await getTiketChatListAPI(id)
    setChatListState(data.data)
  }
  return (
    <section className={Style.ContainerHolder}>
        <div className={Style.SideInfo}><TiketSideInfo info={chatListState&&chatListState.ticket}/></div>
        {
          chatListState===undefined?<h5 className='w-100 text-center text-warning mt-3'><LoadingOutlined /> لطفا صبرکنید.... </h5>:<div className={Style.Messages}>
          {
            chatListState.ticket.ticketStatus===3?          <Alert  variant={'warning'}>
            این تیکت بسته شده است یا به صورت اطلاعیه است!
            </Alert>:          <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                  <Accordion.Header className={Style.AccordionHeader}>پاسخ</Accordion.Header>
                  <Accordion.Body>
                      <AnswerTiketForm info={chatListState.ticket} getUpdate={getChatListHandler}/>
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>
          }


            <MessageList listInfo={chatListState.messages}/>
          </div>
        }
    </section>
  )
}

export default TiketContainerMessage