import { Alert, Checkbox, Input, Select, Skeleton, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCountriesListAPI,
  getForeignCitylistAPI,
  getForeignPriceRangeAPI,
  getForeignPropertyTypeAPI,
} from "../../../Api/BarangizanServices";
import { ForeignFilterInfo } from "../../../Utils/Context/ForeignSearchEngineContext";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import Style from "../DakheliFilter/DakheliFilter.module.scss";
import Button from "../../Button/Button";
import { ForeignBarterFilterInfo } from "../../../Utils/Context/ForeingBarterFilterData";

const { Option } = Select;

const ForeignBarterFilter = ({updateSearch}) => {
  const { ForeignBarterFilterData, setForeignBarterFilterData }=useContext(ForeignBarterFilterInfo);
  const [propertyTypeList, setPropertyTypeList] = useState();
  const [propertyTypeList2, setPropertyTypeList2] = useState();
  const [CountriesList, setCountriesList] = useState();
  const [CountriesList2, setCountriesList2] = useState();
  const [CitiesList, setCitiesList] = useState();
  const [CitiesSelect, setCitiesSelect] = useState();
  const [countriesSelect, setCountriesSelect] = useState();
  const [ForeignPropertyCode, setForeignPropertyCode] = useState();
  const [minArea, setMinArea] = useState();
  const [MaxArea, setMaxArea] = useState();
  const [SalesType, setSalesType] = useState();
  const [priceRangeState, setPriceRangeState] = useState();
  const [priceRangeSelect, setPriceRangeSelect] = useState();
  const [propertyTypeSelect, SetPropertyTypeSelect] = useState();
  useEffect(() => {
    getDataHandler();
  }, []);
  useEffect(()=>{
    updateSearch()
  },[ForeignBarterFilterData])



  const getDataHandler = async () => {
    const countries = await getCountriesListAPI();
    setCountriesList(countries.data.list);
    const propertyType = await getForeignPropertyTypeAPI();
    setPropertyTypeList(propertyType.data.list);
    ForeignBarterFilterData.estateType&&propertyTypeChangeHandler(ForeignBarterFilterData.estateType.split(","))
    ForeignBarterFilterData.country&&getCityListHandler(ForeignBarterFilterData.country.split(","))
  };
  let defaultProperty = [];

  ForeignBarterFilterData.estateType &&
    ForeignBarterFilterData.estateType.split(",").forEach((element) => {
      defaultProperty.push(parseInt(element))
    });

  let defaultCities = [];

  ForeignBarterFilterData.city &&
    ForeignBarterFilterData.city.split(",").forEach((element) => {
      defaultCities.push(element)
    });

  let defaultCountry = [];
    
  ForeignBarterFilterData.country &&
    ForeignBarterFilterData.country.split(",").forEach((element) => {
      if(element==="1"){
      defaultCountry.push(parseInt(element))
    }
      else{
        defaultCountry.push(element)
      }
    });
  const getCityListHandler = async (info) => {
    if (info.includes(1)|| info.includes('1')) {
      CountryList = [];
      CountryList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      setCountriesList2(CountryList);
    } else if (info.length === 0) {
      CountryList = [];
      CountryList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      CountriesList &&
        CountriesList.forEach((element) => {
          CountryList.push(
            <Option
              style={{ textAlign: "right" }}
              key={element.title}
              value={element.title}
            >
              {element.title}
            </Option>
          );
        });
      setCountriesList2(CountryList);
    }
    setCountriesSelect(info);
    const cities = await getForeignCitylistAPI(info);
    setCitiesList(cities.data.list);
    const priceRange = await getForeignPriceRangeAPI(info[0]);
    setPriceRangeState(priceRange);
  };

  const propertyTypeChangeHandler = (info) => {
    
    if (info.includes(1) || info.includes('1')) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      setPropertyTypeList2(propertyTypeArrayList);
    } else if (info.length === 0) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      propertyTypeList &&
        propertyTypeList.forEach((element) => {
          propertyTypeArrayList.push(
            <Option
              style={{ textAlign: "right" }}
              key={element.title}
              value={element.title}
            >
              {element.title}
            </Option>
          );
        });
      setPropertyTypeList2(propertyTypeArrayList);
    }
    SetPropertyTypeSelect(info);
  };
  const HandleChangeCity = (info) => {
    setCitiesSelect(info);
  };
  let CountryList = [];
  CountryList.push(
    <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
      {"همه"}
    </Option>
  );
  CountriesList &&
    CountriesList.forEach((element) => {
      CountryList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.title}
        >
          {element.title}
        </Option>
      );
    });
  let propertyTypeArrayList = [];
  propertyTypeArrayList.push(
    <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
      {"همه"}
    </Option>
  );
  propertyTypeList &&
    propertyTypeList.forEach((element) => {
      propertyTypeArrayList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.id}
        >
          {element.title}
        </Option>
      );
    });

  const rangeNumber =
    priceRangeState &&
    Math.ceil((priceRangeState.maxPrice - priceRangeState.minPrice) / 5);
  let rangeArray = [];
  let minArray = priceRangeState && priceRangeState.minPrice;
  for (let index = 1; index <= 5; index++) {
    rangeArray.push(
      `${separate(minArray)}-${separate(
        index === 5
          ? priceRangeState && priceRangeState.maxPrice
          : rangeNumber * index
      )}`
    );
    minArray = rangeNumber * index + 1;
  }




  const getListData =async() => {
    
    let items = {
      estateType: propertyTypeSelect && propertyTypeSelect.toString(),
      code: ForeignPropertyCode===undefined?ForeignBarterFilterData.code:ForeignPropertyCode,
      country: countriesSelect && countriesSelect.toString(),
      city: CitiesSelect && CitiesSelect.toString(),
      minArea: minArea===undefined?ForeignBarterFilterData.minArea:minArea,
      maxArea: MaxArea===undefined?ForeignBarterFilterData.maxArea:MaxArea,
      foreignEstateSellingType: SalesType===undefined?ForeignBarterFilterData.foreignEstateSellingType:SalesType,
      minPrice: priceRangeSelect===undefined?ForeignBarterFilterData.minPrice:priceRangeSelect.split("-")[0].replaceAll(",", ""),
      maxPrice: priceRangeSelect===undefined?ForeignBarterFilterData.maxPrice:priceRangeSelect.split("-")[1].replaceAll(",", ""),
      sortby:0,
      isTavizi: true,
      isEjreyi:ForeignBarterFilterData.isEjreyi
    };
    setForeignBarterFilterData(items);
    
  };
  return (
    <div className={Style.FilterComponent}>
      <section className={Style.sectionFilter}>
        <h3>جست و جو </h3>
        <hr />
        {
          CountriesList!==undefined?<Select
          mode="multiple"
          allowClear
          className={Style.SelectOptionHolder}
          placeholder=" کشور "
          onChange={getCityListHandler}
          defaultValue={defaultCountry}
        >
          {CountriesList2 === undefined ? CountryList : CountriesList2}
        </Select>:<Skeleton.Input active size={"small"} className={"mb-2"}/>
        }
        <Select
          mode="multiple"
          allowClear
          className={Style.SelectOptionHolder}
          placeholder=" شهر "
          onChange={HandleChangeCity}
          defaultValue={defaultCities}
        >
          {CitiesList &&
            CitiesList.map((item) => {
              return (
                <Option
                  style={{ textAlign: "right" }}
                  key={item.title}
                  value={item.title}
                >
                  {item.title}
                </Option>
              );
            })}
        </Select>
        {
          propertyTypeList!==undefined?<Select
          mode="multiple"
          allowClear
          className={Style.SelectOptionHolder}
          placeholder=" نوع ملک"
          onChange={propertyTypeChangeHandler}
          defaultValue={defaultProperty}
        >
          {propertyTypeList2 === undefined
            ? propertyTypeArrayList
            : propertyTypeList2}
        </Select>:
          <Skeleton.Input active size={"small"} className={"mb-2"}/>
          
        }
        {/* <Input
          placeholder="کدملک..."
          className={Style.TextInput}
          onChange={(e) => setForeignPropertyCode(e.target.value)}
          defaultValue={ForeignBarterFilterData.code && ForeignBarterFilterData.code}
        /> */}
        <Input
        
        defaultValue={ForeignBarterFilterData.minArea !== 0 ? ForeignBarterFilterData.minArea : null}
          placeholder="از...مترمربع"
          className={Style.TextInput}
          onChange={(e) => setMinArea(e.target.value)}
        />
        <Input
        
          defaultValue={ForeignBarterFilterData.maxArea !== 0 ? ForeignBarterFilterData.maxArea : null}
          placeholder="تا...مترمربع"
          className={Style.TextInput}
          onChange={(e) => setMaxArea(e.target.value)}
        />
        {countriesSelect && countriesSelect.length > 1 ? (
          <Alert
            type="warning"
            message="به دلیل انتخاب چندکشور و متفاوت بودن وجه رایج هر کشور قادر به مشخص کردن بازه قیمتی نیستید"
            showIcon
            className="w-100 RTL mb-3"
          />
        ) : (
         <Select
         defaultValue={ForeignBarterFilterData.maxPrice===undefined?999:`${separate(ForeignBarterFilterData.minPrice)}-${separate(ForeignBarterFilterData.maxPrice)}`}
         className={Style.SelectOptionHolder}
         onChange={setPriceRangeSelect}
       >
         <Option disabled value={999} >
           رنج قیمت{" "}
           {priceRangeState && priceRangeState.maxPrice !== undefined
             ? `به ${priceRangeState && priceRangeState.currency}`
             : null}
         </Option>
         {priceRangeState && priceRangeState.maxPrice !== undefined ? (
           rangeArray.map((item, index) => {
             return (
               <Option
                 key={index}
                 value={item}
               >
                 {item}
               </Option>
             );
           })
         ) : (
           <Option
             style={{ direction: "rtl", fontFamily: "iran-suns-num" }}
             disabled
           >
             {"ابتدا یک کشور انتخاب کنید!"}
           </Option>
         )}
       </Select>
        )}
        
        <select
          className={Style.SelectOptionHolder}
          onChange={(e) => setSalesType(e.target.value)}
          defaultValue={ForeignBarterFilterData.foreignEstateSellingType===undefined?999:ForeignBarterFilterData.foreignEstateSellingType}
        >
          <option disabled value={999}>
            شرایط فروش
          </option>
          <option value={0}>نقد</option>
          <option value={1}>اقساط</option>
          <option value={2}>نقد،اقساط</option>
        </select>
        <Button
          value={"جست جو"}
          classbtn={"confrimBtn"}
          style={{ width: "100%" }}
          onClick={() => getListData()}
        />
      </section>
    </div>
  );
};

export default ForeignBarterFilter;
