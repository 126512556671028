import React from 'react'
import Style from './PropertyProjectTabs.module.scss'
const PropertyProjectTabs = ({tab,setTabs}) => {
  return (
    <section className={Style.ComponentHolder}>
        <div className={tab===1?Style.Active:null} onClick={()=>setTabs(1)}>لیست واحد ها</div>
        {/* <div className={tab===2?Style.Active:null} onClick={()=>setTabs(2)}>شرایط خرید</div> */}
        <div className={tab===2?Style.Active:null} onClick={()=>setTabs(2)}> گالری تصاویر</div>
    </section>
  )
}

export default PropertyProjectTabs