import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getTourDetailsAPI } from '../../../Api/BarangizanServices'
import Footer from '../../../Components/Footer/Footer'
import TourHeader from '../../../Components/SiteHeaders/TourHeader/TourHeader'
import TopPageDetail from '../../../Components/TopPageDetail/TopPageDetail'
import TourContainer from '../../../Components/TourContainer/TourContainer'

const TourDetail = () => {
    const {id}=useParams()
    const[TourDetails,setTourDetails]=useState()
    useEffect(()=>{
        getTourDetailHandler()
    },[])
    const getTourDetailHandler=async()=>{
        const data=await getTourDetailsAPI(id)
        setTourDetails(data)
    }
  return (
    <div className='pageHolder'>
        <TourHeader/>
        <TopPageDetail date={TourDetails!==undefined?TourDetails.data.startDate:'درحال دریافت'} title={TourDetails!==undefined?TourDetails.data.title:'درحال دریافت'}/>
        <TourContainer data={TourDetails}/>
        <Footer/>
    </div>
  )
}

export default TourDetail