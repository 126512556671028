import React from 'react'
import Style from './PropertyDetails.module.scss'
const PropertyDetails = ({data}) => {
  return (
    <section className={Style.SectionHolder}>
        <h3>جزئیات</h3>
        <ul className={Style.DetailsList}>
        {data&&data.features.map((item,index)=>{
            return(
                <li key={index} className={Style.Detail}>
                    <p>{item.key}</p>
                    <span>{item.value}</span>
                </li>
            )
        })}
        
        </ul>
    </section>
  )
}

export default PropertyDetails