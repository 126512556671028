import { Radio, Switch } from "antd";
import React, { useContext } from "react";
import { FilterInfo } from "../../../Utils/Context/SearchEngineContext";
import Style from "./SortItemModal.module.scss";
const SortItemModal = ({ searchUpdate }) => {
  const { FilterData, setFilterData } = useContext(FilterInfo);
  const options = [
    { label: "ارزانترین", value: "LowestPrice" },
    { label: "گرانترین", value: "HighestPrice" },
    { label: "متراژ بالا", value: "HighestArea" },
    { label: "متراژ پایین", value: "LowestArea" },
  ];
  const onChangeHandler = (e) => {
    if (e) {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: FilterData.sortby,
        isTavizi: true,
      };
      setFilterData(items);
      searchUpdate();
    } else {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: FilterData.sortby,
        isTavizi: false,
      };
      setFilterData(items);
      searchUpdate();
    }
  };

  function onChange(e) {
    if (e.target.value === "LowestPrice") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 0,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestPrice") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 1,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestArea") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 2,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    } else if (e.target.value === "LowestArea") {
      let items = {
        estateType: FilterData.estateType,
        code: FilterData.code,
        province: FilterData.province,
        city: FilterData.city,
        minArea: FilterData.minArea,
        maxArea: FilterData.maxArea,
        minPrice: FilterData.minPrice,
        maxPrice: FilterData.maxPrice,
        sortby: 3,
        isTavizi: undefined,
      };
      setFilterData(items);
      searchUpdate();
    }
  }
  // console.log(FilterData.sortby)
  return (
    <section className={Style.SortItemModalHolder}>
      <Radio.Group
        className={Style.typeCheckbox}
        options={options}
        onChange={onChange}
        defaultValue={
          FilterData.sortby === 0
            ? "LowestPrice"
            : FilterData.sortby === 1
            ? "HighestPrice"
            : FilterData.sortby === 2
            ? "HighestArea"
            : FilterData.sortby === 3
            ? "LowestArea"
            : null
        }
      />
      <div className={Style.ExchangeHolder}>
        <label htmlFor="exchange"> تهاتر</label>{" "}
        <Switch id="exchange" onChange={onChangeHandler} />
      </div>
    </section>
  );
};

export default SortItemModal;
