import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// import Style from './PropertySlider.module.scss'
import Style from './ProjectSlider.module.scss'
import "swiper/css/pagination"
import SwiperCore, {
    Pagination
  } from 'swiper';
import ProjectPerviewSlide from '../../../ProjectPerviewSlide/ProjectPerviewSlide';
import { useState } from 'react';
import { getProjectListAPI } from '../../../../Api/BarangizanServices';
import SliderSkeleton from '../../../Loading/Skeleton/SliderSkeleton/SliderSkeleton';

  
  // install Swiper modules
  SwiperCore.use([Pagination]);

  
const ProjectSlider = () => {
  const[projectListState,setProjectListState]=useState(null)

  useEffect(()=>{
    getDataHandler()
  },[])
  const getDataHandler=async()=>{
    const data=await getProjectListAPI(1)
    setProjectListState(data.data.list)
  }
  let loadingHandler=null

  if(projectListState===null){
    loadingHandler=<SwiperSlide className={Style.swiperSlide}><SliderSkeleton/></SwiperSlide>
  }
  else{
    loadingHandler=projectListState.map(item=>{
      return(
        <SwiperSlide key={item.code} className={Style.swiperSlide}><ProjectPerviewSlide info={item}/></SwiperSlide>
      )
    })
  }
    return (
        <div className={Style.SliderHolder}>
                <Swiper  spaceBetween={30}  className={Style.swiper} 
                breakpoints={{
                    // when window width is >= 640px
                    250: {
                        slidesPerView: 1.3,
                    },
                    640: {
                      slidesPerView: 3,
                    },
                    // when window width is >= 768px
                    999: {
                        slidesPerView:4.3,
                      },
                  }}
                >
                    {loadingHandler}
                </Swiper>
        </div>
    )
}

export default ProjectSlider
