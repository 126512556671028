import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UserInfoUpdateAPI } from "../../../Api/BarangizanServices";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import Button from "../../Button/Button";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
// import Input from "../../Input/Input";
// import { Input } from 'antd';
import Style from "./ProfileForm.module.scss";

const ProfileForm = ({ info }) => {
  const{type}=useParams()
    const[loadingState,setLoadingState]=useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues:info
  });

  const onSubmit = (data) => {
    setLoadingState(true)
    UserInfoUpdateAPI(data,(succ,res)=>{
        if(succ){
            succToast(res.data.message)
            setLoadingState(false)
            setTimeout(()=>window.location.reload(),1000)
        }
        else{
            errorToast(res.data.errors[0])
            setLoadingState(false)
        }
    })
  };

  

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
      <ul className={Style.itemsHolder}>
        <li>
          <label className={Style.inputName}>
            نام<span className={Style.redColor}>*</span>
          </label>
          <input
            name="firstName"
            {...register("firstName", { required: true})}
            placeholder={"مثال:اصغر"}
            className={errors.firstName ? Style.valid : Style.text}
          />
        </li>
        <li>
          <label className={Style.inputName}>
            {localStorage.getItem('userType')==='0'?"نام خانوادگی":'نام شرکت'}<span className={Style.redColor}>*</span>
          </label>
          <input
            {...register("lastName", { required: true })}
            placeholder={"مثال:محمدی"}
            className={errors.lastName ? Style.valid : Style.text}
          />
        </li>
        <li>
          <label className={Style.inputName}>ایمیل{type==='khareji'?<span className={Style.redColor}>*</span>:null}</label>
          <input
            type="mail"
            {...register("email", { required: type==='khareji' })}
            placeholder={"مثال:asghar@gmail.com"}
            className={errors.lastname ? Style.valid : Style.text}
          />
        </li>
        <li>
          <label className={Style.inputName}>
            تلفن همراه {type==='dakheli'?<span className={Style.redColor}>*</span>:null}
          </label>
          <input
            type="tel"
            {...register("phoneNumber", { required: type==='dakheli' })}
            className={errors.phoneNumber ? Style.valid : Style.text}
            readOnly
          />
        </li>
        <li className={Style.btnHolder}>
          <Button value="ثبت" classbtn="submitBtn" style={{ width: "100%" }} />
        </li>
      </ul>
    </form>
  );
};

export default ProfileForm;
