import React from 'react';
import Footer from '../../../Components/Footer/Footer';
import CooperationFormPerson from '../../../Components/Forms/CooperationFormPerson/CooperationFormPerson';
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader';

const CooperationRequestPerson = () => {
  return (
    <div className={'pageHolder'}>
        {
            window.location.pathname.split('/')[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>
        }
        <div className='w-100'><CooperationFormPerson/></div>
        <Footer/>
    </div>
  )
};

export default CooperationRequestPerson;
