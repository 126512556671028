import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faEnvelope,
  faSignOutAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getNotifDataAPI } from "../../../Api/BarangizanServices";
import { profile } from "../../../Utils/Context/UserInfoContext";
import { itemInfo } from "../../../Utils/Context/UserPanelItemIdContext";
import { errorToast } from "../../../Utils/Toast/Toast";
import Style from "./UserPanelSideBar.module.scss";
const UserPanelSideBar = ({ CloseSideBar }) => {
  const { userInfo } = useContext(profile);
  const[Notif,setNotif]=useState(null)
  const { itemId, setItemId } = useContext(itemInfo);
  const SignOutHandler = () => {
    localStorage.removeItem("BarangizanToken");
    errorToast("باموفقیت خارج شدید!");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  useEffect(()=>{
    getNotifDataHandler()
  },[])
  const getNotifDataHandler=async()=>{
    const data=await getNotifDataAPI()
    setNotif(data.data)
  }
  
  return (
    <section className={Style.ComponentHolder}>
      <div className={Style.CloseSideBar} onClick={() => CloseSideBar()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={Style.UserInfo}>
        <h4>
          <FontAwesomeIcon icon={faUser} /> 
          {userInfo === undefined
            ? "درحال دریافت اطلاعات"
            : `${userInfo.firstName} ${userInfo.lastName}`}
        </h4>
        <p>
          <span>
            {userInfo === undefined
              ? "درحال دریافت اطلاعات"
              : userInfo.phoneNumber}
          </span>
        </p>
      </div>
      <ul className={Style.ProfileItems}>
        <li
          className={itemId === 1 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(1);
            CloseSideBar();
          }}
        >
          <p>پروفایل کاربری</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 2 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(2);
            CloseSideBar();
          }}
        >
          <p> ثبت آگهی ملک</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 3 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(3);
            CloseSideBar();
          }}
        >
          <p> لیست آگهی های شما</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 4 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(4);
            CloseSideBar();
          }}
        >
          <p>ثبت آگهی مجله</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 15 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(15);
            CloseSideBar();
          }}
        >
          <p>لیست درخواست آگهی مجله</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 5 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(5);
            CloseSideBar();
          }}
        >
          <p>ملک های رزرو شده</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 6 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(6);
            CloseSideBar();
          }}
        >
          <p>تورهای ثبت نام شده</p>

          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 14 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(14);
            CloseSideBar();
          }}
        >
          <p>درخواست های تهاتر</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 16? Style.ActiveItem : null}
          onClick={() => {
            setItemId(16);
            CloseSideBar();
          }}
        >
          <p>دریافت درخواست تهاتر</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        {/* <li
          className={itemId === 7 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(7);
            CloseSideBar();
          }}
        >
          <p>لیست قراردادها</p>
          <FontAwesomeIcon icon={faBars} />
        </li> */}
        <li
          className={itemId === 8 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(8);
            CloseSideBar();
          }}
        >
          <p> املاک انتخاب شده برای بازدید </p>
          {/* <p>املاک انتخاب شده </p> */}
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 13 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(13);
            CloseSideBar();
          }}
        >
          <p>املاک مورد علاقه</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={itemId === 9 ? Style.ActiveItem : null}
          onClick={() => {
            setItemId(9);
            CloseSideBar();
          }}
        >
          <p>کیف پول من</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={
            itemId === 10 || itemId === 11 || itemId === 12
              ? Style.AccordionAvtive
              : Style.AccordionRegular
          }
        >
          <div
            className={Style.titlePart}
            onClick={() => {
              setItemId(10);
            }}
          >
            <p> تیکت {Notif?.count!==0?<span className="text-danger">*</span>:null}</p>
            <FontAwesomeIcon icon={itemId === 10 ? faAngleUp : faAngleDown} />
          </div>
          <div
            className={Style.AcardionBody}
            style={{
              display:
                itemId === 10 || itemId === 11 || itemId === 12
                  ? "block"
                  : "none",
            }}
          >
            <div
              onClick={() => {
                setItemId(11);
                CloseSideBar();
              }}
            >
              <p> ارسال تیکت</p>
              <FontAwesomeIcon icon={faTelegramPlane} />
            </div>
            <div
              onClick={() => {
                setItemId(12);
                CloseSideBar();
              }}
            >
              <p>لیست تیکت ها {Notif?.count!==0?<span className="text-danger">*</span>:null}</p>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
          </div>
        </li>
        <li className={Style.SignOut} onClick={SignOutHandler}>
          <p>خروج</p>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </li>
      </ul>
    </section>
  );
};

export default UserPanelSideBar;
