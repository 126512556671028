import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TahatorRequestForm from '../Forms/TahatorRequestForm/TahatorRequestForm'
import VisitTypeForm from '../Forms/VisitTypeForm/VisitTypeForm'
import Style from './PropertyContainer.module.scss'
import PropertyDetails from './PropertyDetails/PropertyDetails'
import PropertyMedia from './PropertyMedia/PropertyMedia'
import PropertyReserv from './PropertyReserv/PropertyReserv'

const PropertyContainer = ({openModalHandler,data,getUpdate}) => {
  const navigate=useNavigate()
  const {id}=useParams()
  const[showModal,setShowModal]=useState(false)
  const[showModal2,setShowModal2]=useState(false)
  const[showModal3,setShowModal3]=useState(false)
  const[tourinfo,setTourInfo]=useState()
  const[TahatorInfo,setTahatorInfo]=useState()
  const modalCancel=()=>{
    setShowModal(false)
    setShowModal2(false)
    setShowModal3(false)
  }
  const modalNextTourHandler=(info)=>{
    setShowModal2(true)
    setTourInfo(info)
  }
  const TahatorModalHandler=(value)=>{
    setTahatorInfo(value)
    setShowModal3(true)
  }
  
  return (
    <section className={Style.ContainerHolder}>
      <Modal className={'RTL'} title="نوع بازدید خود از زمین را مشخص کنید" visible={showModal} closable={false} footer={<Button onClick={modalCancel} type="dashed" danger>بستن</Button>}>
        <VisitTypeForm openModal={(e)=>modalNextTourHandler(e)} data={data}/>
      </Modal>
      <Modal className={'RTL'} title="اطلاعات تور بعدی" visible={showModal2} closable={false} footer={<Button onClick={modalCancel} type="dashed" danger>بستن</Button>}>
        <ul className={'d-flex flex-column'}>
          <li className='mb-2'>نام تور: <span style={{color:"#F9C326"}}>{tourinfo&&tourinfo.title}</span></li>
          <li className='mb-2'>تاریخ : <span style={{color:"#F9C326"}}>{tourinfo&&tourinfo.startDate}</span></li>
          <li className='mb-2'>تعداد جای باقی مانده :  <span style={{color:"#F9C326"}} >{tourinfo&&tourinfo.left}</span></li>
          <li><Button onClick={()=>navigate('/tour', { state: id })} style={{border:'none',background:'#F9C326' ,color:'#fff'}}> لیست تورها و ثبت نام</Button></li>
        </ul>
      </Modal>
      <Modal style={{top:10}} className={'RTL'} title="ملک خودرا برای تهاتر انتخاب کنید!" visible={showModal3} closable={false} footer={<Button onClick={modalCancel} type="dashed" danger>بستن</Button>}>
        <TahatorRequestForm data={TahatorInfo} closeModal={modalCancel}/>
      </Modal>
        <div className={Style.LeftPart}><PropertyReserv tahatorModal={TahatorModalHandler} visitModal={()=>setShowModal(true)} openModal={openModalHandler} data={data} getUpdate={getUpdate}/></div>
        <div className={Style.RightPart}>
            <PropertyMedia data={data&&data.estate}/>
            <PropertyDetails data={data&&data.estate}/>
        </div>
    </section>
  )
}

export default PropertyContainer