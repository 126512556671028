import { Alert, Checkbox, Input, Radio } from 'antd';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { setProjectVisitTypeAPI, setVisitTypeAPI } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Style from './ProjectVisitForm.module.scss'
const { TextArea } = Input;
const ProjectVisitForm = ({openModal,data}) => {
    console.log(data)
    const [value, setValue] = useState();
    const[loadingState,setLoadingState]=useState(false)
    const[checkedValue,setCheckeValue]=useState(false)
    const[desc,setDesc]=useState('')
    const {id}=useParams()
    const onChange = e => {
      setValue(e.target.value);
    };
    const onChangeCheckBox = e => {
        setCheckeValue(e.target.checked);
    };
    const SubmitVisitHandler=()=>{
        setLoadingState(true)
        if(value===undefined){
            errorToast('لطفا نوع بازدید رو مشخص کنید')
            setLoadingState(false)
        }
        else if(checkedValue===false)
        {
            errorToast('لطفا شرایط و قوانین را قبول کنید!')
            setLoadingState(false)
        }
        else{
            let info={
                VisitType:value,
                Code:id,
                Description:desc
            }
            setProjectVisitTypeAPI(info,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    setLoadingState(false)
                    if(res.data.data.visitType===0||res.data.data.visitType===0){
                        if(res.data.data.haspayment){
                            window.location.replace(res.data.data.payment.url);
                            setLoadingState(false)
                        }
                    }
                    else{
                        openModal(res.data.data.tour)
                        setLoadingState(false)
                    }
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        }
    }
  return (
    <form className={Style.FormHolder}>
        <LoadingPage show={loadingState}/>
        <Radio.Group onChange={onChange} value={value} className={Style.RadipListHolder}>
            {
                data.exclusiveVisitPrice===1?null:<Radio value={0}>به صورت اختصاصی برای خرید زمین حضور پیدا میکنم</Radio>
                
            }

        <Radio value={1}>همراه با تور بعدی برای خرید زمین حضور پیدا میکنم</Radio>
        {
            data.virtualVisitPrice===1?null:<Radio value={2}>تور مجازی</Radio>
        }
        </Radio.Group>
        {
            value===2?<Alert className={Style.AlertHolder}  message={
                <>
                <p style={{fontSize:'0.8rem'}}>حداکثر پنج ملک را می توانید برای بازدید در تور حضوری انتخاب بفرمایید</p>
                <p style={{fontSize:'0.8rem'}}>پرداخت مبلغ رزرو به معنای خرید قطعی ملک می باشد و مبلغ پرداخت شده قابل برگشت نمی باشد</p>
                </>
            
            } type="info" />:null
        }
        <TextArea value={desc} onChange={(e)=>setDesc(e.target.value)} rows={4} placeholder="توضیحات"  className="RTL"/>
        
        <Checkbox onChange={onChangeCheckBox} className={Style.CheckBoxHolder}>تمامی <Link to={'../khareji/termsandrules'}>قوانین و مقرارت</Link> سایت برانگیزان را خوانده و قبول میکنم</Checkbox>
        <Button variant='success' onClick={SubmitVisitHandler}>رزو</Button>
    </form>
  )
}

export default ProjectVisitForm