import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ItemInfoProvider } from "./Utils/Context/UserPanelItemIdContext";
import { UserInfoProvider } from "./Utils/Context/UserInfoContext";
import { ContactInfoProvider } from "./Utils/Context/ContactInfoContext";
import { ImageProvider } from "./Utils/Context/ImageContext";
import { FilterInfoProvider } from "./Utils/Context/SearchEngineContext";
import { ForeignFilterInfoProvider } from "./Utils/Context/ForeignSearchEngineContext";
import { ForeignBarterFilterInfoProvider } from "./Utils/Context/ForeingBarterFilterData";
import { DakheliBarterFilterInfoProvider } from "./Utils/Context/DakheliBarterFilterData.jsx";

ReactDOM.render(
  <React.StrictMode>
    <ItemInfoProvider>
      <UserInfoProvider>
        <ContactInfoProvider>
          <ImageProvider>
            <FilterInfoProvider>
              <ForeignFilterInfoProvider>
                <ForeignBarterFilterInfoProvider>
                  <DakheliBarterFilterInfoProvider>
                    <App />
                  </DakheliBarterFilterInfoProvider>
                </ForeignBarterFilterInfoProvider>
              </ForeignFilterInfoProvider>
            </FilterInfoProvider>
          </ImageProvider>
        </ContactInfoProvider>
      </UserInfoProvider>
    </ItemInfoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
