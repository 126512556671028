import React from 'react'
import Footer from '../../../Components/Footer/Footer'
import KharejiTourReigsterForm from '../../../Components/Forms/KharejiTourReigsterForm/KharejiTourReigsterForm'
import TourHeader from '../../../Components/SiteHeaders/TourHeader/TourHeader'

const KharejiTourRegister = () => {
  return (
    <div className='pageHolder'>
        <TourHeader/>
        <KharejiTourReigsterForm/>
        <Footer/>
    </div>
  )
}

export default KharejiTourRegister