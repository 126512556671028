
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { getForeignUSerPropertyListAPI } from '../../../Api/BarangizanServices'
import PropertyForeignUserPanelPerview from '../../PropertyContainer/PropertyForeignUserPanelPerview/PropertyForeignUserPanelPerview'
import {Modal,Button} from 'react-bootstrap'
import EditForeignPropertyEnglishPanel from '../../Forms/EditForeignPropertyEnglishPanel/EditForeignPropertyEnglishPanel'
const ForeignPropertyRegistredList = () => {
    const[propertyList,setPropertyList]=useState()
    const [show, setShow] = useState(false);
    const [propertyCode, setPropertyCode] = useState();
    useEffect(()=>{
        getPropertyListHandler()
    },[])
    const getPropertyListHandler=async()=>{
        const data=await getForeignUSerPropertyListAPI()
        setPropertyList(data.data.data)
    }
    const modalHandler=(code)=>{
        setShow(true)
        setPropertyCode(code)
    }
  return (
    <section className={'flexStyle bg-white h-100'}>
              <Modal show={show} className={"RTL"} size={"xl"}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "1rem" }}>Edit</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-around w-100">
          
            <EditForeignPropertyEnglishPanel
              code={propertyCode}
              closeModal={() => setShow(false)}
              getUpdate={() => getPropertyListHandler()}
            />
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)} variant={"danger"}>
            close
          </Button>
        </Modal.Footer>
      </Modal>
{propertyList===undefined?
<Spinner animation='border' variant='warning' className='mt-5'/>:
    propertyList.map((item,index)=>{
        return(
            <PropertyForeignUserPanelPerview key={index} data={item} getUpdate={()=>getPropertyListHandler()} openModal={(code)=>modalHandler(code)}/>
        )
    })
}
</section>
  )
}

export default ForeignPropertyRegistredList