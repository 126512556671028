const AuthTerms=`

کاربر با ثبت نام در برانگیزان می‌پذیرد که قوانین و مقررات را به صورت کامل مطالعه کرده و پذیرفته است.
این قوانین ممکن است در طول زمان تغییر کند، استفادۀ مستمر کاربران از سایت  به معنی پذیرش هرگونه تغییر در قوانین و مقررات آن است.
برای استفاده از برانگیزان لازم است که هر کاربر یک حساب کاربری در آن بسازد. 
تنها افرادی که دارای بیش از ۱۸ سال سن قانونی باشند می‌توانند از خدمات سرمایه گذاری برانگیزان استفاده نمایند.
کاربر می‌پذیرد اطلاعات خواسته شده را به صورت صحیح و به‌ روز در برانگیزان وارد کند.
مسئولیت همۀ فعالیت‌هایی که از طریق حساب کاربری اشخاص در برانگیزان یا سایر خدمات مربوط به آن انجام می‌شود به عهدۀ کاربر است.
مسئولیت حفظ امنیت اطلاعات حساب کاربری از جمله نام کاربری و رمز عبور به عهدۀ کاربر است. 
در صورت مفقود یا سرقت اطلاعات حساب کاربری، کاربر موظف است در اسرع وقت موضوع را به اطلاع شرکت برساند. بدیهی است تا زمانی که اطلاع‌رسانی به شرکت انجام نشده است مسئولیت همۀ فعالیت‌هایی که از طریق حساب کاربری مذکور انجام شده و می‌شود به عهدۀ کاربر خواهد بود.
در شرایط خاصی ممکن است از کاربر برای استفاده از سرویس، درخواست احراز هویت شود، در چنین شرایطی اگر کاربر اطلاعات کافی در اختیار شرکت قرار ندهد، شرکت می‌تواند حساب کاربری وی را مسدود کرده و از ارائۀ سرویس به کاربر خودداری کند. 
کاربر بخش سرمایه گذاری املاک برانگیزان می تواند مالک قانونی ملک، نماینده قانونی او، مشاور املاک معتمد مالک ملک مربوطه و یا خریدار املاک باشد؛از نظر برانگیزان هر آن کس که درب منزل را برای متصدیان برانگیزان باز می کند مالک یا نماینده مورد اعتماد مالک می باشد.
برانگیزان در شرایطی که به هر دلیل به این نتیجه برسد حضور یک کاربر میتواند برای شرکت یا سایر متصدیان و مخاطبان خطرناک باشد یا مطلوب نباشد حق حذف دسترسی کاربر مذکور به سایت برانگیزان را دارد.
مسئولیت حفظ و حراست از حساب کاربر برعهدۀ کاربر بوده و کاربر ضمن پذیرش این مسئولیت، همۀ داده پیام‌های صادره از طریق حساب کاربری مذکور را منتسب به خود دانسته و هرگونه ادعایی نسبت به انکار، تردید و جعل داده پیام‌های ارسال شده از ناحیۀ نامبرده غیر قابل استماع و مردود است.
کاربر می پذیرد هر گونه رویه، اشکال، عبارات مبین تائید و اعلام قبولی و تصدیق داده پیامهای صادره از طرف برانگیزان به منزله امضا الکترونیکی و قبولی محسوب میشود و هر گونه ادعائی نسبت به امضا و قبولی مذکور غیر قابل استماع و مردود است.
کاربر با عضویت در برانگیزان قصد و اراده خود را نسبت به پذیرش انعقاد هرگونه اقدام و عمل حقوقی از جمله عقود و معاملات از راه دور و از طریق سیستم رایانه‌ای و الکترونیکی اعلام میکند.
هر گونه کپی یا استفاده از مطالب وب سایت برانگیزان بدون اجازه کتبی تحت هر شرایطی ممنوع و پیگرد قانونی دارد.
هر گونه فعالیت در بخش سرمایه گذاری املاک وب سایت برانگیزان از جمله ورود به سایت، عضویت، ثبت درخواست رزرو ملک خارجی و داخلی یا خرید تور داخلی و خارجی، مجله ی املاک شمال و همچنین تمامی بخش های دیگر سایت برانگیزان به معنای آگاه بودن کاربر از قوانین آن بخش مربوطه و نحوه استفاده از خدمات آن  می باشد. 
حق هر گونه تجدید نظر و به روزآوری شرایط و قوانین و همچنین عملکرد و محتوای وب سایت، کارمزد خدمات، در هر زمان و بدون اطلاع قبلی به دلایلی همچون بهبود عملکرد، افزودن یک قابلیت و امکان جدید به خدمات و یا اجرای پیشنهادات کاربران برای برانگیزان محفوظ می باشد.
بخش سرمایه گذاری املاک برانگیزان یک بستر انتشار آگهی های ملکی در چهارچوب قوانین ملی و بین المللی می باشد. 
در بخش سرمایه گذاری املاک برانگیزان کاربران املاک  خود را معرفی و قیمت گذاری می نمایند و خریدار با آگاهی کامل از اینکه برانگیزان معتمد ایشان بوده و مالک نمی باشد اقدام به رزرو و پرداخت بصورت آنلاین می نماید و برانگیزان منجزا (بدون هیچ قید و شرطی) هیچ گونه مسئولیتی در قبال امور مالیاتی و حقوقی و مهاجرتی مربوط وامنیت و جرایم و پرداخت های جانبی نخواهد داشت.
شماره۰۹۹۲۶۲۰۲۲۸۷ به عنوان شماره ی مشاوره ی سرمایه گذاری داخلی و ۰۰۹۰۵۳۳۸۸۳۸۲۸۲ ، ۰۰۷۷۷۵۷۴۸۳۹۶۸ به عنوان شماره ی مشاوره ی سرمایه گذاری داخلی رسمی برانگیزان بوده و کلیه پیام های ارسالی از شماره های فوق به کاربران، یا هرگونه اطلاع رسانی از سوی برانگیزان از طریق تلفن، واتس اپ یا تلگرام به عنوان سند رسمی فرایند رزرو می باشد و میهمانان و میزبانان با فعالیت در این سایت حق هیچ گونه اعتراضی نسبت به اسناد فوق نخواهند داشت. 
لازم به ذکر است قوانین و مقررات مندرج در این صفحه از همان تاریخ به روز آوری و انتشار قابل اجراست و جایگزین کلیه توافق های قبلی محسوب می شود.
هر گونه اقدامی از سوی کاربران که منجر به ورود خسارت به شهرت تجاری، نام و اعتبار برانگیزان شود تخلف از شرایط قراردادی و قانونی تلقی و برانگیزان حق غیر فعال نمودن کاربر و اقدام قانونی علیه وی را جهت مطالبه خسارت و احقاق حقوق قانونی خود خواهد داشت.
کاربر توافق میکند جهت مبادلۀ آسان اطلاعات، همۀ تغییرات و الحاقیه‌های قوانین و مقررات حاضر اعم از تغییر و اضافه کردن شروط آن، ارسال اخطاریه و ابلاغیه‌های مربوط به بازدید ، از طریق سیستم رایانه‌ای برانگیزان بعمل آید و کاربر ضمن پذیرش اطلاع از شرط و روند اجرایی آن موافقت خود را نسبت به اعمال شرط مذکور اعلام میکند
`
export default AuthTerms