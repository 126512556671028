import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { separate } from '../../Utils/SeprateNumber/SeprateThreeNumber';
import Button from '../Button/Button';
import Style from './TourPerview.module.scss'
const TourPerview = ({item,btn}) => {
  const navigate=useNavigate()
  return(
    <section className={Style.ComponentHolder}>
        <img src={`https://api.barangizan.ir${item.image}`} alt="" onClick={()=>navigate(`/tour/${item.id}`)}/>
        <div className={Style.InfoHolder}><Link to={`/tour/${item.id}`}>{item.title}</Link> <span className={Style.Date}>{item.startDate}</span></div>
        <div className={Style.InfoHolder}><p className='m-0'>هزینه</p> <span>{separate(item.price)} تومان</span></div>
        <Button value={'ثبت نام'} classbtn={'submitBtn'} style={{display:btn==='none'?'none':null}} onClick={()=>navigate('/dakheli/tourregister')}/>
    </section>
  )
};

export default TourPerview;
