import React from 'react'
import Style from './Divider.module.scss'
const Divider = ({name,bgColor}) => {

    return (
        <div className={Style.Divider}>
            <span></span>
            <p style={bgColor&&{background:`${bgColor}`}}>{name}</p>
        </div>
    )
}

export default Divider
