import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { separate } from '../../Utils/SeprateNumber/SeprateThreeNumber'
import Style from './ProjectPerviewSlide.module.scss'
const ProjectPerviewSlide = ({info}) => {
    const navigate=useNavigate()
    return (
        <section className={Style.PropertySection}>
        <ul className={Style.PropertyInfo}>
            <li className={Style.ImageHolder} onClick={()=>navigate(`/propertyproject/${info.code}`)}><img src={`https://api.barangizan.ir/${info.imageUrl}`} alt="ajil" /></li>
            <li className={Style.PropertyName}><Link to={`/propertyproject/${info.code}`}>{info.title}</Link></li>
            <li className={Style.situation}>
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <p> {`${info.country} ${info.city}`}</p>
            </li>
            <li className={Style.info}>
                <h6>تعداد بلاک:</h6>
                <p>{info.blockCount} </p>
            </li>
            <li className={Style.info}>
                <h6>تعداد واحد:</h6>
                <p>{info.unitCount} </p>
            </li>
            <li className={Style.info}>
                <h6>شروع قیمت :</h6>
                <p style={{color:'red'}}>{separate(info.minPrice)} {info.currency}</p>
            </li>
            <li className={Style.info}>
                <h6>   حداکثر قیمت :</h6>
                <p style={{color:'green'}}>{separate(info.maxPrice)} {info.currency}</p>
            </li>
        </ul>
    </section>
    )
}

export default ProjectPerviewSlide
