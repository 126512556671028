import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { getTahatorRequestsAPI } from '../../../Api/BarangizanServices'
import TahatorRequestPerview from '../../TahatorRequestPerview/TahatorRequestPerview'

const UserPanelTahtorRequests = () => {
    const[RequestsList,setRequestList]=useState()
    useEffect(()=>{
        getTahtorStateHandler()
    },[])
    const getTahtorStateHandler=async()=>{
        const data=await getTahatorRequestsAPI()
        setRequestList(data.data.list)
    }
    
  return (
    <section>
        {
            RequestsList===undefined?<div className='w-100 text-center'><Spinner variant='warning' animation='border' className='my-3'/></div>:RequestsList.length===0?<p className='w-100 text-warnign text-center my-3'>شما درخواستی ثبت نکرده اید</p>:
            RequestsList.map((item,index)=>{
                return(
                    <TahatorRequestPerview key={index} data={item} count={index}/>
                )
            })
        }
    </section>
  )
}

export default UserPanelTahtorRequests