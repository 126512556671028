import React from "react";
import { useForm } from "react-hook-form";
import Style from "./MagazinePropertyRegisterForm.module.scss";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { Checkbox } from "antd";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  getUSerPropertyListAPI,
  MagazinAvailabelListAPI,
  MagazinListAPI,
  RegisterPropertyMagazineAPI,
} from "../../../Api/BarangizanServices";
import { useState } from "react";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
const MagazinePropertyRegisterForm = () => {
  const [propertyList, setPropertyList] = useState(null);
  const [MagazineList, setMagazineList] = useState(null);
  const [MagazineSelect, setMagazineSelect] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [priceShow, setPriceShow] = useState(false);
  const [frontPageSelect, setfrontPageSelect] = useState(false);
  const [backPageSelect, setbackPageSelect] = useState(false);
  const [midelPageSelect, setmidelPageSelect] = useState(false);
  const [terms, setTerms] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getDataHandler();
  }, []);
  const getDataHandler = async () => {
    const data = await getUSerPropertyListAPI();
    setPropertyList(data.data.data);
    const data2 = await MagazinAvailabelListAPI();
    setMagazineList(data2.data.list);
  };
  const HandelMagazinePrice = (e) => {
      let magazineSelect = MagazineList.find(
          (element) => element.id === parseInt(e.target.value)
          );
          if (magazineSelect.pricePerPage !== 1) {
              setPriceShow(true);
              setMagazineSelect(magazineSelect);
            }
        };
        const PageSelectHandler=(e)=>{
            setfrontPageSelect(e.includes('IwantFrontCover'))
            setbackPageSelect(e.includes('IwantBackCover'))
            setmidelPageSelect(e.includes('PageCount'))
        }
        
        const onSubmit = (data) => {
            if(terms){
                setLoadingPage(true)
            let formData=new FormData()
            formData.append('EstateCode',data.propertySelect)
            formData.append('MagazineId',data.magazineSelect)
            formData.append('IwantBackCover',backPageSelect)
            formData.append('IwantFrontCover',frontPageSelect)
            formData.append('PageCount',midelPageSelect?1:0)
            RegisterPropertyMagazineAPI(formData,(succ,res)=>{
                if(succ){
                    if(res.data.data.hasPayment){
                        succToast(res.data.message)
                        window.location.replace(res.data.data.payment.url);
                        setLoadingPage(false)
                    }
                    else{
                        succToast(res.data.message)
                        setLoadingPage(false)
                    }
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingPage(false)
                }
            })
            }
            else{
                errorToast('شرایط و قوانین را بپذیرید!')
            }
           
        };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <ul className={Style.itemsHolder}>
        <li>
          <label className={Style.inputName}>
            ملک موردنظر خودرا انتخاب کنید
            <span className={Style.redColor}>*</span>
          </label>
          <div className={Style.LocationHolder}>
            <select
              defaultValue={"-1"}
              name=""
              id=""
              style={{ width: "100%" }}
              {...register("propertySelect", { required: true })}
            >
              <option disabled value={"-1"}>
                {" "}
                ملک را مشخص کنید
              </option>
              {propertyList?.map((item, index) => {
                return item.code ? (
                  <option key={index} value={item.code}>
                    {item.title}
                  </option>
                ) : null;
              })}
            </select>
          </div>
        </li>
        <li>
          <label className={Style.inputName}>
            مجله موردنظر خودرا انتخاب کنید
            <span className={Style.redColor}>*</span>
          </label>
          <div className={Style.LocationHolder}>
            <select
              defaultValue={"-1"}
              name=""
              id=""
              style={{ width: "100%" }}
              {...register("magazineSelect", { required: true })}
              onChange={HandelMagazinePrice}
            >
              <option disabled value={"-1"}>
                {" "}
                مجله را مشخص کنید
              </option>
              {MagazineList?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </div>
        </li>

        {priceShow ? (
          <li className={Style.PriceHolder}>
            <p>
            <FontAwesomeIcon icon={faExclamationCircle} color='goldenrod'/>
                هزینه هر صفحه داخلی <span>{separate(MagazineSelect.pricePerPage)}</span>{" "}
              تومان
            </p>
            <p>
                <FontAwesomeIcon icon={faExclamationCircle} color='goldenrod'/>
               هزینه صفحه اول جلد 
              <span>{separate(MagazineSelect.frontCoverPrice)}</span> تومان
            </p>
            <p>
            <FontAwesomeIcon icon={faExclamationCircle} color='goldenrod'/>
               هزینه صفحه آخر جلد  
              <span>{separate(MagazineSelect.backCoverPrice)}</span> تومان
            </p>
          </li>
        ) : null}
        <li>
        <Checkbox.Group onChange={PageSelectHandler}>
            <Checkbox value="IwantBackCover" className="my-2">ثبت آگهی صفحه پشت جلد مجله</Checkbox>
            <Checkbox value="IwantFrontCover" className="my-2">ثبت آگهی صفحه اول جلد مجله</Checkbox>
            <Checkbox value="PageCount" className="my-2">ثبت آگهی در صفحات داخلی مجله</Checkbox>
        </Checkbox.Group>
        </li>
        <li>
        
          <Checkbox onChange={e=>setTerms(e.target.checked)}>
            تمامی <Link to={`../${window.location.pathname.split('/')[1]}/termsandrules`}>قوانین و مقرارت</Link> سایت
            برانگیزان را خوانده و قبول میکنم
          </Checkbox>
        </li>

        <li className={Style.btnHolder}>
          <Button value="ثبت" classbtn="submitBtn" style={{ width: "100%" }} />
        </li>
      </ul>
    </form>
  );
};

export default MagazinePropertyRegisterForm;
