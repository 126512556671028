import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkAcountTestAPI, getUserInfoApi } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
// import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import UserPanelContainer from '../../Components/UserPanelContainer/UserPanelContainer'
import { profile } from '../../Utils/Context/UserInfoContext'

const UserPanel = () => {
  const {setUserInfo}=useContext(profile)
  const navigate=useNavigate()
  useEffect(()=>{
    getUserProfileHandler()
    checkUserToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getUserProfileHandler=async()=>{
    const data=await getUserInfoApi()
    if(data[1].status===471){
      navigate('/changepass')
    }
    else{
      setUserInfo(data[1].data)
    }
  }
  const checkUserToken=()=>{
    checkAcountTestAPI((succ,res)=>{
      if(!succ){
        localStorage.removeItem('BarangizanToken')
      }
    })
  }
  return (
    <div className='pageHolder'>

        <AmlakDakheliHeaders/>
          <UserPanelContainer/>
        <Footer/>
    </div>
  )
}

export default UserPanel