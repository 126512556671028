import React from 'react'
import { useNavigate } from 'react-router-dom'
import Style from './EnglishTiketSideInfo.module.scss'
const EnglishTiketSideInfo = ({info}) => {
    const history=useNavigate()
    console.log(info)
  return (
    <ul className={Style.InfoListHolder}>
        <li>
            <h3>Tiket Information</h3>
        </li>
        <li>
            <span>Subject</span>
            <p>{info===undefined?'wait...':info.subject}</p>
        </li>
        <li>
            <span> Importance</span>
            <p>{info===undefined?'wait...':info.priorities===0?'Very Low':info.priorities===1?'Low':info.priorities===2?'Moderate':info.priorities===3?'High':'veryHigh'}</p>
        </li>
        <li>
            <span>Situation</span>
            <p>{info===undefined?'waite':info.ticketStatus===0?'Waiting':info.ticketStatus===1?"LastMessageByAdmin":info.ticketStatus===2?'LastMessageByStore':"Closed"}</p>
        </li>
        <li>
            <span>Tiket Date</span>
            <p>{info===undefined?'waite...':info.englishDateAdded}</p>
        </li>
        <li>
            <h5 onClick={()=>history(-1)}>back to panel</h5>
        </li>
    </ul>
  )
}

export default EnglishTiketSideInfo