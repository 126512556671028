import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Style from './EnglishAnswerTiketForm.module.scss'
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import { NewMessageTiketAPI } from '../../../Api/BarangizanServices';
import { LoadingOutlined } from '@ant-design/icons';

const EnglishAnswerTiketForm = ({info,getUpdate}) => {
    const[loadingState,setLoadingState]=useState()
    const {register,handleSubmit, reset,formState: { errors }} = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
        let formData=new FormData()

        formData.append("TicketId",info.id)
        formData.append("Message",data.Message)
        formData.append("File",data.File[0])

        NewMessageTiketAPI(formData,(succ,res)=>{
            if(succ){
                reset()
                succToast(res.data.message)
                setLoadingState(false)
                getUpdate()
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
    }
  return (
    <form  onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
    <ul className={Style.itemsHolder}>
        <li className={Style.TextAreaHolder}>
            <label className={Style.inputName}> Text <span className={Style.redColor}>*</span></label>
            <Input style={{textAlign:'left',direction:'ltr'}}  type='textarea'  {...register('Message',{ required: true })}  inputtype={errors.Message ? "valid" : "textArea"} />
        </li>
        <li>
            <label htmlFor="File" className={Style.FileLabel} style={{marginTop:'1rem'}}>
                <input type="file" name="" id="File" style={{display:'none'}}  {...register('File',{ required: false })}/>
                UploadFile
            </label>  
        </li>
        <li className={Style.btnHolder}>
            <Button type="submit" disabled={loadingState} value={loadingState?<LoadingOutlined />:'send'} classbtn='submitBtn' style={{width:'100%'}}/>
        </li>
        
    </ul>
</form>
  )
}

export default EnglishAnswerTiketForm