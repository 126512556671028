import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { getMagazinRequstsAPI } from '../../../Api/BarangizanServices'
import Style from './UserPanelMagazinRequest.module.scss'
const UserPanelMagazinRequest = () => {
    const[RequstState,setRequestState]=useState()
    useEffect(()=>{
        getRequestsListHandler()
    },[])
    const getRequestsListHandler=async()=>{
        const data=await getMagazinRequstsAPI()
        setRequestState(data)
    }
    console.log(RequstState)
  return (
    <>
        {
            RequstState===undefined?<div className='w-100 text-center my-3'><Spinner animation='border' variant='warning'/></div>:RequstState.length===0?
            <p>درخواستی یافت نشد</p>:RequstState.map((item,index)=>{
                return(
                    <section key={index} className={Style.SectionHolder}>
                        <h3>تاریخ درخواست: {item.date}</h3>
                        <div className={Style.info}>
                            <h6>نام ملک</h6>
                            <p>{item.estate.title}</p>
                            <h6>کد ملک</h6>
                            <p>{item.estate.code}</p>
                        </div>
                        <div className={Style.info}>
                            <h6>نام مجله</h6>
                            <p>{item.magazine.title}</p>
                            <h6>زمان انتشار مجله</h6>
                            <p>{item.magazine.publishDate}</p>
                        </div>
                        <div className={Style.info}>
                        <p> صفحه اول جلد مجله <FontAwesomeIcon icon={item.iwantFrontCover?faCheck:faTimes} color={item.iwantFrontCover?'green':'red'}/></p>
                        <p>صفحه آخر جلد مجله <FontAwesomeIcon icon={item.iwantBackCover?faCheck:faTimes} color={item.iwantBackCover?'green':'red'}/></p>
                        <p>صفحه داخلی مجله <FontAwesomeIcon icon={item.pageCount!==0?faCheck:faTimes} color={item.pageCount!==0?'green':'red'}/></p>
                        </div>

                    </section>
                )
            })
        }
    </>
  )
}

export default UserPanelMagazinRequest