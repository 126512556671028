import React from 'react'
import { useNavigate } from 'react-router-dom'
import Style from './TiketSideInfo.module.scss'
const TiketSideInfo = ({info}) => {
    const history=useNavigate()
  return (
    <ul className={Style.InfoListHolder}>
        <li>
            <h3>اطلاعات تیکت</h3>
        </li>
        <li>
            <span>موضوع</span>
            <p>{info===undefined?'درحال دریافت اطلاعات':info.subject}</p>
        </li>
        <li>
            <span>اهمیت تیکت</span>
            <p>{info===undefined?'درحال دریافت اطلاعات':info.ticketPriorities}</p>
        </li>
        <li>
            <span>وضعیت</span>
            <p>{info===undefined?'درحال دریافت اطلاعات':info.status}</p>
        </li>
        <li>
            <span> تاریخ شروع تیکت</span>
            <p>{info===undefined?'درحال دریافت اطلاعات':info.dateAdded}</p>
        </li>
        <li>
            <h5 onClick={()=>history(-1)}>بازگشت به پنل</h5>
        </li>
    </ul>
  )
}

export default TiketSideInfo