import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageDetailAPI } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import AmlakDakheliHeaders from '../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import NotFound from '../NotFound/NotFound'
import Style from './CustomePage.module.scss'
const CustomePage = () => {
    const {link}=useParams()
    const[pageDetail,setPageDetail]=useState()
    useEffect(()=>{
        getPageDetailHandler()
    },[])
    const param=window.location.pathname[1]==='dakheli' || window.location.pathname[1]==='khareji'?window.location.pathname[2]:window.location.pathname[1]
    
    const getPageDetailHandler=async()=>{
        const data=await pageDetailAPI(link)
        setPageDetail(data.data.page)
    }
    console.log(pageDetail)
  return (
    <>
    {
        pageDetail===null?<NotFound/>:<div className='pageHolder'>
            {window.location.pathname[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>}
            <h3 className={Style.TitlePage}>{pageDetail?.title}</h3>
            <div className={`${Style.contentPage} `} dangerouslySetInnerHTML={{__html: pageDetail?.text}}></div>
            <Footer/>
        </div>
    }

    </>

  )
}

export default CustomePage