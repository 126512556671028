import { Divider } from 'antd'
import React from 'react'
import Footer from '../../../Components/Footer/Footer'
import MagazineLists from '../../../Components/Lists/MagazineLists/MagazineLists'

import MagazineHeader from '../../../Components/SiteHeaders/MagazineHeader/MagazineHeader'
import Style from './MagazineList.module.scss'
const Magazine = () => {
    return (
        <div className='pageHolder'>
            <MagazineHeader/>
            <section className={Style.MagazineTopPage}>
                <h3>مجله املاک برانگیزان</h3>
                <div className={Style.FilterHolder}>
                    <select className={Style.SelectOptionHolder} name="" id="">
                        <option value="">سال</option>
                    </select>
                    <select className={Style.SelectOptionHolder} name="" id="">
                        <option value="">ماه</option>
                    </select>
                </div>
                
            </section>
            <Divider className={Style.divider}/>
            <MagazineLists/>
            <Footer/>
        </div>
    )
}

export default Magazine
