import {getAxios} from "./ApiInstance"

export const RegisterUserAPI=(info, callback)=>{
    getAxios().post("account/register",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RegisterForeignUserAPI=(info, callback)=>{
    getAxios().post("account/RegisterKhareji",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const VerifyAccountAPI=(info, callback)=>{
    getAxios().post("account/verify",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const VerifyForeignAccountAPI=(info, callback)=>{
    getAxios().post("account/VerifyKhareji",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const LoginUserAPI=(info, callback)=>{
    getAxios().post("account/login",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const VerifyCodeForgetPassword=(info, callback)=>{
    getAxios().post("account/VerifyForgetPassword",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const changePasswordAPI=(info, callback)=>{
    getAxios().post("account/ChangePassword",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const sendCodeForgetPassword=(info, callback)=>{
    getAxios().post("account/ForgetPassword",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const UserInfoUpdateAPI=(info, callback)=>{
    getAxios().post("account/UpdateProfile",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};



export const AddTofavoritAPI=(info, callback)=>{
    getAxios().post("Estate/AddToFavorite",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddUnitToFavoritAPI=(info, callback)=>{
    getAxios().post("unit/AddToFavorite",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const RemoveTofavoritAPI=(info, callback)=>{
    getAxios().post("Estate/RemoveFromFavorite",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const ContactUsAPI=(info, callback)=>{
    getAxios().post("general/CreateContactUsMessage",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RemoveUnitfavoritAPI=(info, callback)=>{
    getAxios().post("unit/RemoveFromFavorite",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const ResevePropertyAPI=(info, callback)=>{
    getAxios().post("Estate/Reserve",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const ReservUnitAPI=(info, callback)=>{
    getAxios().post("unit/Reserve",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};



export const setVisitTypeAPI=(info, callback)=>{
    getAxios().post("Estate/VisitEstate",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const setProjectVisitTypeAPI=(info, callback)=>{
    getAxios().post("project/VisitEstate",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const CharegeAccountAPI=(money, callback)=>{
    getAxios().post(`account/ChargeAccount?amount=${money}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const ShomalTourRegisterAPI=(info, callback)=>{
    getAxios().post("tours/TourRegistration",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CreateTahatorRequestAPI=(info, callback)=>{
    getAxios().post("tahator/CreateTahatorRequest",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};


export const CreateTiketAPI=(info, callback)=>{
    getAxios().post("ticket/CreateTicket",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const NewMessageTiketAPI=(info, callback)=>{
    getAxios().post("ticket/NewMessage",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const addPropertyUserAPI=(info, callback)=>{
    getAxios().post("estate/CreateEstateCreationRequest",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RegisterPropertyMagazineAPI=(info, callback)=>{
    getAxios().post("Magazine/SubmitMagazineRequest",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const AddForeignPropertyAPI=(info, callback)=>{
    getAxios().post("ForeignEstate/CreateEstateCreationRequest",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CooperationFormPersonAPI=(info, callback)=>{
    getAxios().post("CooperationRequest/SubmitByRealUser",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CooperationFormCompanyAPI=(info, callback)=>{
    getAxios().post("/CooperationRequest/SubmitByLegalUser",info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CancelRegistePropertyAPI=(info, callback)=>{
    getAxios().post(`estate/CancelMyEstate?code=${info}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const CancelRegisteForeignPropertyAPI=(info, callback)=>{
    getAxios().post(`ForeignEstate/CancelMyEstate?code=${info}`).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RequestEditPropertyAPI=(info, callback)=>{
    getAxios().post(`estate/EditMyEstate`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const RequestEditForeignPropertyAPI=(info, callback)=>{
    getAxios().post(`ForeignEstate/EditMyEstate`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const TahaorResponseAPI=(info, callback)=>{
    getAxios().post(`tahator/Respond`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const PayReceivedComissionAPI=(info, callback)=>{
    getAxios().post(`tahator/PayReceivedComission`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};
export const PayTahatorComissionAPI=(info, callback)=>{
    getAxios().post(`tahator/PayComission`,info).then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};

export const checkAcountTestAPI=( callback)=>{
    getAxios().get("account/test").then(response =>{   
        callback(true,response)
        }).catch(error => { 
            callback(false,error.response);
        })
};



export const pageDetailAPI=async (link) => {
    try{
        const result =await getAxios().get(`general/PageDetail?link=${link}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPageListAPI=async () => {
    try{
        const result =await getAxios().get("general/pages");
        return [result.status,result.data];
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getUserInfoApi=async () => {
    try{
        const result =await getAxios().get("account/GetProfile");
        return [result.status,result.data];
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyTypeAPI=async () => {
    try{
        const result =await getAxios().get("/Estate/EstateTypes");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyTypeAPI=async () => {
    try{
        const result =await getAxios().get("/ForeignEstate/EstateTypes");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};

export const getCountriesListAPI=async () => {
    try{
        const result =await getAxios().get("/ForeignEstate/countries");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignCitylistAPI=async (countryname) => {
    try{
        const result =await getAxios().get(`/ForeignEstate/Cities?countryname=${countryname}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPriceRangeAPI=async (countryname) => {
    try{
        const result =await getAxios().get(`/ForeignEstate/GetFilterData?countryname=${countryname}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getProvinceApi=async () => {
    try{
        const result =await getAxios().get("estate/provinces");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getMagazinRequstsAPI=async () => {
    try{
        const result =await getAxios().get("Magazine/Requests");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};

export const getCityApi=async (name) => {
    try{
        const result =await getAxios().get(`estate/Cities?provinceName=${name}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getPropertyDetailsAPI=async (code) => {
    try{
        const result =await getAxios().get(`estate/MyEstateDetail?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyDetailsAPI=async (code) => {
    try{
        const result =await getAxios().get(`ForeignEstate/MyEstateDetail?code=${code}`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};


export const getContactInfoAPI=async () => {
    try{
        const result =await getAxios().get("settings/GetData");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getTahatorRequestsAPI=async () => {
    try{
        const result =await getAxios().get("tahator/MyTahatorRequests");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getResivedTahatorRequestsAPI=async () => {
    try{
        const result =await getAxios().get("tahator/ReceivedTahatorRequests");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};


export const getUSerPropertyListAPI=async () => {
    try{
        const result =await getAxios().get("estate/MyEstates");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};
export const getForeignUSerPropertyListAPI=async () => {
    try{
        const result =await getAxios().get("ForeignEstate/MyEstates");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};


export const getStatikImageAPI=async () => {
    try{
        const result =await getAxios().get("settings/GetFrontEndFiles");
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};



export const getPropertiDakheliAPI=async () => {
    try{
        const result =await getAxios().get("Estate/GetDakheli");
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyAPI=async () => {
    try{
        const result =await getAxios().get("ForeignEstate/list");
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};


export const getPropertyInfoAPI=async (code) => {
    try{
        const result =await getAxios().get(`Estate/GetEstateDetail?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getUnitInfoAPI=async (code) => {
    try{
        const result =await getAxios().get(`unit/detail?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getForeignPropertyInfoAPI=async (code) => {
    try{
        const result =await getAxios().get(`ForeignEstate/GetEstateDetail?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};


export const getUserTahtorEstateAPI=async () => {
    try{
        const result =await getAxios().get(`tahator/MyTahatorEstates`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getPropertyResevedAPI=async () => {
    try{
        const result =await getAxios().get(`Estate/ReservedEstates`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getTourListAPI=async () => {
    try{
        const result =await getAxios().get(`tours/list`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const MagazinListAPI=async () => {
    try{
        const result =await getAxios().get(`Magazine/List`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const MagazinAvailabelListAPI=async () => {
    try{
        const result =await getAxios().get(`Magazine/AvailableForRequest`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getTourListForonePropertyAPI=async (code) => {
    try{
        const result =await getAxios().get(`tours/list?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getTourDetailsAPI=async (code) => {
    try{
        const result =await getAxios().get(`tours/GetTour?id=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getPropertyGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`estate/gallery?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getForeingPropertyGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`ForeignEstate/gallery?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getUnitGalleryAPI=async (code) => {
    try{
        const result =await getAxios().get(`unit/gallery?code=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getPaymentDetailAPI=async (code) => {
    try{
        const result =await getAxios().get(`payment/GetPaymentDetail?paymentId=${code}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const gerTransactionListAPI=async () => {
    try{
        const result =await getAxios().get(`account/Transactions`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getVisitPropertyChoice=async () => {
    try{
        const result =await getAxios().get(`Estate/VisitedEstates`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getTourRegisteredAPI=async () => {
    try{
        const result =await getAxios().get(`tours/GetRegisterdTours`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getTiketListAPI=async () => {
    try{
        const result =await getAxios().get(`ticket/List`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getMagazineDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`Magazine/Detail?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getProjectDetailAPI=async (id) => {
    try{
        const result =await getAxios().get(`Project/projectDetail?code=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getMagazineGalleryAPI=async (id) => {
    try{
        const result =await getAxios().get(`Magazine/Gallery?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getFilterDataAPI=async () => {
    try{
        const result =await getAxios().get(`estate/GetFilterData`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getFeaturesListOptionsAPI=async (type) => {
    try{
        const result =await getAxios().get(`/estate/GetAllWithOptions?featureType=${type}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getFavoritePropertyListAPI=async () => {
    try{
        const result =await getAxios().get(`/estate/Favorites`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getNotifDataAPI=async () => {
    try{
        const result =await getAxios().get(`/ticket/notifs`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getTiketChatListAPI=async (id) => {
    try{
        const result =await getAxios().get(`ticket/ViewTicket?id=${id}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getDakheliSearchAPI=async (pageNumber,info) => {
    try{
        const result =await getAxios().get(`estate/GetDakheli?pageNumber=${pageNumber}&pageCount=32&code=${info.code}&province=${info.province}&city=${info.city}&minArea=${info.minArea===""||info.minArea===undefined?0:info.minArea}&maxArea=${info.maxArea===""||info.maxArea===undefined?0:info.maxArea}&minPrice=${info.minPrice===undefined?0:info.minPrice}&maxPrice=${info.maxPrice===undefined?0:info.maxPrice}&estateType=${info.estateType}&sortby=${info.sortby===undefined?0:info.sortby}&isTavizi=${info.isTavizi===undefined?false:info.isTavizi}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};
export const getForeignSearchAPI=async (pageNumber,info) => {
    try{
        const result =await getAxios().get(`ForeignEstate/list?pageNumber=${pageNumber}&pageCount=32&code=${info.code}&country=${info.country}&city=${info.city}&minArea=${info.minArea===""||info.minArea===undefined?0:info.minArea}&maxArea=${info.maxArea===""||info.maxArea===undefined?0:info.maxArea}&minPrice=${info.minPrice===undefined?0:info.minPrice}&maxPrice=${info.maxPrice===undefined?0:info.maxPrice}&estateType=${info.estateType}&sortby=${info.sortby===undefined?0:info.sortby}&isTavizi=${info.isTavizi===undefined?false:info.isTavizi}&foreignEstateSellingType=${info.foreignEstateSellingType===undefined?3:info.foreignEstateSellingType}&isEjreyi=${info.isTavizi===undefined?false:info.isEjreyi}`);
        return result.data;
    }catch (error){
        return [error.response.status,error.response.data];
    }
};

export const getProjectListAPI=async (pageNumber) => {
    try{
        const result =await getAxios().get(`/Project/List?pageNumber=${pageNumber}&pageDataCount=10`);
        return result.data;
    }catch (error){
        
        return [error.response.status,error.response.data];
    }
};