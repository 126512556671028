import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendCodeForgetPassword, VerifyCodeForgetPassword } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage';
import Timer from '../../Timer/Timer';
import Style from './ConfirmCodeForgetPasswordForm.module.scss'
const ConfirmCodeForgetPasswordForm = () => {
    const {register,handleSubmit, formState: { errors } } = useForm();
    const {state}=useLocation()
    const history = useNavigate();
    const[resend,setResend]=useState(false)
    const[timer,setTimer]=useState('')
    const[loadingState,setLoadingState]=useState(false)
    useEffect(()=>{
      sendCodeHandler()
    },[])

    const sendCodeHandler=()=>{
      setLoadingState(true)
      sendCodeForgetPassword(state,(succ,res)=>{
        if(succ){
          setLoadingState(false)
          succToast('کد تایید برای شما ارسال شد!')
          setTimer(parseInt(res.data.data.totalSeconds))
          setResend(false)
          setTimeout(()=>setResend(true),parseInt(`${parseInt(res.data.data.totalSeconds)}000`))
        }
        else{
          setLoadingState(false)
          errorToast(res.data.errors[0])
          setTimeout(() => {
            history('/forgetpass')
          }, 2000);
        }
      })
    }

    const onSubmit = (data) => {
      setLoadingState(true)
      let info={
        phoneNumber:state.phoneNumber,
        code:data.code
      }
      VerifyCodeForgetPassword(info,(succ,res)=>{
        if(succ){
          setLoadingState(false)
          succToast(res.data.message)
          localStorage.setItem('BarangizanToken',res.data.data.token)
          setTimeout(()=>history('/changepass'),1000)
        }
        else{
          setLoadingState(false)
          errorToast(res.data.errors[0])
        }
      })
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
      <LoadingPage show={loadingState}/>
        <h4>
             کد تایید دریافتی را وارد کنید!
            <FontAwesomeIcon icon={faDoorOpen}/>
        </h4>

        <label className={Style.inputName}>کد تایید<span className={Style.redColor}>*</span></label>
        <Input autoComplete='current-code' type='number' {...register('code',{ required: true })} style={{textAlign:'center'}} inputtype={errors.code ? "valid" : "text"} />

        {resend?<p className={Style.resend} onClick={()=>sendCodeHandler()}>ارسال دوباره</p>:<Timer  Time={timer} />}
        <div className={Style.btnHolder}>
            <Button value='تایید' classbtn={'confrimBtn'}/>
        </div>
    </form>
  )
}

export default ConfirmCodeForgetPasswordForm