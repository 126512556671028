import React from 'react'
import SliderSkeleton from '../../Loading/Skeleton/SliderSkeleton/SliderSkeleton'
import ProjectPerviewSlide from '../../ProjectPerviewSlide/ProjectPerviewSlide'
import Style from './ProjectList.module.scss'
const ProjectList = ({ProjectListState}) => {
  return (
    <ul className={Style.ListHolder}>
    {
      ProjectListState===null?<SliderSkeleton/>:ProjectListState.map(item=>{
        return(
          <li  key={item.code}><ProjectPerviewSlide info={item}/></li>
        )
      })
    }
    </ul>
  )
}

export default ProjectList