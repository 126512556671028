import React from 'react'
import SendTiketsForm from '../../Forms/SendTiketsForm/SendTiketsForm'

const UserPanelSendTikets = () => {
  return (
    <div className='pageHolder'>
      <SendTiketsForm/>
    </div>
  )
}

export default UserPanelSendTikets