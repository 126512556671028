import React, { useState, useContext } from "react";

export const DakheliBarterFilterInfo = React.createContext(null);

export const UserFilterInfo = () => {
  const pc = useContext(DakheliBarterFilterInfo);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const DakheliBarterFilterInfoProvider= ({ children }) => {
  const [DakheliBarterFilterData, setDakheliBarterFilterData] = useState(JSON.parse(localStorage.getItem('Filters3')));
  const closeIt=()=>{
    localStorage.setItem('Filters3',JSON.stringify(DakheliBarterFilterData))
  } 
  window.onbeforeunload = closeIt;

  return (
    <DakheliBarterFilterInfo.Provider
      value={{
        DakheliBarterFilterData,
        setDakheliBarterFilterData: (value) => {
            setDakheliBarterFilterData(value);
        },
      }}
    >
      {children}
    </DakheliBarterFilterInfo.Provider>
  );
};

export { DakheliBarterFilterInfoProvider };
