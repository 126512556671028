import React, { useEffect } from 'react'

const ScrollToTap = ({children}) => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
  return (
    <>{children}</>
  )
}

export default ScrollToTap