import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getResivedTahatorRequestsAPI } from "../../../Api/BarangizanServices";
import TahatorResivedRequestPerview from "../../TahatorResivedRequestPerview/TahatorResivedRequestPerview";

const UserpanelTahatorResived = () => {
  const [RequestsList, setRequestList] = useState();
  useEffect(() => {
    getTahtorStateHandler();
  }, []);
  const getTahtorStateHandler = async () => {
    const data = await getResivedTahatorRequestsAPI();
    setRequestList(data.data.list);
  };

  return (
    <section>
      {RequestsList === undefined ? (
        <div className="w-100 text-center">
          <Spinner variant="warning" animation="border" className="my-3" />
        </div>
      ) : RequestsList.length === 0 ? (
        <p className="w-100 text-warnign text-center my-3">
          شما درخواستی دریافت نکرده اید
        </p>
      ) : (
        RequestsList.map((item, index) => {
          return (
            <TahatorResivedRequestPerview key={index} data={item} count={index}  updateData={()=>getTahtorStateHandler()}/>
            // <></>
          );
        })
      )}
    </section>
  );
};

export default UserpanelTahatorResived;
