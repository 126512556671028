import React from 'react';
import Style from './TourTabs.module.scss'

const TourTabs = ({tab,setProperty}) => {
  return(
      <section className={Style.ComponentHolder}>
          <div className={tab?Style.Active:null} onClick={()=>setProperty(true)}>تور املاک خارجی</div>
          <div className={tab?null:Style.Active} onClick={()=>setProperty(false)}>تور املاک شمال</div>
      </section>
  )
};

export default TourTabs;
