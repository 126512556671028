import { Skeleton } from 'antd'
import React from 'react'
import Style from './SkeltonHolder.module.scss'
const SliderSkeleton = () => {
  return (
    <section className={Style.SkeltonHolder}>
        <Skeleton.Image active={true} className="w-100"/>
        <Skeleton active={true} size={"large"} />
    </section>
  )
}

export default SliderSkeleton