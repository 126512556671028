import React from 'react';
import Footer from '../../../Components/Footer/Footer';
import ForgetPasswordForm from '../../../Components/Forms/ForgetPasswordForm/ForgetPasswordForm';
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import Style from './ForgetPassword.module.scss'
const ForgetPassword = () => {
  return(
    <div className={'pageHolder'}>
        <AmlakDakheliHeaders/>
        <section className={Style.ForgetpasswordSection}>
            <img src="/Assets/Images/Auth/Forgetpassword.svg" alt="Register" />
            <div className={Style.formHolder}><ForgetPasswordForm/></div>
        </section>
        <Footer/>

    </div>
  )
};

export default ForgetPassword;
