import React, { useContext } from 'react'
import { faAngleDown, faAngleUp, faBars, faEnvelope, faSignOutAlt, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profile } from '../../../Utils/Context/UserInfoContext';
import Style from './ForeignUserPanelSideBar.module.scss'
import { errorToast } from '../../../Utils/Toast/Toast';
import { Progress } from 'antd';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

const ForeignUserPanelSideBar = ({CloseSideBar}) => {
    let navigate = useNavigate();
    const {userInfo}=useContext(profile)
    const {part}=useParams()
    const SignOutHandler=()=>{
        localStorage.clear()
        errorToast('باموفقیت خارج شدید!')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
  return (
    <section className={Style.ComponentHolder}>
      <div className={Style.CloseSideBar} onClick={() => CloseSideBar()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={Style.UserInfo}>
        <h4>
          <FontAwesomeIcon icon={faUser} />
              {userInfo===undefined?'درحال دریافت اطلاعات': `${userInfo.firstName} ${userInfo.lastName}`}
             
        </h4>
        <p>
          <span>{userInfo===undefined?'درحال دریافت اطلاعات': userInfo.phoneNumber }</span>
          
        </p>
      </div>
      <ul className={Style.ProfileItems}>
        <li
          className={part === "profile" ? Style.ActiveItem : null}
          onClick={() => {
            navigate('/khareji/englishpanel/profile')
            CloseSideBar();
          }}
        >
          <p>Profile</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={part === "addpropert" ? Style.ActiveItem : null}
          onClick={() => {
            navigate('/khareji/englishpanel/addpropert')
            CloseSideBar();
          }}
        >
          <p>Add property</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={part === "propertylist" ? Style.ActiveItem : null}
          onClick={() => {
            navigate('/khareji/englishpanel/propertylist')
            CloseSideBar();
          }}
        >
          <p>Property List</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={part === "sendtiket" ? Style.ActiveItem : null}
          onClick={() => {
            navigate('/khareji/englishpanel/sendtiket')
            CloseSideBar();
          }}
        >
          <p>Send Tiket</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={part === "tiketlist" ? Style.ActiveItem : null}
          onClick={() => {
            navigate('/khareji/englishpanel/tiketlist')
            CloseSideBar();
          }}
        >
          <p>Tikets List</p>
          <FontAwesomeIcon icon={faBars} />
        </li>
        <li
          className={Style.SignOut}
          onClick={SignOutHandler}
        >
          <p>Exit</p>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </li>      
        
      </ul>
    </section>
  )
}

export default ForeignUserPanelSideBar