import { faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, Switch } from "antd";
import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DakheliBarterFilterInfo } from "../../../Utils/Context/DakheliBarterFilterData";
import DakheliBarterFilter from "../DakheliBarterFilter/DakheliBarterFilter";
import ResultList from "../ResultList/ResultList";
import Style from "./DakheliBarterResultContainer.module.scss";

const DakheliBarterResultContainer = ({
  data,
  openModalHandler,
  openModalHandler2,
  searchUpdate,
  updatePage,
}) => {
  const { DakheliBarterFilterData, setDakheliBarterFilterData } = useContext(DakheliBarterFilterInfo);
  const options = [
    { label: "ارزانترین", value: "LowestPrice" },
    { label: "گرانترین", value: "HighestPrice" },
    { label: "متراژ بالا", value: "HighestArea" },
    { label: "متراژ پایین", value: "LowestArea" },
  ];

  const SortHandler = async (e) => {
    if (e.target.value === "LowestPrice") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 0,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestPrice") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 1,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "HighestArea") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 2,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    } else if (e.target.value === "LowestArea") {
      let items = {
        estateType: DakheliBarterFilterData.estateType,
        code: DakheliBarterFilterData.code,
        province: DakheliBarterFilterData.province,
        city: DakheliBarterFilterData.city,
        minArea: DakheliBarterFilterData.minArea,
        maxArea: DakheliBarterFilterData.maxArea,
        minPrice: DakheliBarterFilterData.minPrice,
        maxPrice: DakheliBarterFilterData.maxPrice,
        sortby: 3,
        isTavizi: undefined,
      };
      setDakheliBarterFilterData(items);
      searchUpdate();
    }
  };
  return (
    <section className={Style.ContainerHolder}>
      <div className={Style.FilterIcon} onClick={() => openModalHandler2()}>
        <FontAwesomeIcon icon={faFilter} />
      </div>
      <div className={Style.FilterPart}>
        <DakheliBarterFilter searchUpdate={searchUpdate} />
      </div>
      <div className={Style.ListPart}>
        <p onClick={() => openModalHandler()} className={Style.SortItemBTN}>
          <FontAwesomeIcon icon={faSort} />
           مرتب سازی بر اساس
        </p>
        <div className={Style.SortItemHolder}>
          <p>
            مرتب سازی بر اساس  <FontAwesomeIcon icon={faSort} />
             : 
          </p>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={SortHandler}
            defaultValue={
              DakheliBarterFilterData.sortby === 0
                ? "LowestPrice"
                : DakheliBarterFilterData.sortby === 1
                ? "HighestPrice" 
                : DakheliBarterFilterData.sortby === 2
                ? "HighestArea" 
                : DakheliBarterFilterData.sortby === 3
                ? "LowestArea" 
                : null
            }
          />
        </div>
        <hr />
        {data === undefined ? (
          <div className="w-100 text-center mt-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : data.list.length === 0 ? (
          <p className="w-100 text-center">!موردی یافت نشد</p>
        ) : (
          <ResultList
            list={data.list}
            totalPosts={data.pagination.totalPages * 32}
            updatePage={updatePage}
          />
        )}
      </div>
    </section>
  );
};

export default DakheliBarterResultContainer;
