import { faBuilding, faHandshake, faMap } from '@fortawesome/free-regular-svg-icons'
import { faBars, faHammer, faHome, faList, faPhone, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { DakheliBarterFilterInfo } from '../../../Utils/Context/DakheliBarterFilterData'
import TopPartHeader from '../TopPartHeader/TopPartHeader'
import Style from './AmlakDakheliHeaders.module.scss'

const AmlakDakheliHeaders = () => {
    const {  setDakheliBarterFilterData } = useContext(DakheliBarterFilterInfo);
    const setFilter=()=>{
        setDakheliBarterFilterData({
            estateType:undefined,
            code:undefined,
            province:undefined,
            city:undefined,
            minArea:0,
            maxArea:0,
            minPrice:undefined,
            maxPrice:undefined,
            sortby: 0,
            isTavizi: true,
          })
          
          
    }

    return (
        <div className='pageHolder' style={{background:'#F2F2F2'}}>
            <TopPartHeader/>
            <Navbar collapseOnSelect expand="lg"  variant='light' className={Style.NavbarHolder}>
            <Container className={Style.NavbarContainer} >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand className={Style.navLogo}>
                <img className={Style.logoNavbar} src='/Assets/Images/Logo/logo.png' alt='برانگیزان'/>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav" className={Style.CollapseHolder}>

                
                <Nav  className={`${Style.NavbarContainer}`}>
                <NavDropdown title={<FontAwesomeIcon icon={faBars}/>} id="basic-nav-dropdown" className={Style.navCourse}>
                    <LinkContainer to={'/khareji'}><Nav.Link className={Style.navText}>  املاک خارجی</Nav.Link></LinkContainer>
                    <LinkContainer to={'/tour'}><Nav.Link className={Style.navText}> توراملاک</Nav.Link></LinkContainer>
                    <LinkContainer to={'/magazine'}><Nav.Link className={Style.navText}> مجله املاک</Nav.Link></LinkContainer>
                </NavDropdown>
                <LinkContainer to={'/'}><Nav.Link className={Style.navText}> <FontAwesomeIcon icon={faHome}/> خانه</Nav.Link></LinkContainer>
                <LinkContainer to={'/aboutus'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faList}/>  درباره ما </Nav.Link></LinkContainer>
                <LinkContainer to={`../${window.location.pathname.split('/')[1]}/contactus`}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faPhone}/>  تماس با ما </Nav.Link></LinkContainer>
                <LinkContainer to={'/dakheli/termsandrules'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faHammer}/> شرایط و قوانین </Nav.Link></LinkContainer>
                <LinkContainer onClick={setFilter} to={'/dakheli/dakhelibarter'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faSync}/>  تهاتر  </Nav.Link></LinkContainer>
                <LinkContainer to={'/dakheli/crequest'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faHandshake}/> درخواست همکاری </Nav.Link></LinkContainer>
                {/* <LinkContainer to={'/shop'}><Nav.Link className={Style.navText}><FontAwesomeIcon icon={faBuilding}/> فروش هتل </Nav.Link></LinkContainer> */}
                </Nav>
                
            </Navbar.Collapse>
            {/* <div className={Style.CreateAdvertising} onClick={()=>history("../dakheli/panel")}>
                ثبت آگهی
            </div> */}
            
            </Container>
        </Navbar>
        </div>
    )
}

export default AmlakDakheliHeaders
