import React from 'react'
import EnglishTiketContainerMessage from '../../Components/EnglishTiketContainer/EnglishTiketContainerMessage'
import Footer from '../../Components/Footer/Footer'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'


const UserPanelTiketMessage = () => {
  return (
    <div className='pageHolder '>
    <AmlakKharejiHeader/>
    <EnglishTiketContainerMessage/>
    <Footer/>
    </div>
  )
}

export default UserPanelTiketMessage