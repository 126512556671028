import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Style from './LandingSlider.module.scss'

import "swiper/css/navigation"
import SwiperCore, {
    Navigation
  } from 'swiper';
SwiperCore.use([Navigation]);
const LandingSlider = () => {
    return (
        <div className={Style.SliderHolder}>
            <Swiper navigation={true} className={Style.swiper}>
                <SwiperSlide className={Style.swiperSlide}><a href="/"><img src="/Assets/Images/Slider/1.JPG" alt="Building" /></a></SwiperSlide>
                <SwiperSlide className={Style.swiperSlide}><a href="/"><img src="/Assets/Images/Slider/1.JPG" alt="Building" /></a></SwiperSlide>
                <SwiperSlide className={Style.swiperSlide}><a href="/"><img src="/Assets/Images/Slider/1.JPG" alt="Building" /></a></SwiperSlide>
                <SwiperSlide className={Style.swiperSlide}><a href="/"><img src="/Assets/Images/Slider/1.JPG" alt="Building" /></a></SwiperSlide>
            </Swiper>
        </div>
    )
}

export default LandingSlider
