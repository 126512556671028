import React, { useState, useContext } from "react";

export const itemInfo = React.createContext(null);

export const UseItemInfo = () => {
  const pc = useContext(itemInfo);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const ItemInfoProvider= ({ children }) => {
  const [itemId, setItemId] = useState(parseInt(localStorage.getItem('idPanel')));
  const closeIt=()=>{
    localStorage.setItem('idPanel',itemId)
  } 
  window.onbeforeunload = closeIt;

  return (
    <itemInfo.Provider
      value={{
        itemId,
        setItemId: (value) => {
          setItemId(value);
        },
      }}
    >
      {children}
    </itemInfo.Provider>
  );
};

export { ItemInfoProvider };
