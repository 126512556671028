import React from 'react'
import Style from './LandingHeader.module.scss'
export const LandingHeader = () => {
    return (
        <div className={`${Style.HeaderHolder} w-100 bg-light d-flex flex-row justify-content-around align-items-center p-2 mb-3`}>
            <img className={Style.Logo} src="/Assets/Images/Logo/logo.png" alt="barangizan" />
            <p>به سایت مشاوره ی سرمایه گذاری ملکی <span>برانگیزان</span> خوش آمدید</p>
            <img className={Style.Logo} src="/Assets/Images/Logo/logo.png" alt="barangizan" />
        </div>
    )
}
