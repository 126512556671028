import React from 'react'
import Style from './PropertySelectPerview.module.scss'
const PropertySelectPerview = ({data}) => {
  return (
    <section className={Style.SectionHolder}>
        
        <div className={Style.ImageHolder}><img src={`https://api.barangizan.ir${data.image}`} alt="property" /></div>
        <ul className={Style.ListHolder}>
            <li>
                <h5>نام ملک</h5>
                <p>{data.title}</p>
            </li>
            <li>
                <h5>کد ملک</h5>
                <p>{data.code}</p>
            </li>
        </ul>
    </section>
  )
}

export default PropertySelectPerview