import React, { useContext, useEffect } from 'react'
import Footer from '../../Components/Footer/Footer'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import { useNavigate } from 'react-router-dom'
import { profile } from '../../Utils/Context/UserInfoContext'
import { checkAcountTestAPI, getUserInfoApi } from '../../Api/BarangizanServices'
import ForeignUserPanelContainer from '../../Components/ForeignUserPanelContainer/ForeignUserPanelContainer'

const EnglishUserPanel = () => {
    const {setUserInfo}=useContext(profile)
  const navigate=useNavigate()
  useEffect(()=>{
    getUserProfileHandler()
    checkUserToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getUserProfileHandler=async()=>{
    const data=await getUserInfoApi()
    if(data[1].status===471){
      navigate('/changepass')
    }
    else{
      setUserInfo(data[1].data)
    }
  }
  const checkUserToken=()=>{
    checkAcountTestAPI((succ,res)=>{
      if(!succ){
        localStorage.removeItem('BarangizanToken')
      }
    })
  }
  return (
    <div className='pageHolder'>
        <AmlakKharejiHeader/>
        <ForeignUserPanelContainer/>
        <Footer/>
    </div>
  )
}

export default EnglishUserPanel