import React, { useContext } from 'react'
import { profile } from '../../../Utils/Context/UserInfoContext'
import ProfileForm from '../../Forms/ProfileForm/ProfileForm'
const UserPanelProfile = () => {
  const {userInfo}=useContext(profile)
  return (
    <section className={'flexStyle bg-white'} >
      {
        userInfo&&<ProfileForm info={userInfo} />
      }
        
    </section>
  )
}

export default UserPanelProfile