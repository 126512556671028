import { faCalendarAlt, faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Style from './TopPageDetail.module.scss'
const TopPageDetail = ({title,date}) => {
  return(
      <section className={Style.ComponentHolder}>
          <p><FontAwesomeIcon icon={faClipboard}/> {title}</p>
          <p><FontAwesomeIcon icon={faCalendarAlt}/> {date}</p>
      </section>
  )
};

export default TopPageDetail;
