import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs } from "antd";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginUserAPI } from "../../../Api/BarangizanServices";
import { itemInfo } from "../../../Utils/Context/UserPanelItemIdContext";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
import Style from "./LoginForm.module.scss";

const { TabPane } = Tabs;

const LoginForm = () => {
  const { type } = useParams();
  const history = useNavigate();
  const { setItemId } = useContext(itemInfo);
  const [loadingState, setLoadingState] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoadingState(true);
    let info = {
      phoneNumber: data.phone,
      password: data.password,
    };
    LoginUserAPI(info, (succ, res) => {
      if (succ) {
        succToast(res.data.message);
        setLoadingState(false);
        setItemId(1);
        localStorage.setItem("BarangizanToken", res.data.data.token);
        localStorage.setItem("userType", res.data.data.userType);
        localStorage.setItem("isForeign", res.data.data.isForeign);
        if (res.data.data.userType === 1 && res.data.data.isForeign) {
          setTimeout(() => history(`../${type}/englishpanel/profile`), 2000);
        } else {
          setTimeout(() => history(`../${type}/panel`), 2000);
        }
      } else {
        errorToast(res.data.errors[0]);
        setLoadingState(false);
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder}>
      <LoadingPage show={loadingState} />

      <Tabs defaultActiveKey={type==='dakheli'?"2":"1"} type="card" size={"large"} centered>
        <TabPane tab="English" key="1">
          <h4>
            login
            <FontAwesomeIcon icon={faDoorOpen} />
          </h4>
          <label className={Style.inputNameEn}>
            {type === "dakheli" ? "phoneNumber" : "email"}{" "}
            <span className={Style.redColor}>*</span>
          </label>
          {type === "dakheli" ? (
            <Input
              type="tel"
              maxLength="11"
              {...register("phone", { required: true })}
              placeholder={"مثال:09XXXXXXXXX"}
              style={{ textAlign: "left" }}
              inputtype={errors.phone ? "valid" : "text"}
            />
          ) : (
            <Input
              type="mail"
              {...register("phone", { required: true })}
              inputtype={errors.phone ? "valid" : "text"}
              style={{ textAlign: "left" ,direction:'ltr'}}
            />
          )}

          <label className={Style.inputNameEn}>
            password <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("password", { required: true })}
            inputtype={errors.password ? "valid" : "text"}
            style={{ textAlign: "left" ,direction:'ltr' }}
          />
          <p
            onClick={() => history("/forgetpass")}
            style={{ textAlign: "right" }}
          >
            forgot password
          </p>
          <div className={Style.btnHolder}>
            <Button value="login" classbtn={"confrimBtn"} />
            <Button
              value=" Register"
              classbtn={"primary"}
              onClick={() => history(`../${type}/register`)}
            />
          </div>
        </TabPane>
        <TabPane tab="فارسی" key="2">
          <h4>
            ورود
            <FontAwesomeIcon icon={faDoorOpen} />
          </h4>
          <label className={Style.inputName}>
            {type === "dakheli" ? "شماره تلفن همراه" : "ایمیل"}{" "}
            <span className={Style.redColor}>*</span>
          </label>
          {type === "dakheli" ? (
            <Input
              type="tel"
              maxLength="11"
              {...register("phone", { required: true })}
              placeholder={"مثال:09XXXXXXXXX"}
              inputtype={errors.phone ? "valid" : "text"}
            />
          ) : (
            <Input
              type="mail"
              {...register("phone", { required: true })}
              inputtype={errors.phone ? "valid" : "text"}
            />
          )}

          <label className={Style.inputName}>
            رمزعبور <span className={Style.redColor}>*</span>
          </label>
          <Input
            type="password"
            {...register("password", { required: true })}
            inputtype={errors.password ? "valid" : "text"}
          />
          <p onClick={() => history("/forgetpass")}>فراموشی رمز عبور</p>
          <div className={Style.btnHolder}>
            <Button value="ورود" classbtn={"confrimBtn"} />
            <Button
              value=" صفحه ثبت نام"
              classbtn={"primary"}
              onClick={() => history(`../${type}/register`)}
            />
          </div>
        </TabPane>
      </Tabs>
    </form>
  );
};

export default LoginForm;
