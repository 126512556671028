import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../Button/Button';
import Input from '../../Input/Input'
import Style from '../ShomalTourRegisterForm/ShomalTourRegisterForm.module.scss'
const KharejiTourReigsterForm = () => {
    const {register,handleSubmit, formState: { errors } } = useForm();
    const[Maloliat,setMaloliat]=useState(false)
    const[PPlNumber,setPPlNumber]=useState(0)
    const runCallback = (cb) => {
        return cb();
      };
    const MaloliatHandler=(value)=>{
        if(value==='0'){
            setMaloliat(false)
        }
        else{
            setMaloliat(true)
        }
    }
    const onSubmit = (data) => {}
    
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.formHolder} style={{width:'95%'}}>
        <h4>
            فرم ثبت نام در تور   
            <FontAwesomeIcon icon={faHandshake}/>
        </h4>
        <div className={Style.TopInputs}>
            <div className={Style.FormCol}>
                <label className={Style.inputName}>نام <span className={Style.redColor}>*</span></label>
                <Input type='text' {...register('Name',{ required: true })}  inputtype={errors.Name ? "valid" : "text"} />
                 <label className={Style.inputName}> نام خانوادگی <span className={Style.redColor}>*</span></label>
                <Input type='text' {...register('lastName',{ required: true })}  inputtype={errors.lastName ? "valid" : "text"} />
                 <label className={Style.inputName}> کدملی <span className={Style.redColor}>*</span></label>
                <Input type='number' {...register('nationalCode',{ required: true })}  inputtype={errors.nationalCode ? "valid" : "text"} />
                 <label className={Style.inputName}> شماره شناسنامه <span className={Style.redColor}>*</span></label>
                <Input type='number' {...register('ShenasnameNumber',{ required: true })}  inputtype={errors.ShenasnameNumber ? "valid" : "text"} />
                <label className={Style.inputName}> شماره گذرنامه <span className={Style.redColor}>*</span></label>
                <Input placeholder='p12345678' type='text' {...register('passport',{ required: true })}  inputtype={errors.passport ? "valid" : "text"} />
                <label className={Style.inputName}> تاریخ صدور گذرنامه <span className={Style.redColor}>*</span></label>
                <Input type='date' {...register('IssueDatePassport',{ required: true })}  inputtype={errors.IssueDatePassport ? "valid" : "text"} />
                <label className={Style.inputName}> تاریخ انقضا گذرنامه <span className={Style.redColor}>*</span></label>
                <Input type='date' {...register('ExpireDatePassport',{ required: true })}  inputtype={errors.ExpireDatePassport ? "valid" : "text"} />
                <label className={Style.inputName}>ایمیل</label>
                <Input type='mail' {...register('email',{ required: false })}  inputtype={errors.email ? "valid" : "text"} />
                <label className={Style.inputName}>تلفن همراه <span className={Style.redColor}>*</span></label>
                <Input type='tel' placeholder='+9891111111111' {...register('ownerPhoneNumber',{ required: true })}  inputtype={errors.ownerPhoneNumber ? "valid" : "text"} />
                <label className={Style.inputName}>شماره واتساپ <span className={Style.redColor}>*</span></label>
                <Input type='tel' {...register('whatsappNumber',{ required: true })}  inputtype={errors.whatsappNumber ? "valid" : "text"} />
                <label className={Style.inputName}>گروه خونی</label>
                <Input type='text' {...register('BloodType',{ required: false })}  inputtype={errors.BloodType ? "valid" : "text"} />
                <div className={Style.LocationHolder} >
                    <select name="" id="" style={{width:'100%'}} onChange={(e)=>MaloliatHandler(e.target.value)}>
                        <option value="0">معلولیت جسمی ندارم</option>
                        <option value="1">معلولیت جسمی دارم</option>
                    </select>
                </div>
                {
                    Maloliat?<Input type='text' {...register('malilatText',{ required: true })}  inputtype={errors.malilatText ? "valid" : "text"}  placeholder='معلولیت خود را بنویسید'/>:null
                }
            </div>
            <div  className={`${Style.FormCol} ${Style.FormCol2}`}>
            <label className={Style.inputName}>تاریخ شرکت در تور  <span className={Style.redColor}>*</span></label>
                <div className={Style.LocationHolder} style={{marginTop:'0'}}>
                    <select name="" id="" style={{width:'100%'}}>
                        <option value=""> تاریخ شرکت در تور</option>
                    </select>
                </div>
                <label className={Style.inputName}> تعداد افراد شرکت کننده در تور <span className={Style.redColor}>*</span></label>
                <Input type='number' min="1" max="12" {...register('PPlNumber',{ required: true })}  inputtype={errors.PPlNumber ? "valid" : "text"} onChange={(e)=>setPPlNumber(e.target.value)}/>
                

                <label htmlFor="nationalCart1" className={Style.FileLabel} style={{marginTop:'1rem'}}>
                    <input type="file" name="" id="nationalCart1" style={{display:'none'}}/>
                    آپلود روي كارت ملي 
                </label>            
                <label htmlFor="nationalCart2" className={Style.FileLabel}>
                    <input type="file" name="" id="nationalCart2" style={{display:'none'}}/>
                    آپلود پشت كارت ملي
                </label>            
                <label htmlFor="shenasname" className={Style.FileLabel}>
                    <input type="file" name="" id="shenasname" style={{display:'none'}}/>
                    آپلود صفحه اول شناسنامه  
                </label>       
                <label htmlFor="passport" className={Style.FileLabel}>
                    <input type="file" name="" id="passport" style={{display:'none'}}/>
                    آپلود تصویر اول گذرنامه  
                </label>                 

            </div>
        </div>
        <ul className={Style.MembersInfo}>
        {
            runCallback(() => {
            const row = [];
            for (var i = 2; i <= parseInt(PPlNumber)+parseInt(1); i++) {
                row.push(
                             <li key={i} className={Style.EachMemberInfo}>
                                <Input type='text' {...register(`Name${i}`,{ required: true })}  inputtype={errors.Name ? "valid" : "text"} placeholder={ ` نام و نام خانوادگی شخص ${i}`} />
                                <Input type='number' {...register(`nationalCode${i}`,{ required: true })}  inputtype={errors.nationalCode ? "valid" : "text"}  placeholder={` کدملی شخص ${i}` }/>
                                <Input type='tel' placeholder='+9891111111111' {...register('ownerPhoneNumber',{ required: true })}  inputtype={errors.ownerPhoneNumber ? "valid" : "text"} />
                                <Input placeholder={ ` شماره گذرنامه ${i}`} type='text' {...register('passport',{ required: true })}  inputtype={errors.passport ? "valid" : "text"} />
                            </li>
                );
            }
            return row;
            })
        }   
        </ul>
        <div className={Style.ExplainHolder}>
            <label className={Style.inputName}>توضيحات لازم(در صورت بیماری خاص لطفا ذکر بفرمایید)</label>
            <Input type='texarea' {...register('explain',{ required: false })}  inputtype={errors.explain ? "valid" : "textArea"} />
        </div>
        <p className={Style.Warning}><span className={Style.redColor}>*</span>در صورت وجود نقص در اطلاعات وارد شده، مسئولیت عواقب آن بر عهده شما می باشد </p>
        <p className={Style.Warning}><span className={Style.redColor}>*</span>بعد از ارسال فرم و بررسی توسط کارشناس با شما تماس گرفته خواهد شد </p>
        <p className={Style.rules}><span className={Style.redColor}>شرایط و قوانین</span> مربوط به بازدید املاک خارجی را مطالعه کردم و موافقم</p>
        <Button value='ارسال فرم' classbtn='submitBtn' style={{width:'100%',margin:'1rem 0'}}/>        
    </form>
  )
};

export default KharejiTourReigsterForm;
