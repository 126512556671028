import { faBuilding, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Item from 'antd/lib/list/Item';
import React from 'react';
import { Link } from 'react-router-dom';
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber';
import Style from './PropertyPerviewResult.module.scss'
const PropertyPerviewResult = ({data}) => {
  return(
    <section className={Style.PropertySection}>
      <div className={Style.ImageHolder}>
      <img src={`https://api.barangizan.ir${data.image}`} alt={Item.title} />
      <p>{data.code}</p>
      </div>
      <h3>{data.title}</h3>
      <p className={Style.locationProperty}><FontAwesomeIcon icon={faMapMarkerAlt}/>{data.address}</p>
      {
        data.project&&<a href='/' className={Style.projectProperty}><FontAwesomeIcon icon={faBuilding}/>{data.project}</a>
      }
      <div className={Style.propertyDetail}>
        <p>متراژ</p>
        <span>{data.area} متر</span>
      </div>
      <div className={Style.propertyDetail}>
        <p>کدملک</p>
        <span>{data.code}</span>
      </div>
      <div className={Style.propertyDetail}>
        <p>قیمت: </p>
        <span className={Style.PriceProperty}> {separate(data.price===data.discountedPrice?data.price:data.discountedPrice)} {data.currency}</span>
      </div>
      <div className={Style.optionHolder}>
        <p className={Style.OptionsHolder}>{data.options}</p>
        <Link to={data.isUnit?`../unit/${data.code}`:`../${window.location.pathname.split('/')[1]}/property/${data.code}`} > مشاهده جزئیات و رزو </Link>
      </div>
    </section>
  );
};

export default PropertyPerviewResult;
