import React from 'react';
import TourPerview from '../../Perview/TourPerview';
import Style from './UserTourRegistredList.module.scss'
const UserTourRegistredList = ({data}) => {
  return (
    <ul className={Style.ListHolder}>
        {
            data.map(item=>{
                return(
                    <li key={item.id}>
                        <TourPerview item={item} btn={'none'} />
                    </li>
                )
            })
        }
    </ul>
  )
};

export default UserTourRegistredList;