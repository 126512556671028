import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LandingHeader } from '../../Components/SiteHeaders/LandingHeader/LandingHeader'
import LandingParts from '../../Components/LandingPart/LandingParts'
import LandingSlider from '../../Components/Sliders/LandingSlider/LandingSlider'
import Style from './Landing.module.scss'
import { plusImage, useImageDispatch, useImageState } from '../../Utils/Context/ImageContext'
import { getStatikImageAPI } from '../../Api/BarangizanServices'
const imgHolder=[
    {
        id:1,
        source:'/Assets/Images/Landing/4.png',
        name:'املاک داخلی',
        link:'/dakheli'
    },
    {
        id:2,
        source:'/Assets/Images/Landing/3.png',
        name:'املاک خارجی',
        link:'/khareji'
    },
    {
        id:3,
        source:'/Assets/Images/Landing/2.png',
        name:'توراملاک',
        link:'/tour'
    },
    {
        id:4,
        source:'/Assets/Images/Landing/1.png',
        name:'مجله املاک',
        link:'/magazine'
    }
]

const Landing = () => {
    const {Image}=useImageState()
    let imgSrc=[]
    for (let index = 0; index < 4; index++) {
        imgSrc.push({
            id:index+1,
            source:Image.length===0?null:Image.find(element => element.key ===imgHolder[index].name ).value,
            name:imgHolder[index].name,
            link:imgHolder[index].link
        })
    }
    return (
        <div className='pageHolder'>
            <LandingHeader/>
            <LandingSlider/>
            <ul className={Style.LandingPartHolder}>
                {imgSrc.map(item=>{
                    return(
                        <li className={Style.EachPart} key={item.id}><Link to={item.link}><LandingParts source={item.source} name={item.name}/></Link></li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Landing
