import React from 'react'
import BackDrop from '../BackDrop/BackDrop'
import modal from './Modal.module.css'

const Modal = (props) => {

    return (
        <div className={modal.pageHolder}>
            <BackDrop show={props.show} close={props.modalClose}/>
                <div className={modal.modal}
                style={{
                    transform : props.show ? `translateY(${props.translate}vh) `: 'translateY(-300vh)',
                    opacity : props.show ? '1' : '0',
                }}
                >
                {props.children}
            </div>
            
        </div>
    )
}
export default Modal
