import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Style from './MagazinePerviewSlide.module.scss'
const MagazinePerviewSlide = ({data}) => {
    const navigate=useNavigate()
    return (
        <section className={Style.PropertySection}>
        <ul className={Style.PropertyInfo}>
            <li className={Style.ImageHolder}><img src={`https://api.barangizan.ir${data.cover}`} alt="magazine" onClick={()=>navigate(`/magazine/${data.id}`)}/></li>
            <li className={Style.info}>
                <Link to={`/magazine/${data.id}`}> {data.title}</Link>
                <p> {data.month} <FontAwesomeIcon icon={faCalendar}/></p>
            </li>
        </ul>
    </section>
    )
}

export default MagazinePerviewSlide
