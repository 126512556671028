import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { separate } from '../../Utils/SeprateNumber/SeprateThreeNumber'
import Style from './PropertyPerviewSlide.module.scss'
const PropertyPerviewSlide = ({item}) => {
    return (
        <section className={Style.PropertySection}>
            {item.isSold?<div className={Style.OffLabel}>فروخته شد</div>:null}
        <ul className={Style.PropertyInfo}>
            <li className={Style.ImageHolder}><img src={`https://api.barangizan.ir${item.image}`} alt="property" /></li>
            <li className={Style.PropertyName}><Link to={`../${window.location.pathname.split('/')[1]}/property/${item.code}`}>{item.title}</Link></li>
            <li className={Style.situation}>
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <p>{item.address} </p>
            </li>
            {/* <li className={Style.situation}>
                <FontAwesomeIcon icon={faBuilding}/>
                <Link to={`/propertyproject/${item.code}`}>{item.title}</Link>
            </li> */}
            <li className={Style.info}>
                <h6>متراژ:</h6>
                <p>{item.area} مترمربع</p>
            </li>
            <li className={Style.info}>
                <h6>قیمت:</h6>
                {/* <p style={{color:'red'}}>{separate(80000)} تومان</p> */}
                {item.price===item.discountedPrice?<p style={{color:'green'}}>{separate(item.price)} {item.currency}</p>:<p style={{color:'green'}}><span style={{color:'red',textDecoration:'line-through 2px'}}>{separate(item.price)}</span>  {separate(item.discountedPrice)} {item.currency} </p>}
            </li>
        </ul>
    </section>
    )
}

export default PropertyPerviewSlide
