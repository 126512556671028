import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "antd/dist/antd.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  AmlakDakheli,
  AmlakKhareji,
  ChangePassowrd,
  ConfirmCode,
  ConfirmCodeForgetPassword,
  ContactUs,
  CooperationRequestCompany,
  CooperationRequestPerson,
  CustomePage,
  DakheliBarter,
  EnglishUserPanel,
  FailedPayment,
  ForeignBarter,
  ForgetPassword,
  KharejiTourReigster,
  Landing,
  Login,
  MagazineDetail,
  MagazineList,
  NotFound,
  Projects,
  Property,
  PropertyProject,
  Register,
  ResultKhareji,
  ResultsDakheli,
  ShomalTourRegister,
  SuccPayment,
  TermsAndRules,
  TourDetails,
  ToursListPage,
  Unit,
  UserPanel,
  UserPanelEnglishTiketMessage,
  UserPanelTiketMessage,
} from "./Pages";
import { plusImage, useImageDispatch } from "./Utils/Context/ImageContext";
import { getPageListAPI, getStatikImageAPI } from "./Api/BarangizanServices";

const isLogin =()=> !!localStorage.getItem("BarangizanToken");


function PrivateRoute({ children }) {
  return isLogin() ? children : <Navigate to={`../${window.location.pathname.split('/')[1]}/login`} />
}


function App() {
  const ImageDispatch = useImageDispatch();
  const[pages,setPages]=useState()
  useEffect(() => {
    getDataHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getDataHandler = async () => {
    const data = await getStatikImageAPI();
    plusImage(ImageDispatch, data.data.list);
    const data2=await getPageListAPI()
    setPages(data2.data.pages)
    
  };

  const PrivateRoutes = () => {
    return isLogin() ? <Outlet /> : <Navigate to={`../${window.location.pathname.split('/')[1]}/login`} />;
  };
  // const routeComponents = pages?.map((item,index) => <Route exact path={`/${item.link}`} element={<CustomePage/>} key={index} />);
  return (
    <Router>
      <Routes>
        {/* {
          routeComponents
        } */}
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/:link" element={<CustomePage />} />
        <Route exact path="/dakheli" element={<AmlakDakheli />} />
        <Route exact path="/khareji" element={<AmlakKhareji />} />
        <Route exact path="/magazine" element={<MagazineList />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/magazine/:id" element={<MagazineDetail />} />
        <Route exact path=":type/register" element={<Register />} />
        <Route exact path=":type/login" element={<Login />} />
        <Route exact path="/verifyphone" element={<ConfirmCodeForgetPassword />} />
        <Route exact path="/changepass" element={<ChangePassowrd />} />
        <Route exact path="/forgetpass" element={<ForgetPassword />} />
        <Route exact path="/dakheli/results" element={<ResultsDakheli />} />
        <Route exact path="/khareji/results" element={<ResultKhareji />} />
        <Route exact path="/tour" element={<ToursListPage />} />
        <Route exact path="/tour/:id" element={<TourDetails />} />
        <Route exact path="/dakheli/tourregister" element={<ShomalTourRegister />} />
        <Route exact path="/khareji/tourregister" element={<KharejiTourReigster />} />
        <Route exact path="/propertyproject/:id" element={<PropertyProject />} />
        <Route exact path=":type/property/:id" element={<Property />} />
        <Route exact path="unit/:id" element={<Unit />} />
        <Route exact path="/failedpayment/:id" element={<FailedPayment/>}/>
        <Route exact path="/succpayment/:id" element={<SuccPayment/>}/>
        <Route exact path=":type/confirmcode" element={<ConfirmCode />} />
        <Route exact path=":type/contactus" element={<ContactUs />} />
        <Route exact path=":type/termsandrules" element={<TermsAndRules />} />
        <Route exact path="/panel/message/:id" element={<UserPanelTiketMessage />} />
        <Route exact path="/panel/enmessage/:id" element={<UserPanelEnglishTiketMessage />} />
        <Route exact path="*" element={<NotFound />} />
        <Route exact path="/khareji/foreignbarter" element={<ForeignBarter />} />
        <Route exact path="/dakheli/dakhelibarter" element={<DakheliBarter />} />
        <Route
          path=":type/panel"
          element={
            <PrivateRoute>
              <UserPanel />
            </PrivateRoute>
          }
      />
       <Route element={<PrivateRoutes />}>
       <Route
          exact
          path="/khareji/englishpanel/:part"
          element={<EnglishUserPanel />}
        />
       <Route
          exact
          path="/:type/crequest"
          element={<CooperationRequestCompany />}
        />
        <Route
          exact
          path="/:type/crequestperson"
          element={<CooperationRequestPerson />}
        />


       </Route>
      </Routes>
      <ToastContainer limit={3} />
    </Router>
  );
}

export default App;
