import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Style from './SuccPaymentContainer.module.scss'
import {  useNavigate } from 'react-router-dom';
import { getPaymentDetailAPI } from '../../../Api/BarangizanServices';
import { useParams } from 'react-router-dom';
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber';
import {Spinner} from 'react-bootstrap'
const SuccPaymentContainer = () => {
  const {id}=useParams()
  const[paymentDetail,setPaymentDetail]=useState()
  useEffect(()=>{
    getPaymentDetailHandler()
  },[])
  const getPaymentDetailHandler=async()=>{
    const data=await getPaymentDetailAPI(id)
    setPaymentDetail(data.data.data)
  }
  const OpenPanelHandler=async()=>{
    if(localStorage.getItem('userType')==="1" && localStorage.getItem('isForeign')==='true'){
      navigate(`../khareji/englishpanel/profile`)
    }
    else if(localStorage.getItem('userType')==="0" && localStorage.getItem('isForeign')==='true'){
      navigate(`../khareji/panel`)
    }
    else{
      navigate(`../dakheli/panel`)
    }
  }

  const navigate = useNavigate();
  return (
    <div className={Style.ContainerHolder}>
        <img src="/Assets/Images/Payment/succ.PNG" alt="" />
        <h3>پرداخت انجام شد!</h3>
        {
          paymentDetail===undefined?<Spinner animation="border" variant="success" />:<ul className={Style.DetailTabel}>
          <li>
            <h5>مبلغ</h5>
            <p>{separate(paymentDetail.amount)}</p>
          </li>
          <li>
            <h5>تاریخ</h5>
            <p>{paymentDetail.date}</p>
          </li>
          {
            paymentDetail.descripiton&&          <li>
            <h5>توضیحات</h5>
            <p>{paymentDetail.descripiton}</p>
          </li>
          }
          <li>
            <h5>شماره پیگیری</h5>
            <p>{paymentDetail.trackingNumber}</p>
          </li>
        </ul>
        }
        <Button onClick={OpenPanelHandler} variant="succsess">بازگشت</Button>
    </div>
  )
}

export default SuccPaymentContainer