import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faMapPin, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'bootstrap';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { CancelRegisteForeignPropertyAPI, CancelRegistePropertyAPI } from '../../../Api/BarangizanServices';
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber'
import { errorToast, succToast } from '../../../Utils/Toast/Toast';
import LoadingPage from '../../Loading/LoadingPage/LoadingPage'
import Style from './PropertyForeignUserPanelPerview.module.scss'
const PropertyForeignUserPanelPerview = ({ data, getUpdate,openModal }) => {
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);
    const CancelPropertyHandler = () => {
        setLoadingState(true);
        CancelRegisteForeignPropertyAPI(data.estateCreationRequestCode, (succ, res) => {
          if (succ) {
            succToast("Done");
            setLoadingState(false);
            getUpdate();
          } else {
            errorToast(res.data.errors[0]);
            setLoadingState(false);
          }
        });
      };
      const paramProperty=localStorage.getItem('isForeign')==="true"?'khareji':"dakheli"
  return (
    <div className={Style.PropertyHolder}>
      <LoadingPage show={loadingState} />
      <img
        src={`https://api.barangizan.ir${data.image}`}
        alt="ملک"
        onClick={
          data.isAccepted ? () => navigate(`../${paramProperty}/property/${data.code}`) : null
        }
      />
      <ul className={Style.ListHolder}>
        <li>
          {data.title && (
            <Link to={`../${paramProperty}/property/${data.code}`}>{data.title}</Link>
          )}
          {
            <p className="text-danger">
              {data.status === 0
                ? "  waiting for confirmation!"
                : data.status === 2
                ? "failed"
                : data.status === 3
                ? " canceled"
                : null}
            </p>
          }
          {data.status === 2 || data.status === 3 || data.id !== 0 ? null : (
            <p
              className={Style.RemoveProperty}
              onClick={() => CancelPropertyHandler()}
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} />   cacnecl
            </p>
          )}
           { data.id !== 0 ? (
            <p
              className={Style.EditBTN}
              onClick={() => openModal(data.code)}
            >
              {" "}
              <FontAwesomeIcon icon={faEdit} />   Edit
            </p>
          ) : null}
        </li>
        <li>
          <p className={Style.Address}>
            <FontAwesomeIcon icon={faMapPin} /> {data.address}
          </p>
        </li>
        <li>
          <p>Meter</p>
          <span>{data.area}</span>
        </li>
        <li>
          <p>PropertyCode</p>
          <span style={{ color: "#FE5252" }}>
            {data.code === null ? "There is no Code for this Property Yet." : data.code}
          </span>
        </li>
        <li>
          <p>Price</p>
          <span style={{ color: "green" }}>
            {data.discountedPrice === undefined
              ? separate(data.price)
              : separate(
                  data.price === data.discountedPrice
                    ? data.price
                    : data.discountedPrice
                )}{" "}
            
          </span>
        </li>
        {data.status === 2 ? (
          <li>
            <Alert
              type="error"
              message={` ${data.rejectReason}`}
              showIcon
              className="w-100 mt-2"
            />
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default PropertyForeignUserPanelPerview