import { ArrowDownOutlined } from '@ant-design/icons'
import React from 'react'
import { Button } from 'react-bootstrap'
import Style from './EnglishMessageList.module.scss'
const EnglishMessageList = ({listInfo}) => {
    
  return (
    <ul className={Style.ListHolder}>
        {
            listInfo.slice(0).reverse().map(item=>{
                return(
                    <li key={item.id}>
                        <div className={item.isAdmin?Style.MessageDetailAdmin:Style.MessageDetailUser}>
                            <p>{item.isAdmin?"Admin":'User'}</p>
                            <span>{item.englishDateAdded}</span>
                        </div>
                        <div className={Style.MessageText}>
                            <p >{
                                item.message
                                }
                            </p>
                        </div>
                        {
                        item.file&&<div className='w-100 p-3'><Button variant='info' className='text-light'>Download File<ArrowDownOutlined /></Button></div>
                            
                        }
                    </li>
                )
            })
        }

    </ul>
  )
}

export default EnglishMessageList