import React, { useContext, useState } from 'react';
import KharejiFilter from '../KharejiFilter/KharejiFilter';
import Style from '../DakheliResultsContainer/DakheliResultsContainer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSort } from '@fortawesome/free-solid-svg-icons';
import { Radio, Switch } from "antd";
import ResultList from '../ResultList/ResultList';
import { ForeignFilterInfo } from '../../../Utils/Context/ForeignSearchEngineContext';
import { Spinner } from "react-bootstrap";

const KharejiResultContainer = ({openModalHandler,openModalHandler2,searchUpdate,updatePage,data}) => {
    const options = [
        { label: "ارزانترین", value: "LowestPrice" },
        { label: "گرانترین", value: "HighestPrice" },
        { label: "متراژ بالا", value: "HighestArea" },
        { label: "متراژ پایین", value: "LowestArea" },
      ];

      const { ForeignFilterData, setForeignFilterData } = useContext(ForeignFilterInfo);
      
      
    const onChangeHandler=(e)=>{
      if(e){
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:true,
          isEjreyi:ForeignFilterData.isEjreyi

        };
        setForeignFilterData(items);
        searchUpdate();
      }
      else{
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:false,
          isEjreyi:ForeignFilterData.isEjreyi
        };
        setForeignFilterData(items);
        searchUpdate();
      }
    }
          
    const onChangeEjareHandler=(e)=>{
      
      if(e){
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:ForeignFilterData.isTavizi,
          isEjreyi:true,
        };
        setForeignFilterData(items);
        searchUpdate();
      }
      else{
        let items = {
          estateType: ForeignFilterData.estateType,
          code: ForeignFilterData.code,
          country: ForeignFilterData.country,
          foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
          city: ForeignFilterData.city,
          minArea: ForeignFilterData.minArea,
          maxArea: ForeignFilterData.maxArea,
          minPrice: ForeignFilterData.minPrice,
          maxPrice: ForeignFilterData.maxPrice,
          sortby: ForeignFilterData.sortby,
          isTavizi:ForeignFilterData.isTavizi,
          isEjreyi:false,
        };
        setForeignFilterData(items);
        searchUpdate();
      }
    }
    
    const SortItembgColoHandler = async (e) => {
        if (e.target.value === "LowestPrice") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 0,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestPrice") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 1,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "HighestArea") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 2,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        } else if (e.target.value === "LowestArea") {
          let items = {
            estateType: ForeignFilterData.estateType,
            code: ForeignFilterData.code,
            country: ForeignFilterData.country,
            foreignEstateSellingType:ForeignFilterData.foreignEstateSellingType,
            city: ForeignFilterData.city,
            minArea: ForeignFilterData.minArea,
            maxArea: ForeignFilterData.maxArea,
            minPrice: ForeignFilterData.minPrice,
            maxPrice: ForeignFilterData.maxPrice,
            sortby: 3,
            isTavizi: ForeignFilterData.isTavizi,
            isEjreyi:ForeignFilterData.isEjreyi
          };
          setForeignFilterData(items);
          searchUpdate();
        }
      };



  return (
    <section className={Style.ContainerHolder}>
        <div className={Style.FilterIcon} onClick={()=>openModalHandler2()}><FontAwesomeIcon icon={faFilter}/></div>
        <div className={Style.FilterPart}><KharejiFilter updateSearch={()=>searchUpdate(1)}/></div>
        <div className={Style.ListPart}>
            <p onClick={()=>openModalHandler()} className={Style.SortItemBTN}><FontAwesomeIcon icon={faSort}/> مرتب سازی بر اساس</p>
            <div className={Style.SortItemHolder}>
          <p>
            مرتب سازی بر اساس  <FontAwesomeIcon icon={faSort} />
             : 
          </p>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={SortItembgColoHandler}
            defaultValue={
                ForeignFilterData.sortby === 0
                ? "LowestPrice"
                : ForeignFilterData.sortby === 1
                ? "HighestPrice" 
                : ForeignFilterData.sortby === 2
                ? "HighestArea" 
                : ForeignFilterData.sortby === 3
                ? "LowestArea" 
                : null
            }
          />
            <div className={Style.TahatorHolder}>
            <Switch onChange={onChangeHandler} /> تهاتر
          </div>
            <div className={Style.TahatorHolder}>
            <Switch onChange={onChangeEjareHandler} /> اجاره
          </div>
          
        </div>
        <hr />
        {data === undefined ? (
          <div className="w-100 text-center mt-5">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : data.list.length === 0 ? (
          <p className="w-100 text-center">!موردی یافت نشد</p>
        ) : (
          <ResultList
            list={data.list}
            totalPosts={data.pagination.totalPages * 32}
            updatePage={updatePage}
          />
        )}
        </div>
    </section>
  )
};

export default KharejiResultContainer;
