import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Style from './NotFound.module.scss'
const NotFound = () => {
    const navigate=useNavigate()
  return (
    <div className={Style.PageHolder}>
        <div className={Style.noise}></div>
        <div className={Style.overlay}></div>
        <div className={Style.terminal}>
        <h1>ارور <span className={Style.errorcode}>404</span></h1>
        <p className={Style.output}>صفحه موردنظر شما یافت نشده یا از دسترس خارج شده است!</p>
        <p className={Style.output}> به  <sapn style={{cursor:'pointer'}} className="text-white" onClick={()=>navigate(-1)}>[صفحه قبل]</sapn> یا <Link to="/">صفحه خانه برگردید</Link>.</p>
        <p className={Style.output}>موفق باشید</p>
        </div>
    </div>
  )
}

export default NotFound