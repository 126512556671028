import React from 'react';
import CooperationFormHolder from '../../../Components/CooperationRequest/CooperationFormHolder/CooperationFormHolder';
import CooperationTopPart from '../../../Components/CooperationRequest/CooperationTopPart/CooperationTopPart';
import Footer from '../../../Components/Footer/Footer';
import AmlakDakheliHeaders from '../../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders';
import AmlakKharejiHeader from '../../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader';
const CooperationRequest = () => {
  return (
    <div className={'pageHolder'}>
        {
          window.location.pathname.split('/')[1]==='khareji'?<AmlakKharejiHeader/>:<AmlakDakheliHeaders/>
        }
        <CooperationTopPart/>
        <CooperationFormHolder/>
        <Footer/>
    </div>
  );
};

export default CooperationRequest;
