import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Style from './SendTiketsForm.module.scss'
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { Spinner } from 'react-bootstrap';
import { CreateTiketAPI } from '../../../Api/BarangizanServices';
import { errorToast, succToast } from '../../../Utils/Toast/Toast';

const SendTiketsForm = () => {
    const[loadingState,setLoadingState]=useState()
    const {register,handleSubmit, reset,formState: { errors }} = useForm();
    const onSubmit = (data) => {
        setLoadingState(true)
        let formData=new FormData()
        formData.append("Subject",data.Subject)
        formData.append("TicketPriorities",data.TicketPriorities)
        formData.append("Message",data.Message)
        formData.append("File",data.File[0])
        CreateTiketAPI(formData,(succ,res)=>{
            if(succ){
                reset()
                succToast(res.data.message)
                setLoadingState(false)
            }
            else{
                errorToast(res.data.errors[0])
                setLoadingState(false)
            }
        })
    }
  return (
    <form  onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
        <ul className={Style.itemsHolder}>
            <li>
                <label className={Style.inputName}>موضوع تیکت<span className={Style.redColor}>*</span></label>
                <Input {...register('Subject',{ required: true })}  inputtype={errors.Subject ? "valid" : "text"} />
            </li>
            <li>
                <label className={Style.inputName}>اهمیت تیکت<span className={Style.redColor}>*</span></label>
                <div className={Style.LocationHolder} style={{marginTop:'0'}}>
                    <select defaultValue={"0"} name="" id="" style={{width:'100%'}} {...register('TicketPriorities',{ required: true })}>
                        <option  value="0">خیلی کم</option>
                        <option value="1">کم</option>
                        <option value="2">متوسط</option>
                        <option value="3">بالا</option>
                        <option value="4">خیلی بالا</option>
                    </select>
                </div>
            </li>
            <li className={Style.TextAreaHolder}>
                <label className={Style.inputName}>متن تیکت <span className={Style.redColor}>*</span></label>
                <Input   type='textarea'  {...register('Message',{ required: true })}  inputtype={errors.Message ? "valid" : "textArea"} />
            </li>
            <li>
                <label htmlFor="File" className={Style.FileLabel} style={{marginTop:'1rem'}}>
                    <input type="file" name="" id="File" style={{display:'none'}}  {...register('File',{ required: false })}/>
                    آپلود فایل
                </label>  
            </li>
            <li className={Style.btnHolder}>
                <Button type="submit" disabled={loadingState} value={loadingState?<Spinner animation='border'/>:'ثبت'} classbtn='submitBtn' style={{width:'100%'}}/>
            </li>
            
        </ul>
    </form>
  )
}

export default SendTiketsForm