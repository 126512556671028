import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {  AddUnitToFavoritAPI,RemoveUnitfavoritAPI,ReservUnitAPI,ResevePropertyAPI } from '../../../Api/BarangizanServices'
import { separate } from '../../../Utils/SeprateNumber/SeprateThreeNumber'
import { errorToast, succToast } from '../../../Utils/Toast/Toast'
import LoadingPage from '../../Loading/LoadingPage/LoadingPage'
import Style from './UnitReserv.module.scss'
const UnitReserv = ({openModal,data,getUpdate,visitModal}) => {
    const[loadingState,setLoadingState]=useState(false)
    const isLogin =()=> !!localStorage.getItem("BarangizanToken");
    const navigate=useNavigate()
    const AddTofavoritHandler=()=>{
        let info={
            code:data.project.code
        }
        if(isLogin()){
            if(data.isInUsersFavorites){
                RemoveUnitfavoritAPI(info,(succ,res)=>{
                    if(succ){
                        succToast(res.data.message)
                        getUpdate()
                    }
                    else{
                        errorToast(res.data.errors[0])
                    }
                })
    
            }else{
                AddUnitToFavoritAPI(info,(succ,res)=>{
                    if(succ){
                        succToast(res.data.message)
                        getUpdate()
                    }
                    else{
                        errorToast(res.data.message)
                    }
                })
            }
        }
        else{
            navigate('../khareji/panel')
        }

    }

    const ResevedPropertyHandler=()=>{
        if(isLogin()){
            setLoadingState(true)
            let info={
                code:data.project.code
            }
            ReservUnitAPI(info,(succ,res)=>{
                if(succ){
                    succToast(res.data.message)
                    setLoadingState(false)
                    window.location.replace(res.data.data.payment.url);
                }
                else{
                    errorToast(res.data.errors[0])
                    setLoadingState(false)
                }
            })
        }
        else{
            navigate('../khareji/panel')
        }
    }
  return (
    <section className={Style.SectionHolder}>
        <LoadingPage show={loadingState}/>
        <img src="/Assets/Images/Property/property.svg" alt="ملک" />
        <ul className={Style.InfoHolder}>
            <li className={Style.PropertyCode}>
                <p>کدملک</p>
                <span>{data!==undefined?data.project.code:'درحال دریافت'}</span>
            </li>
            <li >
                <p>متراژ</p>
                <span>{data!==undefined?data.project.area:'درحال دریافت'}</span>
            </li>
            <li >
                <p>بلوک</p>
                <span>{data!==undefined?data.project.blockName:'درحال دریافت'}</span>
            </li>
            <li >
                <p>طبقه</p>
                <span>{data!==undefined?data.project.floor:'درحال دریافت'}</span>
            </li>
            <li className={Style.PriceHolder}>
                <p>مبلغ کل</p>
                <span>{data!==undefined?separate(data.project.price===data.project.discountedPrice?data.project.price:data.project.discountedPrice):'درحال دریافت'} {data&&data.project.currency}</span>
            </li>
            <li className={Style.PriceHolder}>
                <p>مبلغ برای رزرو</p>
                <span>{data!==undefined?separate(data.project.prePaymentPrice):'درحال دریافت'} تومان</span>
            </li>
            <li className={Style.ModalGallery} onClick={()=>openModal()}>
                <p>گالری تصاویر</p>
            </li>
            <li className={Style.ModalFavorit}  onClick={AddTofavoritHandler}>
                {data&&data.isInUsersFavorites?<p>حذف از لیست منتخب</p>:<p>منتخب برای بازدید </p>}
                
            </li>
            {
                data&&data.isEstateReserved?<li className={Style.ModalReserv} >
                    <p>این ملک رزرو شده است! </p>
                </li>:<li className={Style.ModalReserv} onClick={()=>ResevedPropertyHandler()}>
                    <p>رزرو </p>
                </li>
            }
            
            <li className={Style.ModalBuy}>
                <p>درخواست خرید آنلاین </p>
            </li>
        </ul>
    </section>
  )
}

export default UnitReserv