import React from 'react'
import ForeignSendtiketForm from '../../Forms/ForeignSendtiketForm/ForeignSendtiketForm';

const ForeignTikets = () => {

  return (
    <div className='pageHolder'>
        <ForeignSendtiketForm/>
    </div>
  )
}

export default ForeignTikets