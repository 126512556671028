import React, { useContext, useEffect } from "react";
import Style from "../AmlakDakheliSearchContainer/AmlakDakheliSearchContainer.module.scss";
import { Alert, Input, Select } from "antd";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import {
  getCountriesListAPI,
  getForeignCitylistAPI,
  getForeignPropertyTypeAPI,
  getForeignPriceRangeAPI,
} from "../../../Api/BarangizanServices";

import { useState } from "react";
import { separate } from "../../../Utils/SeprateNumber/SeprateThreeNumber";
import { ForeignFilterInfo } from "../../../Utils/Context/ForeignSearchEngineContext";
const { Option } = Select;

const AmlakKharejiSearchContainer = () => {
  const{setForeignFilterData}=useContext(ForeignFilterInfo)
  const [propertyTypeList, setPropertyTypeList] = useState();
  const [propertyTypeList2, setPropertyTypeList2] = useState();
  const [CountriesList, setCountriesList] = useState();
  const [CountriesList2, setCountriesList2] = useState();
  const [CitiesList, setCitiesList] = useState();
  const [CitiesSelect, setCitiesSelect] = useState();
  const [countriesSelect, setCountriesSelect] = useState();
  const [ForeignPropertyCode, setForeignPropertyCode] = useState();
  const [minArea, setMinArea] = useState();
  const [MaxArea, setMaxArea] = useState();
  const [SalesType, setSalesType] = useState();
  const [priceRangeState, setPriceRangeState] = useState();
  const [priceRangeSelect, setPriceRangeSelect] = useState();
  const [propertyTypeSelect, SetPropertyTypeSelect] = useState();
  useEffect(() => {
    getDataHandler();
  }, []);
  const getDataHandler = async () => {
    const countries = await getCountriesListAPI();
    setCountriesList(countries.data.list);
    const propertyType = await getForeignPropertyTypeAPI();
    setPropertyTypeList(propertyType.data.list);
  };

  const getCityListHandler = async (info) => {
    if (info.includes(1)) {
      CountryList = [];
      CountryList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      setCountriesList2(CountryList);
    } else if (info.length === 0) {
      CountryList = [];
      CountryList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      CountriesList &&
        CountriesList.forEach((element) => {
          CountryList.push(
            <Option
              style={{ textAlign: "right" }}
              key={element.title}
              value={element.title}
            >
              {element.title}
            </Option>
          );
        });
      setCountriesList2(CountryList);
    }
    setCountriesSelect(info);
    const cities = await getForeignCitylistAPI(info);
    setCitiesList(cities.data.list);
    const priceRange = await getForeignPriceRangeAPI(info[0]);
    setPriceRangeState(priceRange);
  };
  const propertyTypeChangeHandler = (info) => {
    if (info.includes(1)) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      setPropertyTypeList2(propertyTypeArrayList);
    } else if (info.length === 0) {
      let propertyTypeArrayList = [];
      propertyTypeArrayList.push(
        <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
          {"همه"}
        </Option>
      );
      propertyTypeList &&
        propertyTypeList.forEach((element) => {
          propertyTypeArrayList.push(
            <Option
              style={{ textAlign: "right" }}
              key={element.title}
              value={element.title}
            >
              {element.title}
            </Option>
          );
        });
      setPropertyTypeList2(propertyTypeArrayList);
    }
    SetPropertyTypeSelect(info);
  };
  const HandleChangeCity = (info) => {
    setCitiesSelect(info);
  };
  let CountryList = [];
  CountryList.push(
    <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
      {"همه"}
    </Option>
  );
  CountriesList &&
    CountriesList.forEach((element) => {
      CountryList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.title}
        >
          {element.title}
        </Option>
      );
    });
  let propertyTypeArrayList = [];
  propertyTypeArrayList.push(
    <Option style={{ textAlign: "right" }} key={"همه"} value={1}>
      {"همه"}
    </Option>
  );
  propertyTypeList &&
    propertyTypeList.forEach((element) => {
      propertyTypeArrayList.push(
        <Option
          style={{ textAlign: "right" }}
          key={element.title}
          value={element.id}
        >
          {element.title}
        </Option>
      );
    });

  const rangeNumber =
    priceRangeState &&
    Math.ceil((priceRangeState.maxPrice - priceRangeState.minPrice) / 5);
  let rangeArray = [];
  let minArray = priceRangeState && priceRangeState.minPrice;
  for (let index = 1; index <= 5; index++) {
    rangeArray.push(
      `${separate(minArray)}-${separate(
        index === 5
          ? priceRangeState && priceRangeState.maxPrice
          : rangeNumber * index
      )}`
    );
    minArray = rangeNumber * index + 1;
  }
  const getListData = () => {
    let items = {
      estateType: propertyTypeSelect && propertyTypeSelect.toString(),
      code: ForeignPropertyCode,
      country: countriesSelect && countriesSelect.toString(),
      city: CitiesSelect && CitiesSelect.toString(),
      minArea: minArea,
      maxArea: MaxArea,
      foreignEstateSellingType: SalesType,
      minPrice: priceRangeSelect&&priceRangeSelect.split('-')[0].replaceAll(',',''),
      maxPrice: priceRangeSelect&&priceRangeSelect.split('-')[1].replaceAll(',',''),
      sortby:0,
      isEjreyi: undefined,
      isTavizi: undefined,
    };
    history('/khareji/results');
    setForeignFilterData(items)
    
  };

  const history = useNavigate();
  return (
    <div className={`flexStyle ${Style.ContainerHolder}`}>
      <img
        className={Style.ContainerBG}
        src="/Assets/Images/SearchContent/1.png"
        alt="خرید ملک"
      />
      <section className={Style.SearchFiledHolder}>
        <h3>
          <span>بهترین</span> و <span>مناسب ترین</span> ملک را برای خود پیدا
          کنید
        </h3>
        <h5>
          ما بیش از <span>200</span> واحد ملک برای شما داریم
        </h5>
        <ul className={Style.InputHolders}>
          <li className={Style.EachInput}>
            <Select
              mode="multiple"
              allowClear
              className={Style.SelectOptionHolder}
              placeholder=" کشور "
              onChange={getCityListHandler}
            >
              {CountriesList2 === undefined ? CountryList : CountriesList2}
            </Select>
          </li>
          <li className={Style.EachInput}>
            <Select
              mode="multiple"
              allowClear
              className={Style.SelectOptionHolder}
              placeholder=" شهر "
              onChange={HandleChangeCity}
            >
              {CitiesList &&
                CitiesList.map((item) => {
                  return (
                    <Option
                      style={{ textAlign: "right" }}
                      key={item.title}
                      value={item.title}
                    >
                      {item.title}
                    </Option>
                  );
                })}
            </Select>
          </li>
          <li className={Style.EachInput}>
            <Select
              mode="multiple"
              allowClear
              className={Style.SelectOptionHolder}
              placeholder=" نوع ملک"
              onChange={propertyTypeChangeHandler}
            >
              {propertyTypeList2 === undefined
                ? propertyTypeArrayList
                : propertyTypeList2}
            </Select>
          </li>
          <li className={Style.EachInput}>
            <Input
              placeholder="کدملک..."
              className={Style.TextInput}
              onChange={(e) => setForeignPropertyCode(e.target.value)}
            />
          </li>
          <li className={Style.EachInput}>
            <Input
              placeholder="از...مترمربع"
              className={Style.TextInput}
              onChange={(e) => setMinArea(e.target.value)}
            />
          </li>
          <li className={Style.EachInput}>
            <Input
              placeholder="تا...مترمربع"
              className={Style.TextInput}
              onChange={(e) => setMaxArea(e.target.value)}
            />
          </li>

          {countriesSelect && countriesSelect.length > 1 ? (
            <li className={Style.EachInput}>
              <Alert
                type="warning"
                message="به دلیل انتخاب چندکشور و متفاوت بودن وجه رایج هر کشور قادر به مشخص کردن بازه قیمتی نیستید"
                showIcon
                className="w-100 RTL"
              />
            </li>
          ) : (
            <li className={Style.EachInput}>
              <select
                defaultValue={""}
                className={Style.SelectOptionHolder}
                onChange={(e) => setPriceRangeSelect(e.target.value)}
              >
                <option disabled value={""}>
                  رنج قیمت  {priceRangeState && priceRangeState.maxPrice!==undefined?`به ${priceRangeState&&priceRangeState.currency}`:null}
                </option>
                {priceRangeState && priceRangeState.maxPrice !== undefined ? (
                  rangeArray.map((item, index) => {
                    return (
                      <option
                        style={{
                          direction: "ltr",
                          fontFamily: "iran-suns-num",
                        }}
                        key={index}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })
                ) : (
                  <option
                    style={{ direction: "rtl", fontFamily: "iran-suns-num" }}
                    disabled
                  >
                    {"ابتدا یک کشور انتخاب کنید!"}
                  </option>
                )}
              </select>
            </li>
          )}
          <li className={Style.EachInput}>
            <select
              className={Style.SelectOptionHolder}
              onChange={(e) => setSalesType(e.target.value)}
              defaultValue={""}
            >
              <option disabled value={""}>
                شرایط فروش
              </option>
              <option value={0}>نقد</option>
              <option value={1}>اقساط</option>
              <option value={2}>نقد،اقساط</option>
            </select>
          </li>
          <li className={Style.EachInput}>
            <Button
              value={"جست جو"}
              classbtn={"confrimBtn"}
              style={{ width: "100%" }}
              onClick={getListData}
            />
          </li>
        </ul>
      </section>
    </div>
  );
};

export default AmlakKharejiSearchContainer;
