import { Alert } from 'antd'
import React, { useEffect, useState } from 'react'
import { getTourRegisteredAPI } from '../../../Api/BarangizanServices'
import UserTourRegistredList from '../../Lists/UserTourRegistredList/UserTourRegistredList'
import LoadingPage from '../../Loading/LoadingPage/LoadingPage'


const UserPanelTourRegistered = () => {
    const[TourList,setTourList]=useState()
    useEffect(()=>{
        getTourRegistredHandler()
    },[])
    const getTourRegistredHandler=async()=>{
        const data=await getTourRegisteredAPI()
        setTourList(data.data.list)
    }
    let loadingHandler=null
    if(TourList===undefined){
        loadingHandler=<LoadingPage show={true}/>
    }
    else if(TourList.length===0){
        loadingHandler=    <Alert
        type="warning"
        message=" شما در هیچ توری ثبت نام نکرده اید!"
        showIcon
        className=" mt-2"
        style={{width:'95%'}}
      />
    }
    else{
        loadingHandler=<UserTourRegistredList data={TourList}/>
    }
  return (
    <section className='flexStyle bg-white'>
        {loadingHandler}
    </section>
  )
}

export default UserPanelTourRegistered