import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getProjectDetailAPI } from '../../Api/BarangizanServices'
import Footer from '../../Components/Footer/Footer'
import PropertyProjectMedia from '../../Components/PropertyProjectComponents/PropertyProjectMedia/PropertyProjectMedia'
import PropertyProjectTables from '../../Components/PropertyProjectComponents/PropertyProjectTables/PropertyProjectTables'
import TermsOfPurchase from '../../Components/PropertyProjectComponents/TermsOfPurchase/TermsOfPurchase'
import AmlakKharejiHeader from '../../Components/SiteHeaders/AmlakKharejiHeader/AmlakKharejiHeader'
import PropertyProjectSlider from '../../Components/Sliders/PropertyProjectSlider/PropertyProjectSlider'
import PropertyProjectTabs from '../../Components/Tabs/PropertyProjectTabs/PropertyProjectTabs'
import TopPageDetail from '../../Components/TopPageDetail/TopPageDetail'

const PropertyProject = () => {
  const {id}=useParams()
  const[projectState,setProjectState]=useState(null)
    useEffect(()=>{
      window.scrollTo(0,0)
      getProjectDetailHandler()
    },[])
    const getProjectDetailHandler=async()=>{
      const data=await getProjectDetailAPI(id)
      setProjectState(data.data.project)
    }
    const[tabs,setTabs]=useState(1)

    const setTabsHandler=(e)=>{
        setTabs(e)
    }
  return (
    <div className='pageHolder'>
        <AmlakKharejiHeader/>
        <TopPageDetail title={projectState===null?'درحال دریافت':projectState.title} date={projectState===null?'درحال دریافت':projectState.dateTime} />
        <PropertyProjectMedia info={projectState}/>
        <PropertyProjectTabs tab={tabs} setTabs={setTabsHandler}/>
        {
          tabs===1?<PropertyProjectTables data={projectState&&projectState.units} type={projectState&&projectState}/>:tabs===2?<PropertyProjectSlider info={projectState&&projectState.gallery}/>:null
        }
        
        <Footer/>
    </div>
  )
}

export default PropertyProject