import { Skeleton } from "antd";
import React, { useState } from "react";
import { Button, Modal } from "antd";
import Style from "./PropertyProjectMedia.module.scss";
import ProjectVisitForm from "../../Forms/ProjectVisitForm/ProjectVisitForm";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation"
import SwiperCore, {
    Navigation
  } from 'swiper';
SwiperCore.use([Navigation]);

const PropertyProjectMedia = ({ info }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [tourinfo, setTourInfo] = useState();
  const navigate = useNavigate();
  const modalCancel = () => {
    setShowModal(false);
    setShowModal2(false);
  };
  const modalNextTourHandler = (info) => {
    setTourInfo(info);
    setShowModal2(true);
  };

  return (
    <section className={Style.SectionHolder}>
      <Modal
        className={"RTL"}
        title="نوع بازدید خود از پروژه را مشخص کنید"
        visible={showModal}
        closable={false}
        footer={
          <Button onClick={modalCancel} type="dashed" danger>
            بستن
          </Button>
        }
      >
        <ProjectVisitForm
          openModal={(e) => modalNextTourHandler(e)}
          data={info}
        />
      </Modal>
      <Modal
        className={"RTL"}
        title="اطلاعات تور بعدی"
        visible={showModal2}
        closable={false}
        footer={
          <Button onClick={modalCancel} type="dashed" danger>
            بستن
          </Button>
        }
      >
        <ul className={"d-flex flex-column"}>
          <li className="mb-2">
            نام تور: 
            <span style={{ color: "#F9C326" }}>
              {tourinfo && tourinfo.title}
            </span>
          </li>
          <li className="mb-2">
            تاریخ : 
            <span style={{ color: "#F9C326" }}>
              {tourinfo && tourinfo.startDate}
            </span>
          </li>
          <li className="mb-2">
            تعداد جای باقی مانده :  
            <span style={{ color: "#F9C326" }}>
              {tourinfo && tourinfo.left}
            </span>
          </li>
          <li>
            <Button
              onClick={() => navigate("/tour", { state: info.code })}
              style={{ border: "none", background: "#F9C326", color: "#fff" }}
            >
              {" "}
              لیست تورها و ثبت نام
            </Button>
          </li>
        </ul>
      </Modal>
      {info === null ? (
        <Skeleton.Image active={true} />
      ) : (
        // <img
        //   src={`https://api.barangizan.ir/${info.imageUrl}`}
        //   alt="قبررس"
        //   className={Style.ProjecImage}
        // />
        <Swiper navigation={true} className={Style.swiper}>
          {
            info.gallery.map((item,index)=>{
              return(

                <SwiperSlide key={index} className={Style.swiperSlide}><img src={`https://api.barangizan.ir${item}`} alt="Building" /></SwiperSlide>
              )
            })
          }

    </Swiper>
      )}
      <h3>توضیحات پروژه {info && info.title}</h3>
      <div
        className={Style.DescHolder}
        dangerouslySetInnerHTML={{
          __html: info !== null ? info.description : "درحال دریافت",
        }}
      ></div>
      <p
        style={{ cursor: "pointer" }}
        className="text-white w-100 bg-warning text-center p-2 shadow-sm"
        onClick={() => setShowModal(true)}
      >
        بازدید ملک
      </p>
    </section>
  );
};

export default PropertyProjectMedia;
