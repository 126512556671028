import React from "react";
import Style from "./MagazineSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";
import SwiperCore, { Pagination } from "swiper";
import MagazinePerviewSlide from "../../../MagazinePerviewSlide/MagazinePerviewSlide";
import { useEffect } from "react";
import { useState } from "react";
import { MagazinListAPI } from "../../../../Api/BarangizanServices";

SwiperCore.use([Pagination]);

const MagazineSlider = () => {
  const [magazineList, setMagazineList] = useState();
  useEffect(() => {
    getMagazineListHandler();
  }, []);
  const getMagazineListHandler = async () => {
    const data = await MagazinListAPI();
    setMagazineList(data.data.list);
  };

  return (
    <div className={Style.SliderHolder}>
      <Swiper
        spaceBetween={30}
        className={Style.swiper}
        breakpoints={{
          // when window width is >= 640px
          250: {
            slidesPerView: 1.3,
          },
          640: {
            slidesPerView: 3,
          },
          // when window width is >= 768px
          999: {
            slidesPerView: 4.3,
          },
        }}
      >
        {magazineList &&
          magazineList.map((item) => {
            return (
              <SwiperSlide className={Style.swiperSlide} key={item.id}>
                <MagazinePerviewSlide data={item} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default MagazineSlider;
