import React, { useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap'
import Button from '../../Button/Button'
import Divider from '../../Divider/Divider'
import ContractsList from '../../Lists/ContractsList/ContractsList'
const data=[
  {
    id:1,
    imgSrc:'/Assets/Images/Contracts/1.png',
    name:'قرارداداول',
  },
  {
    id:2,
    imgSrc:'/Assets/Images/Contracts/1.png',
    name:'قرارداداول',
  },
  {
    id:3,
    imgSrc:'/Assets/Images/Contracts/1.png',
    name:'قرارداداول',
  },
  {
    id:4,
    imgSrc:'/Assets/Images/Contracts/1.png',
    name:'قرارداداول',
  }
]

const UserPanelContracts = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  return (
    <section className='flexStyle bg-white h-100'>
      <Modal show={show} className={'RTL'}>
        <Modal.Header >
          <Modal.Title className='fs-7 '>درخواست تغییر اطلاعات </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Form className='text-center'>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control as="textarea" rows={8}  placeholder=" توضیحات لازم برای تغییر را وارد کنید" />
          </Form.Group>

        </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button value=' ثبت' classbtn='confrimBtn' />
          <Button value=' بستن' classbtn='Remove' onClick={handleShow}/>
        </Modal.Footer>
    </Modal>
      <Divider bgColor='#fff' name={' قرار داد ها'}/>
      <ContractsList data={data}/>
      <Divider bgColor='#fff' name={'درخواست تغییر اطلاعات '}/>
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col md={6} className='text-center'><p className='m-0'>چنانچه خواهان تغییر اطلاعات خود هستید از طریق دکمه خود زیر درخواست خود را ثبت کنید</p></Col>
          <Col md={6} className='text-center mt-sm-3 mt-md-0'><Button value='تغییر اطلاعات' classbtn='confrimBtn' onClick={handleShow}/></Col>
        </Row>
      </Container>
    </section>
  )
}

export default UserPanelContracts