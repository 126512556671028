import React from 'react'
import Style from './ContractsList.module.scss'
const ContractsList = ({data}) => {
  return (
    <ul className={Style.ListHolder}>
        {
            data.map(item=>{
                return(
                    <li key={item.id}>
                        <img src={item.imgSrc} alt="" />
                        <span></span>
                        <a href='/'>{item.name}</a>
                    </li>
                )
            })
        }
    </ul>
  )
}

export default ContractsList