import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  AddForeignPropertyAPI,
  getCountriesListAPI,
  getFeaturesListOptionsAPI,
  getForeignCitylistAPI,
  getForeignPropertyTypeAPI,
} from "../../../Api/BarangizanServices";
import Style from "./AddForeignPropertyEnglishForm.module.scss";
import { Alert } from "antd";
import Select from "react-select";
import LoadingPage from "../../Loading/LoadingPage/LoadingPage";
import Button from "../../Button/Button";
import { errorToast, succToast } from "../../../Utils/Toast/Toast";

const AddForeignPropertyEnglishForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const [loadingType, setLoadingType] = useState(true);
  const [RentState, setRentState] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [propertyTypeValue, setPropertyValue] = useState("");
  const [countryList, setCountryList] = useState("");
  const [citiesList, setCitiestList] = useState([]);
  const [CityName, setCityName] = useState("");
  const [coutrySelect, setCountrySelect] = useState("");
  const [featureListState, setFeatureListState] = useState();
  const [description, setDescription] = useState("");
  const [ImageUrls, setImageUrls] = useState();
  const [ImageFile, setImageFile] = useState();
  const [TahatorState, setTahatorState] = useState(false);
  const [countryListTahator, setCountryListTahator] = useState(null);

  const imageChange = (evt) => {
    setImageFile(evt.target.files);
    let imageUrlList = [];
    Array.from(evt.target.files).forEach((element) =>
      imageUrlList.push(URL.createObjectURL(element))
    );
    setImageUrls(imageUrlList);
  };

  useEffect(() => {
    getDataHandler();
  }, []);
  const getDataHandler = async () => {
    const data = await getCountriesListAPI();
    setCountryList(data.data.list);
    const features = await getFeaturesListOptionsAPI(1);
    setFeatureListState(features.data.data);
    const propertyType = await getForeignPropertyTypeAPI();
    setPropertyType(propertyType.data.list);
    setLoadingType(false);
  };
  const getCityListHandler = async (info) => {
    setCountrySelect(info);
    const cities = await getForeignCitylistAPI(countryList.find(element=>element.englishTitle===info.label).title);
    setCitiestList(cities.data.list);
  };
  const citySelectHandler=(e)=>{
    setCityName(citiesList.find(element=>element.englishTitle===e.label).title)
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();


  const onSubmit = (data) => {
    if(ImageFile.length===0){
      errorToast('please choose a picture!')
    }
    else{

      setLoadingState(true);
      let formData = new FormData();
      formData.append("EstatesTypeId", propertyTypeValue.value);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("address", data.Address);
      formData.append("IsTahator", TahatorState);
      formData.append("TahatorCountries", countryListTahator);
  
      formData.append("Country", coutrySelect.label);
      formData.append("CountryId", coutrySelect.value);
      formData.append("city", CityName);
      formData.append("Metraj", data.Metraj);
      formData.append("price", data.price);
      formData.append("isEjareyi", RentState);
      for (let index = 0; index < ImageFile.length; index++) {
        formData.append(`Images[${index}].IsVideo`, false);
        formData.append(`Images[${index}].Item`, ImageFile[index]);
      }
      for (var i = 0; i < parseInt(featureListState.length); i++) {
        formData.append(`Features[${i}].Key`, data.Features[i].Key);
        let featureString = [];
        data.Features[i].value.forEach((element) => {
          featureString.push(element.value);
        });
        formData.append(`Features[${i}].Value`, featureString.toString());
      }
      AddForeignPropertyAPI(formData, (succ, res) => {
        if (succ) {
          succToast(res.data.message);
          setLoadingState(false);
          reset();
        } else {
          errorToast(res.data.errors[0]);
          setLoadingState(false);
        }
      });
    }
  };
  const TahatorHandler=(e)=>{
    setTahatorState(e.target.checked)
  }
  const seletCountryTahatorHandler=(e)=>{
    let CountryListSelected=[]
    e.forEach(element => {
      CountryListSelected.push(element.value)
    });
    setCountryListTahator(CountryListSelected.join("_"))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={Style.FormHolder}>
      <LoadingPage show={loadingState} />
      <ul className={Style.InputHolder}>
        <li>
          <label htmlFor="type">Property Type</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={setPropertyValue}
              options={
                propertyType &&
                propertyType.map((item) => {
                  return { value: item.id, label: item.englishTitle };
                })
              }
              name="type"
              placeholder="select..."
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>
        <li>
          <label htmlFor="title">Property Name</label>
          <input
            {...register("title", { required: false })}
            className={errors.title ? "valid" : "textEn"}
            autoComplete="title"
            type="text"
            name="title"
            style={{ direction: "ltr", textAlign: "left" }}
          />
        </li>

        <li>
          <label htmlFor="province"> Country</label>
          <div className={Style.selectOption}>
            <Select
              {...register("province", { required: false })}
              onChange={getCityListHandler}
              options={
                countryList &&
                countryList.map((item) => {
                  return { value: item.id, label: item.englishTitle };
                })
              }
              name="province"
              placeholder="select..."
              className={Style.selectOptionsearch}
              isLoading={loadingType}
            />
          </div>
        </li>
        <li>
          <label htmlFor="city"> City</label>
          <div className={Style.selectOption}>
            <Select
              {...register("city", { required: false })}
              options={
                citiesList &&
                citiesList.map((item) => {
                  return { value: item.id, label: item.englishTitle };
                })
              }
              name="city"
              onChange={citySelectHandler}
              placeholder="select..."
              className={Style.selectOptionsearch}
            />
          </div>
        </li>

        <li>
          <label htmlFor="Metraj">Meter </label>
          <input
            {...register("Metraj", { required: true })}
            className={errors.Metraj ? "valid" : "textEn"}
            autoComplete="Metraj"
            type="text"
            name="Metraj"
            style={{ direction: "ltr", textAlign: "left" }}
          />
        </li>
        <li>
          <label htmlFor="price">Price </label>
          <input
            {...register("price", { required: true })}
            className={errors.price ? "valid" : "textEn"}
            autoComplete="price"
            type="number"
            name="price"
            style={{ direction: "ltr", textAlign: "left" }}
          />
        </li>

        <li>
          <label htmlFor="foreignEstateSellingType"> selling Type</label>
          <div className={Style.selectOption}>
            <select
              name="foreignEstateSellingType"
              id=""
              {...register("foreignEstateSellingType", { required: false })}
            >
              <option value={0}>Cash</option>
              <option value={1}>Installments</option>
              <option value={2}>Cash,Installments</option>
            </select>
          </div>
        </li>
        <li className={Style.CheckBoxHolder}>
          <label htmlFor="Rent">  It is rented </label>
          <input
            style={{ accentColor: "blue" }}
            type={"checkbox"}
            id={"Rent"}
            onChange={(e) => setRentState(e.target.checked)}
          />
        </li>

        {RentState ? (
          <li>
            <label htmlFor="prepayment">Pre Payment </label>
            <input
              {...register("prepayment", { required: true })}
              className={errors.prepayment ? "valid" : "textEn"}
              autoComplete="prepayment"
              type="number"
              name="prepayment"
              style={{ direction: "ltr", textAlign: "left" }}
            />
          </li>
        ) : null}
        {RentState ? (
          <li>
            <label htmlFor="monthlyRent">Rent </label>
            <input
              {...register("monthlyRent", { required: true })}
              className={errors.monthlyRent ? "valid" : "textEn"}
              autoComplete="monthlyRent"
              type="number"
              name="monthlyRent"
              style={{ direction: "ltr", textAlign: "left" }}
            />
          </li>
        ) : null}

        {RentState ? (
          <li>
            <label htmlFor="ChargingFee">ChargingFee </label>
            <input
              {...register("ChargingFee", { required: true })}
              className={errors.ChargingFee ? "valid" : "textEn"}
              autoComplete="ChargingFee"
              type="number"
              name="ChargingFee"
              style={{ direction: "ltr", textAlign: "left" }}
            />
          </li>
        ) : null}
                <li>
            <label htmlFor='isTahator' className={Style.TahatorHolder}>
            <input type="checkbox" name="" id="isTahator" onChange={TahatorHandler}/>Exchange 
            </label>
            
        </li>
        {
          TahatorState?<li>
          <label htmlFor="type">    Countries for Exchange</label>
          <div className={Style.selectOption}>
            <Select
              {...register("type", { required: false })}
              onChange={seletCountryTahatorHandler}
              options={
                countryList?.map(item=>{
                  return({ value: item.title, label: item.englishTitle })
                })
              }
              name="type"
              placeholder="select..."
              className={Style.selectOptionsearch}
              isMulti
            />
          </div>
        </li>:null
        }
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="Address">Address</label>
        <textarea
          {...register("Address", { required: true })}
          className={errors.Address ? "valid" : "textEn"}
          autoComplete="Address"
          type="text"
          name="Address"
          style={{ direction: "ltr", textAlign: "left" }}
        />
      </div>
      <ul className={Style.InputHolder}>
        {featureListState &&
          featureListState.map((item, i) => {
            return (
              <li key={item.id}>
                <label htmlFor="featureCount">{`  Feautre ${i + 1}`}</label>
                <input
                  {...register(`Features[${i}].Key`)}
                  className={errors.username ? "valid" : "textEn"}
                  autoComplete={`Features[${i}].Key`}
                  type="text"
                  name={`Features[${i}].Key`}
                  style={{
                    marginBottom: "0.5rem",
                    direction: "ltr",
                    textAlign: "left",
                    fontSize: "1rem",
                  }}
                  defaultValue={item.englishText}
                  readOnly
                />
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <div className={Style.selectOption}>
                      <Select
                        {...register(`Features[${i}].value`)}
                        options={item.options.map((item) => {
                          return {
                            label: item.englishText,
                            value: item.text,
                          };
                        })}
                        name={`Features[${i}].value`}
                        // onChange={setFeatureTest}
                        onChange={onChange}
                        placeholder=" select"
                        className={Style.SelectOptionFeatures}
                        isMulti
                        defaultValue={[{ label: "nothing", value: 0 }]}
                      />
                    </div>
                  )}
                  name={`Features[${i}].value`}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={[{ label: "nothing", value: 0 }]}
                />
              </li>
            );
          })}
      </ul>
      <div className={Style.DescHolder}>
        <label htmlFor="description"> More Details</label>
        <textarea
        {...register("description", { required: true })}
          className={errors.description ? "valid" : "textEn"}
          name={"description"}
          defaultValue={""}
          style={{ direction: "ltr", textAlign: "left" }}
        />
      </div>
      <div className={Style.ImagePart}>
        <label htmlFor="imageFile" className={Style.ImageLabel}>
          Choose Media
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => imageChange(e)}
            multiple
          />
        </label>
      </div>
      <ul className={Style.InputHolder}>
        {ImageUrls &&
          ImageUrls.map((item, index) => {
            return (
              <li key={index} className={Style.ImagePerview}>
                <img
                  src={item}
                  className={Style.ImagePerview}
                  alt=""
                  onClick={() => window.open(item, "_blank")}
                />
              </li>
            );
          })}
      </ul>

      <Alert
        type="warning"
        message="Only Jpg,JPEG,PNG are acceptable!"
        showIcon
        className="w-100 my-2"
      />
      <Button value="submit" classbtn="submitBtn" style={{ width: "100%" }} />
    </form>
  );
};

export default AddForeignPropertyEnglishForm;
