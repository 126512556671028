import React, { useEffect, useState } from 'react'
import { getTiketListAPI } from '../../../Api/BarangizanServices'
import ForeignTiketsListTabel from '../../Lists/ForeignTiketsList/ForeignTiketsListTabel'

const ForeignTiketsList = () => {
    const[ListState,setListState]=useState()
    useEffect(()=>{
      getTiketListHandler()
    },[])
  
    const getTiketListHandler=async()=>{
      const data=await getTiketListAPI()
      setListState(data.data.list)
    }
  return (
    <div className='pageHolder'>
        <ForeignTiketsListTabel dataList={ListState}/>
    </div>
  )
}

export default ForeignTiketsList