import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMagazineDetailAPI, getMagazineGalleryAPI } from '../../../Api/BarangizanServices';
import Footer from '../../../Components/Footer/Footer';
import LoadingPage from '../../../Components/Loading/LoadingPage/LoadingPage';
import MagazineMedia from '../../../Components/MagazineMedia/MagazineMedia';
import { Button, Modal } from 'react-bootstrap'
// import Modal from '../../../Components/Modal/Modal';
import MagazineHeader from '../../../Components/SiteHeaders/MagazineHeader/MagazineHeader';
import FlipBookSlider from '../../../Components/Sliders/FlipBookSlider/FlipBookSlider';
import TopPageDetail from '../../../Components/TopPageDetail/TopPageDetail';
import Style from './MagazineDetail.module.scss'
const MagazineDetail = () => {
    const [purchased, setPurchased] = useState(false);
    const closeModalHandler=()=>{
        setPurchased(false)
    }
    const openModalHandler=()=>{
        setPurchased(true)
    }

    const[magazineData,setMagazineData]=useState()
    const[magazineGallery,setmagazineGallery]=useState()
    const {id}=useParams()
    useEffect(()=>{
      getMagazineDetailHandler()
    },[])
    const getMagazineDetailHandler=async()=>{
      const data=await getMagazineDetailAPI(id)
      const data2=await getMagazineGalleryAPI(id)
      setMagazineData(data.data)
      setmagazineGallery(data2.data)
    }

  return(
      
    <div className='pageHolder'>
            <Modal show={purchased} className={'RTL'} size={'xl'}>
            <Modal.Header >
              <Modal.Title style={{fontSize:'1rem'}}> مجله</Modal.Title>
            </Modal.Header>

            <Modal.Body className='d-flex justify-content-around w-100'>
            {magazineGallery&&<FlipBookSlider data={magazineGallery}/>}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeModalHandler} variant={'danger'}>بستن</Button>
            </Modal.Footer>
        </Modal>
    <MagazineHeader/>
        <TopPageDetail date={magazineData===undefined?'درحال دریافت':magazineData.month} title={magazineData===undefined?'درحال دریافت':magazineData.title}/>
        {
            magazineData===undefined?<LoadingPage show={true}/>: <MagazineMedia data={magazineData} openModal={openModalHandler}/>
        }
       {/* <div className={Style.magazineHolder}>
          {magazineGallery&&<FlipBookSlider data={magazineGallery}/>}
       </div> */}
    <Footer/>
    </div>
  )
};

export default MagazineDetail;
