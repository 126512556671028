import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
// import { Pagination } from 'react-bootstrap';
import PropertyPerviewResult from "../PropertyPerviewResult/PropertyPerviewResult";
import Style from "./ResultList.module.scss";
const ResultList = ({ list, totalPosts, updatePage }) => {
  const [pageindex, SetPageIndex] = useState(1);
  
  return (
    <ul className={Style.ListHolder}>
      {list.map((item) => {
        return (
          <li key={item.code} className={Style.propertyHolder}>
            <PropertyPerviewResult data={item} />
          </li>
        );
      })}
      <li className={Style.Pagination}>
        <Pagination
          showSizeChanger={false} 
          current={pageindex}
          pageSize={32}
          total={totalPosts}
          onChange={(page) => {
            updatePage(page);
            SetPageIndex(page);
          }}
        />
      </li>
    </ul>
  );
};

export default ResultList;
