import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDakheliSearchAPI } from "../../Api/BarangizanServices";
import Button from "../../Components/Button/Button";
import Footer from "../../Components/Footer/Footer";
import LoadingPage from "../../Components/Loading/LoadingPage/LoadingPage";
import Modal from "../../Components/Modal/Modal";
import DakheliFilter from "../../Components/Results/DakheliFilter/DakheliFilter";
import DakheliResultsContainer from "../../Components/Results/DakheliResultsContainer/DakheliResultsContainer";
import SortItemModal from "../../Components/Results/SortItemModal/SortItemModal";
import AmlakDakheliHeaders from "../../Components/SiteHeaders/AmlakDakheliHeader/AmlakDakheliHeaders";
import { FilterInfo } from "../../Utils/Context/SearchEngineContext";

const ResultsDakheli = () => {
  const { FilterData } = useContext(FilterInfo);
  const [propertyList, setPropertyList] = useState();
  const [purchased, setPurchased] = useState(false);
  const [purchased2, setPurchased2] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const closeModalHandler = () => {
    setPurchased(false);
    setPurchased2(false);
  };
  const openModalHandler = () => {
    setPurchased(true);
  };
  const openModalHandler2 = () => {
    setPurchased2(true);
  };
  useEffect(() => {
    getPropertyListHandler(1);
  }, []);
  const getPropertyListHandler = async (page) => {
    setLoadingState(true)
      const data = await getDakheliSearchAPI(page, FilterData);
      window.scrollTo(0, 0);
    setPropertyList(data.data);
    setLoadingState(false)
  };

  return (
    <div className="pageHolder">
      <LoadingPage show={loadingState}/>
      <Modal show={purchased} modalClose={closeModalHandler} translate={30}>
        <SortItemModal  searchUpdate={()=>getPropertyListHandler(1)} />
      </Modal>
      <Modal show={purchased2} modalClose={closeModalHandler} translate={50}>
        <DakheliFilter searchUpdate={() => getPropertyListHandler(1)} />
      </Modal>
      <AmlakDakheliHeaders />

      <DakheliResultsContainer
        updatePage={(e) => getPropertyListHandler(e)}
        searchUpdate={() => getPropertyListHandler(1)}
        data={propertyList}
        openModalHandler={openModalHandler}
        openModalHandler2={openModalHandler2}
      />
      <Footer />
    </div>
  );
};

export default ResultsDakheli;
