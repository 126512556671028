import React, { useState, useContext } from "react";

export const contact = React.createContext(null);

export const ContactInfo = () => {
  const pc = useContext(contact);
  if (pc === null) {
    throw new Error("useRefetchState Must be inside of Provider");
  }
  return pc;
};

const ContactInfoProvider= ({ children }) => {
  const [contactDetail, setContactDetail] = useState();

  return (
    <contact.Provider
      value={{
        contactDetail,
        setContactDetail: (value) => {
            setContactDetail(value);
        },
      }}
    >
      {children}
    </contact.Provider>
  );
};

export { ContactInfoProvider };
